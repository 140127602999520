import React from "react";

export const TextBox = (propss) => {
  const {
    register,
    id,
    errors,
    maxLength,
    disabled,
    type,
    placeholder,
    icon,
    getError,
    value,
    staticVal,
    setValue,
    className,
    ...props
  } = propss;
  if (staticVal) {
    setValue(id, staticVal);
  }

  const error = getError?.(errors) || errors?.[id];
  return (
    <React.Fragment>
      <input
        id={id}
        placeholder={placeholder}
        name={id}
        {...register(id, {
          valueAsNumber: type === "number",
        })}
        className={`form-control ${error ? "is-invalid" : ""} ${
          icon ? "icon-enabled" : ""
        } ${className}`}
        type={type ? type : "text"}
        onKeyDown={(evt) =>
          type === "number" &&
          ["e", "E", "+", "-"].includes(evt.key) &&
          evt.preventDefault()
        }
        maxLength={maxLength}
        disabled={disabled}
        defaultValue={value}
        // value={staticVal}
        step="any"
        autoComplete="off"
        {...props}
      />
      {error && <p className="invalid-feedback"> {error.message}</p>}{" "}
    </React.Fragment>
  );
};
export default TextBox;
