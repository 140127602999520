import React from 'react';
import { createContext, useState } from 'react';

export const ModulesContext = createContext();

export const ModulesProvider = ({ children }) => {
   const [modules, setModules] = useState([]);

   return (
      <ModulesContext.Provider value={{
         modules, setModules
      }}>
         {children}
      </ModulesContext.Provider>
   );
};