import React from 'react';
import "./SearchItem.css";

const SearchItem = (props) => {
    return (
        <div className="profile-container">
          <img
            src={props.photoUrl}
            alt="Profile"
            className="profile-photo"
          />
          <div className="profile-info">
            {props.name}
          </div>
        </div>
    );
}

export default SearchItem;