import React, { createContext, useState } from 'react';

export const RefreshScreenContext = createContext();

export const RefreshScreenProvider = ({ children }) => {

   const [refresh, setRefresh] = useState(false);

   return (
      <RefreshScreenContext.Provider value={{
         refresh, setRefresh
      }}>
         {children}
      </RefreshScreenContext.Provider>
   );
};