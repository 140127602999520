import React from 'react';
import './MainScreen.css';

const MainScreen = (props) => {
  return (
    <div className='main-screen'>
        {props.childComponent}
    </div>
  )
}

export default MainScreen;