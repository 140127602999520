/* A React component that displays a table of users. */
import React, { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { FaCheck, FaEye, FaPen, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ModalTab from "../../components/Modal";
import TableComp from "../../components/Table";
import { SelectColumnFilter } from "../../components/Table/Filters";
import {
  activateUserById,
  deleteUserById,
  getDriverGroups,
  // getLoading,
  getProfiles,
  getUsers,
  getUsersData,
} from "../../rtk-slice/userManagement";
import ViewEditUser from "./ViewEditUser";
import ViewLogsModal from "./components/ViewLogsModal";

/**
 * @returns A select element with a list of profiles.
 */
const SelectFilterProfile = ({ column }) => {
  let profileListData = useSelector(getProfiles);

  const profileList = useMemo(() => {
    if (!Array.isArray(profileListData)) return [];
    return profileListData.map((profile) => ({
      label: profile,
      value: profile,
      key: profile,
    }));
  }, [profileListData]);

  const { filterValue, setFilter } = column;
  return (
    <span>
      <select
        className="w-100 form-control"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      >
        <option value="">--</option>
        {Array.isArray(profileList) &&
          profileList.map((item) => (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          ))}
      </select>
    </span>
  );
};

/**
 * @returns A select dropdown with the driver groups as options.
 */
const SelectFilterDriverGroup = ({ column }) => {
  let driverGroupData = useSelector(getDriverGroups);

  const driverGroup = useMemo(() => {
    /* Converting the data from the redux store into a format that can be used by the react-table
    component. */
    if (!Array.isArray(driverGroupData)) return [];
    return driverGroupData.map((item) => ({
      label: item.name,
      value: item.id,
      key: item.id,
    }));
  }, [driverGroupData]);

  const { filterValue, setFilter } = column;
  return (
    <span>
      <select
        className="w-100 form-control"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      >
        <option value="">--</option>
        {Array.isArray(driverGroup) &&
          driverGroup.map((item) => (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          ))}
      </select>
    </span>
  );
};

const SelectStatus = ({ column }) => {
  const { filterValue, setFilter } = column;

  return (
    <span>
      <select
        className="w-100 form-control"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      >
        <option value={true}>Active</option>
        <option value={false}>Inactive</option>
        <option value="">All</option>
      </select>
    </span>
  );
};

const UsersDisplay = () => {
  const dispatch = useDispatch();
  const usersData = useSelector(getUsers);

  const [selectedUserId, setSelectedUserId] = useState(0);
  const [viewMode, setViewMode] = useState("");
  const [viewUserLogs, setViewUserLogs] = useState(null);
  const actionIconSize = 18;
  /* Converting the data from the redux store into a format that can be used by the react-table
  component. */
  let driverGroup = useSelector(getDriverGroups);
  driverGroup =
    Array.isArray(driverGroup) &&
    driverGroup.map((item) => ({
      label: item.name,
      value: item.id,
      key: item.id,
    }));

  const handleHideModal = () => setSelectedUserId(0);
  const handleActionClick = async (action, id) => {
    if (action === "edit") {
      setViewMode("edit");
      setSelectedUserId(id);
    } else if (action === "view") {
      setViewMode("view");
      setSelectedUserId(id);
    } else if (action === "deactivate") {
      let confirmDeactivate = window.confirm(
        "Are you sure you want to Deactivate this user?"
      );
      if (confirmDeactivate) {
        await dispatch(deleteUserById(id));
        dispatch(getUsersData());
      }
    } else if (action === "activate") {
      let confirmActivate = window.confirm(
        "Are you sure you want to Activate this user?"
      );
      if (confirmActivate) {
        // await dispatch(deleteUserById(id));
        await dispatch(activateUserById(id));
        dispatch(getUsersData());
      }
    }
  };

  /**
   * It takes an id and returns the label of the driver group with that id
   * @returns The label of the driver group.
   */
  const getDriverGroupLabelById = (id) => {
    const obj =
      Array.isArray(driverGroup) &&
      driverGroup.find((g) => {
        return g.value === id;
      });
    return (obj && obj.label) || "";
  };

  const handleLogsClick = (id) => {
    setViewUserLogs(id);
  };

  /* An array of objects. Each object is a column in the table. */
  const columns = [
    {
      Header: "Employee ID",
      accessor: "employeeId",
      Filter: ColumnFilter,
      maxWidth: 70,
      minWidth: 50,
      width: 60,
    },
    {
      Header: "Reference ID",
      accessor: "referenceId",
      Filter: ColumnFilter,
      maxWidth: 70,
      minWidth: 50,
      width: 60,
    },
    {
      Header: "Username",
      accessor: "username",
      Filter: ColumnFilter,
      maxWidth: 80,
      minWidth: 50,
      // getCellProps:({row}) => {console.log("row",row);},
      width: 65,
    },
    {
      Header: "First Name",
      accessor: "firstName",
      Filter: ColumnFilter,
      maxWidth: 120,
      minWidth: 50,
      width: 80,
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      Filter: ColumnFilter,
      maxWidth: 120,
      minWidth: 50,
      width: 80,
    },
    {
      Header: "Contact No",
      accessor: "contactNo",
      Filter: ColumnFilter,
      maxWidth: 120,
      minWidth: 50,
      width: 80,
    },
    {
      Header: "Profile",
      accessor: "profile",
      Filter: SelectFilterProfile,
      maxWidth: 80,
      minWidth: 50,
      width: 60,
    },
    {
      Header: "Driver Group",
      accessor: "driverGroupId",
      Filter: SelectFilterDriverGroup,
      maxWidth: 120,
      minWidth: 50,
      width: 80,
      Cell: ({ value }) => getDriverGroupLabelById(value),
    },
    {
      Header: "Driver Type",
      accessor: "driverTypeName",
      Filter: SelectColumnFilter,
      maxWidth: 120,
      minWidth: 50,
      width: 80,
    },
    {
      Header: "License Number",
      accessor: "licenseNo",
      Filter: ColumnFilter,
      maxWidth: 70,
      minWidth: 50,
      width: 80,
      // Cell: ({row}) =>(<div>{row.original.licenseNo} our message here</div>)
    },
    {
      Header: "Status",
      accessor: "active",
      id: "status",
      Filter: SelectStatus,
      maxWidth: 70,
      minWidth: 50,
      width: 55,
      Cell: ({ value }) => (value === true ? "ACTIVE" : "INACTIVE"),
    },
    {
      Header: "Logs",
      id: "logs",
      accessor: "userId",
      disableFilters: true,
      maxWidth: 70,
      minWidth: 50,
      width: 55,
      Cell: ({ value }) => (
        <Button
          type="button"
          variant="light"
          onClick={() => handleLogsClick(value)}
        >
          Logs
        </Button>
      ),
    },
    {
      Filter: ColumnFilter,
      disableFilters: true,
      maxWidth: 70,
      minWidth: 50,
      Header: "Actions",
      width: 65,
      Cell: ({ row, ...prop }) => (
        <div className="d-flex ">
          {row.original?.active && (
            <button
              title="view"
              className="btn btn-primary p-1 me-1 lh-1"
              onClick={() => {
                handleActionClick("view", row.original.userId);
              }}
            >
              <FaEye size={actionIconSize} />
            </button>
          )}
          {row.original?.active && (
            <button
              title="edit"
              className="btn btn-primary p-1 me-1"
              onClick={() => {
                handleActionClick("edit", row.original.userId);
              }}
            >
              <FaPen className="" size={actionIconSize} />
            </button>
          )}

          <button
            title={row.original.active ? "Deactivate" : "Activate"}
            className="btn btn-primary p-1"
            onClick={() => {
              /* if status is active => "deactivate" will trigger */
              const action = row.original.active ? "deactivate" : "activate";
              handleActionClick(action, row.original.userId);
            }}
          >
            {row.original.active ? (
              <FaTrash size={actionIconSize} />
            ) : (
              <FaCheck size={actionIconSize} />
            )}
          </button>
        </div>
      ),
    },
    {
      Header: "Message",
      accessor: "",
      disableFilters: true,
      Filter: ColumnFilter,
      maxWidth: 70,
      minWidth: 50,
      width: 60,
      Cell: ({ row }) => (
        <div style={{ color: "red" }}>
          {row.original.licenseActiveAlert &&
            row.original.licenseActiveAlertMsg}
        </div>
      ),
    },
  ];
  const getRowProps = (row) => ({
    style: {
      backgroundColor: row.original.active === false ? "#fcf1f0" : "",
    },
  });

  return (
    <>
      {viewUserLogs && (
        <ViewLogsModal id={viewUserLogs} onHide={() => setViewUserLogs(null)} />
      )}
      <div className="blur-bg-10">
        {usersData && (
          <TableComp
            data={usersData ? usersData : []}
            columns={columns}
            getRowProps={getRowProps}
            tableOptions={{
              initialState: {
                filters: [
                  {
                    id: "status",
                    value: true,
                  },
                ],
              },
            }}
          />
        )}

        {/* A modal that is used to view and edit the user details. */}
        <ModalTab
          fullscreen={true}
          onHide={handleHideModal}
          show={selectedUserId > 0}
          body={
            selectedUserId && (
              <ViewEditUser userId={selectedUserId} viewMode={viewMode} />
            )
          }
        />
      </div>
    </>
  );
};

/**
 * @returns A Column Filter Input Box for TableComp
 */
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <span>
      <input
        className="form-control"
        placeholder="Search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </span>
  );
};

export default UsersDisplay;
