import React, { useState, useRef, useEffect, useContext } from 'react';
import "./EditPopUp.css";
import "./McqPopUp.css";
import "./AddTextPopUp.css";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation } from 'react-router-dom';
import { uploadText } from '../../InductionLearningServices/Trainings';
import { useLoadingContext } from '../../context/LoadingContext';
import { RefreshScreenContext } from '../../context/RefreshScreen';
import { getTextModule } from '../../InductionLearningServices/Modules';

function AddTextPopUp(props) {
   const { setIsLoading } = useLoadingContext();
   const location = useLocation();
   const trainingId = location.state.onTap.id;
   const { setRefresh } = useContext(RefreshScreenContext);

   const [text, setText] = useState('');
   const textAreaRef = useRef(null);

   const handleChange = (e) => {
      const newText = e.target.value;
      setText(newText);
   };
   const getText = async (moduleId) => {
      setIsLoading((prev) => true);
      let response = await getTextModule(moduleId);
      setText((prev) => response.data);
      setIsLoading((prev) => false);
   }

   useEffect(() => {
      if (props.moduleId != "") {
         getText(props.moduleId);
      }
   }, []);

   useEffect(() => {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
   }, [text]);

   const sendText = async (formData) => {
      setIsLoading(true);
      const response = await uploadText(formData, trainingId);
      setIsLoading(false);
      if (response.status == 200) {
         setRefresh((prev) => true);
         props.closeAddTextPopUp();
      }
   };

   const postTextData = () => {
      let formData = new FormData();
      formData.append("text", text);
      sendText(formData);
   };

   return (
      <div className="edit-popup">
         <div className="headline-popup">
            <h2>Text</h2>
         </div>
         <span className="close-icon"><AiOutlineClose /></span>
         <div className='scrollable-container-parent-add-text'>
            <textarea ref={textAreaRef}
               value={text}
               onChange={handleChange}
               placeholder="Type here..."
               rows={5}
               className='scrollable-container-add-text'
            />
         </div>
         <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'end', margin: '5px 0px 0px 0px', cursor: 'pointer', gap: '10px' }}>
            <button onClick={postTextData} style={{ border: '1px solid white', padding: '0 10px', borderRadius: '5px', fontWeight: '500', cursor: 'pointer' }}>Post</button>
         </div>
      </div>
   );

   const scrollableContainerChild = {

   };
}

export default AddTextPopUp;
