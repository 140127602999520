import React from 'react';
import "./TableHeaders.css";

const TableHeaders = (props) => {
    const headerList = props.list;
  return (
    <div style={{display: "flex"}}>
        {
            headerList.map((listItem) => {
                if(listItem.show === "1") {
                    return (
                        <div key={listItem.title} className="search-screen-header-item" style={listItem.title === "Module" ? {borderTopLeftRadius: "12.5px"} : listItem.title === "TEXT" ? {borderTopRightRadius: "12.5px"} : {}}>
                            {listItem.title}
                        </div>
                    );
                }
            })
        }
    </div>
  );
}

export default TableHeaders;