import {
  ActiveButtonProvider,
  ModuleIdProvider,
  OnTapProvider,
  TrainingIdProvider,
  TrainingNameProvider,
  TrainingsProvider,
} from "../context/TrainingsContext";
import "../components/InductionLearning/InductionLearning.css";
import { McqProgressProvider } from "../context/McqProgress";
import { RefreshScreenProvider } from "../context/RefreshScreen";
import { ModulesProvider } from "../context/ModulesContext";
import { SearchProvider } from "../context/SearchContext";
import { LoadingProvider } from "../context/LoadingContext";

const ContextProvider = ({ children }) => {
  return (
    <TrainingsProvider>
      <TrainingIdProvider>
        <TrainingNameProvider>
          <ModuleIdProvider>
            <ActiveButtonProvider>
              <OnTapProvider>
                <ModulesProvider>
                  <SearchProvider>
                    <RefreshScreenProvider>
                      <McqProgressProvider>
                        <LoadingProvider>
                          {children}
                        </LoadingProvider>
                      </McqProgressProvider>
                    </RefreshScreenProvider>
                  </SearchProvider>
                </ModulesProvider>
              </OnTapProvider>
            </ActiveButtonProvider>
          </ModuleIdProvider>
        </TrainingNameProvider>
      </TrainingIdProvider>
    </TrainingsProvider>
  );
};

export default ContextProvider;
