import React, { createContext, useState } from 'react';

export const McqProgressContext = createContext();

export const McqProgressProvider = ({ children }) => {

   const [mcqList, setMcqList] = useState([]);

   return (
      <McqProgressContext.Provider value={{
         mcqList, setMcqList
      }}>
         {children}
      </McqProgressContext.Provider>
   );
};