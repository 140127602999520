import React from "react";
import { FormField, TextBox } from "../../Form";
import { useFieldArray, useFormContext } from "react-hook-form";

function CompanyDirectors({ prev, onNext }) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "companyDirectors",
  });
  const submit = () => {
    onNext();
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      <h3 className="stepper_Header">Company Directors</h3>
      <div className="my-3">
        {fields.map((field, index) => (
          <div key={field.id} className="mb-3">
            <FormField
              className="Field_label"
              title="Director Name"
              id={`companyDirectors.${index}.directorName`}
              ratio={[12, 12]}
            >
              <TextBox
                type="text"
                id={`companyDirectors.${index}.directorName`}
                register={register}
                errors={errors}
                placeholder="eg. John"
                className="py-2"
                getError={(error) =>
                  error?.companyDirectors?.[index]?.directorName
                }
              />
            </FormField>
            <FormField
              className="Field_label"
              title="Director Address"
              id={`companyDirectors.${index}.directorAddress`}
              ratio={[12, 12]}
            >
              <TextBox
                type="text"
                id={`companyDirectors.${index}.directorAddress`}
                register={register}
                errors={errors}
                placeholder="eg. block 10, NSW, Sydney"
                className="py-2"
                getError={(error) =>
                  error?.companyDirectors?.[index]?.directorAddress
                }
              />
            </FormField>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => remove(index)}
              >
                Remove
              </button>
            </div>
            <hr />
          </div>
        ))}
        <div className="text-center mb-4">
          <button
            type="button"
            className="btn"
            onClick={() =>
              append({
                directorName: "",
                directorAddress: "",
              })
            }
          >
            + Add Directors
          </button>
        </div>
      </div>
      <div className="form-btn">
        <button type="button" className="credit-form-btn" onClick={prev}>
          Back
        </button>
        <button type="submit" className="credit-form-btn">
          Next
        </button>
      </div>
    </form>
  );
}

export default CompanyDirectors;
