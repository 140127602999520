import React, { useMemo } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useDriverLogs } from "../../../api/drivers";
import TableComp from "../../../components/Table";
import { ColumnFilter } from "../UsersDisplay";
import LogsTable from "./LogsTable";

export default function ViewLogsModal({ onHide, id }) {
  const { isLoading, error, data: response } = useDriverLogs(id);

  const columns = useMemo(() => {
    return [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? "👇" : "👉"}
          </span>
        ),
        width: 30,
      },
      {
        Header: "Log Id",
        accessor: "id",
        Filter: ColumnFilter,
        width: 60,
      },
      {
        Header: "Created By",
        accessor: "createdBy",
        Filter: ColumnFilter,
      },
      {
        Header: "Created Date",
        accessor: "createdDate",
        Filter: ColumnFilter,
      },
    ];
  }, []);

  return (
    <Modal show onHide={onHide} size="lg">
      <Modal.Header closeButton>View Logs</Modal.Header>
      <Modal.Body>
        {error && (
          <p className="warning-text">
            Some error occurred while fetching logs
          </p>
        )}
        {isLoading && <Spinner />}
        {Array.isArray(response?.data) && (
          <TableComp
            columns={columns}
            data={response?.data}
            renderRowSubComponent={({ row }) => <LogsTable row={row} />}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
