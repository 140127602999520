import "../components/InductionLearning/InductionLearning.css";
import AdminMain from "../components/InductionLearning/AdminMain";
import MainScreen from "../components/InductionLearning/MainScreen";
import Sidebar from "../components/InductionLearning/Sidebar";
import ContextProvider from "./ContextProvider";

function Home() {
  return (
    <ContextProvider>
      <div className="induction-learning-body">
        <div className="main-content">
          <Sidebar />
          <MainScreen childComponent={<AdminMain />} />
        </div>
      </div>
    </ContextProvider>

  );
}

export default Home;
