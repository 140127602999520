import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import "./induction.scss";
import { Button, FormField, TextBox } from "../../components/Form";
import { useDispatch } from "react-redux";
import { registerPhone } from "../../rtk-slice/InductionAdmin";

const InductionCreateUser = ({ defaultValues, className }) => {
const dispatch = useDispatch()
  const validationSchema = Yup.object().shape({
    phoneNo: Yup.string()
    .max(20, "Contact number must be less than 20 digits")
    .matches(/^[0-9+]+$/, "Contact numbers should only contain numbers and +")
    .typeError("Contact number is invalid")
    .required("Contact Number is required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });

  const handleCreateUser = (data) => {
    dispatch(registerPhone(data))
  }
  return (
    <>
      <div className="create_induction_user">
        {/* header */}
        <div className="create_induction_user_header">
          <h2 className="text-white text-center p-5">Register Phone Number</h2>
        </div>
        {/* body */}
        <div className="create_induction_user_bg">
          <div className="col-md-10 mx-auto tabs-user ">
            <div className={` p-5 shadow blur-bg rounded ${className}`}>
              <form
                autoComplete="off"
                className="p-3"
                onSubmit={handleSubmit()}
              >
                <section className="row ">
                  <div className="col-md-12 px-5">
                    <FormField title="Phone No:" required={false} id="phoneNo" ratio={[2,10]}>
                      <TextBox
                        type="text"
                        id="phoneNo"
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        placeholder="Phone No"
                      />
                    </FormField>
                    <div className="row d-flex justify-content-end mb-3">
                      
                      <div className="col-2">
                        <Button
                          onClick={handleSubmit((data) => handleCreateUser(data))}
                          title="Register"
                          type="button"
                          className="buttons"
                        />
                      </div>
                    </div>
                    
                  </div>
                </section>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InductionCreateUser;
