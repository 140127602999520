import React from "react";
import { FormField, TextBox } from "../../Form";
import { useFormContext } from "react-hook-form";
import MultiSelect from "../../Form/Fields/MultiSelect";
import SelectField from "../../Form/Fields/SelectField";

const paymentOptions = [
  {
    key: "DIRECT CASE",
    value: "DIRECT_DEPOSIT",
  },
  {
    key: "CHEQUE",
    value: "CHEQUE",
  },
  {
    key: "CREDIT CARD",
    value: "CREDIT_CARD",
  },
  {
    key: "EFT",
    value: "EFT",
  },
  {
    key: "NONE",
    value: "NONE",
  },
];

function PaymentMethod({ prev, onNext }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const submit = () => {
    onNext();
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      <h3 className="stepper_Header">Payment Method</h3>
      <div className="my-3">
        <FormField
          className="Field_label"
          title="Payment Method"
          required
          id="paymentMethod"
          ratio={[12, 12]}
        >
          <MultiSelect
            id="paymentMethod"
            options={paymentOptions}
            optionDisplayKey={"key"}
            optionValueKey={"value"}
            placeholder="Select payment methods"
            errors={errors}
          />
        </FormField>
        <SelectField
          title="Payment Terms"
          className="Field_label"
          ratio={[12, 12]}
          required
          id="paymentTerms"
          options={[
            { key: "7 day net", value: "SDN" },
            { key: "14 day net", value: "FDN" },
            { key: "21 day net", value: "TDN" },
            { key: "30 day net", value: "TNDN" },
          ]}
          optionDisplayKey={"key"}
          optionValueKey={"value"}
          register={register}
          errors={errors}
          getError={(error) => error?.paymentTerms}
        />
        <FormField
          className="Field_label"
          title="Requested Amount"
          required
          id="requestedAmount"
          ratio={[12, 12]}
        >
          <div className="input-group">
            <span className="input-group-text" id="requestedAmount">
              $
            </span>
            <TextBox
              type="text"
              id="requestedAmount"
              register={register}
              errors={errors}
              placeholder="eg. $100"
              className="py-2"
            />
          </div>
        </FormField>
      </div>
      <div className="form-btn">
        <button type="button" className="credit-form-btn" onClick={prev}>
          Back
        </button>
        <button type="submit" className="credit-form-btn">
          Next
        </button>
      </div>
    </form>
  );
}

export default PaymentMethod;
