import React from "react";
import { useForm } from "react-hook-form";
import CheckField from "../../../components/Form/Fields/CheckField";
import { fetchService } from "../../../utils/api";
import config from "../../../constants/config";
import { errorToast, successToast } from "../../../utils/toastFunc";

const YES = "Yes";
const NO = "No";

const DeclarationTab = ({ driversDeclaration, viewMode, driverId }) => {
  const getDefaultDeclaration = () => {
    const declarations = { ...driversDeclaration };
    Object.keys(declarations).forEach((key) => {
      if (declarations[key]?.toLowerCase() === YES.toLowerCase()) {
        declarations[key] = true;
      } else declarations[key] = false;
    });
    return declarations;
  };

  const getDeclarations = (values) => {
    const declarations = { ...values };
    Object.keys(declarations).forEach((key) => {
      if (declarations[key]) {
        declarations[key] = YES;
      } else declarations[key] = NO;
    });
    return declarations;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: getDefaultDeclaration(),
  });

  const submitDeclarations = async (values) => {
    try {
      const declarations = getDeclarations(values);
      await fetchService({
        url: config.saveDriverDeclarations + driverId,
        method: "POST",
        body: JSON.stringify(declarations),
        onSuccessCallback: (response) => {
          successToast({ mes: "Declarations saved successfully" });
        },
      });
    } catch (err) {
      errorToast({ mes: "Something went wrong" });
      console.log(err);
    }
  };

  return (
    <form
      autoComplete="off"
      className=""
      onSubmit={handleSubmit(submitDeclarations)}
    >
      <div className="p-3 w-100">
        {/* <CheckField
          title="I acknowledge that Swift Transport has provided me with and explained all sections within this contract determination document"
          id="contact_determination"
          register={register}
          errors={errors}
          disabled={viewMode === "view"}
        />
        <hr />
        <CheckField
          title="I acknowledge and agree that my engagement with Swift Transport Pty Ltd is purely sub contractual as per agreement set out in this induction"
          id="subcontract_agreement"
          register={register}
          errors={errors}
          disabled={viewMode === "view"}
        />
        <hr />
        <CheckField
          title="I declare that I agree with and have completed the registration above with full understanding and honesty the registration and recognize that I subject myself to disciplinary the registration action in the event that the above facts are the registration found to be falsified or not adhered to"
          id="discipline"
          register={register}
          errors={errors}
          disabled={viewMode === "view"}
        />
        <hr />
        <CheckField
          title="I agree if I do not co-operate or comply with any Swift Transport rule or regulation will be subject to disciplinary action in the form of a verbal  followed by a final written warning in which case Swift Transport reserves the right to terminate my conditional guarantee and cease trading with me"
          id="rule_or_regulation"
          register={register}
          errors={errors}
          disabled={viewMode === "view"}
        />
        <hr /> */}
        <CheckField
          title="I agree to notify Swift Transport management immediately if any of the above details change"
          id="declaration_for_change"
          register={register}
          errors={errors}
          disabled={viewMode === "view"}
        />
        <div className="d-flex w-100">
          <button className="btn btn-primary ms-auto" type="submit">
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default DeclarationTab;
