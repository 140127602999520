import React from "react";
import { Modal, Button } from "react-bootstrap";
import TableComp from "../../../components/Table";

export default function SelectedDriversModal({
  selectedDrivers,
  columns,
  onHide,
}) {
  return (
    <Modal
      show={selectedDrivers.drivers.length}
      fullscreen
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {selectedDrivers.header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Total - {selectedDrivers.drivers.length}
        <TableComp data={selectedDrivers.drivers} columns={columns} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
