import React from 'react';
import ContextProvider from './ContextProvider';
import SearchScreen from './SearchScreen';

const SearchScreenMain = () => {
   return (
      <ContextProvider>
         <SearchScreen />
      </ContextProvider>
   );
};

export default SearchScreenMain;