import * as Yup from "yup";

// export const steps = [
//   { id: "welcome", name: "Welcome" },
//   {
//     id: "Step 1",
//     name: "Client Details",
//     fields: [
//       "customerName",
//       "companyName",
//       "personalInfo.abn",
//       "personalInfo.tradingName",
//       "personalInfo.postalAddress.address",
//       "personalInfo.postalAddress.state",
//       "personalInfo.postalAddress.postCode",
//       "phoneNo",
//       "alternatePhoneNo",
//       "emailAddress",
//       "personalInfo.creditLimit",
//       "personalInfo.fax",
//       "emailAddressesForAccountStatement",
//       "emailAddressesForInvoices",
//     ],
//   },
//   {
//     id: "Step 2",
//     name: "Business Details",
//     fields: [
//       "businessInfo.businessType",
//       "businessInfo.natureOfBusiness",
//       "businessInfo.specialConditions",
//       "businessInfo.businessAddress.postCode",
//       "businessInfo.businessAddress.state",
//       "businessInfo.businessAddress.address",
//     ],
//   },
//   {
//     id: "Step 3",
//     name: "Trade Details",
//     fields: ["tradeDetails"],
//   },
//   {
//     id: "Step 4",
//     name: "Company Directors",
//     fields: ["companyDirectors"],
//   },
//   {
//     id: "Step 5",
//     name: "Bank Details",
//     fields: [
//       "bankDetails.bank",
//       "bankDetails.bsb",
//       "bankDetails.accountHolderName",
//       "bankDetails.accountNumber",
//     ],
//   },
//   {
//     id: "Step 6",
//     name: "Payment Method",
//     fields: ["paymentMethod", "requestedAmount"],
//   },
//   {
//     id: "Step 7",
//     name: "Signature",
//     fields: ["signature"],
//   },
//   { id: "preview", name: "Preview" },
// ];

export const CreditAppDefaultValues = {
  personalInfo: {
    abn: "",
    creditLimit: 0,
    fax: "",
    postalAddress: {
      postCode: "",
      state: "",
      suburb: "",
      address: "",
    },
    tradingName: "",
  },
  companyName: "",
  alternatePhoneNo: "",
  emailAddress: "",
  emailAddressesForAccountStatement: "",
  emailAddressesForInvoices: "",
  bankDetails: {
    accountHolderName: "",
    accountNumber: "",
    bank: "",
    bsb: "",
  },
  businessInfo: {
    businessAddress: {
      postCode: "",
      state: "",
      suburb: "",
      address: "",
    },
    businessType: "",
    natureOfBusiness: "",
    specialConditions: "",
  },
  companyDirectors: [
    {
      directorAddress: "",
      directorName: "",
    },
  ],
  tradeDetails: [
    {
      referenceName: "",
      contactNumber: "",
      additionalContactNumber: "",
    },
  ],
  notes: "",
  paymentMethod: [],
  phoneNo: "",
  requestedAmount: 0,
  signUrl: "",
};

// export const CreditAppDefaultValues = {
//   personalInfo: {
//     abn: "287451",
//     creditLimit: 40000,
//     fax: "54574845",
//     postalAddress: {
//       postCode: "457812",
//       state: "Trivumi",
//       address: "Ranaghat",
//     },
//     tradingName: "Gouriya",
//   },
//   companyName: "AIR FLY WARE",
//   alternatePhoneNo: "8969852251",
//   bankDetails: {
//     accountHolderName: "Bhim",
//     accountNumber: "851548457845",
//     bank: "SBI",
//     bsb: "485747",
//   },
//   accountsTeam: {
//     authorizingOfficer: "",
//     authorizingOfficerContactNo: "",
//     contactPerson: "8969852251",
//     emailAddress: "",
//     phoneNumber: "",
//     position: "",
//   },
//   businessInfo: {
//     businessAddress: {
//       postCode: "748596",
//       state: "NAIDTI",
//       address: "KARTHAJHA",
//     },
//     businessType: "PTY_LTD",
//     natureOfBusiness: "Import Export",
//     specialConditions: "No",
//   },
//   companyDirectors: [
//     {
//       directorAddress: "Kilrgha, Jhaghar",
//       directorName: "Rameshwar",
//     },
//   ],
//   emailAddress: "satish741@gmail.com",
//   emailAddressesForAccountStatement: "satish41@gmail.com",
//   emailAddressesForInvoices: "satish358@gmail.com",
//   notes: "",
//   paymentMethod: ["CHEQUE"],

//   phoneNo: "8961635005",
//   requestedAmount: 40000,
//   tradeDetails: [
//     {
//       additionalContactNumber: "874968547",
//       contactNumber: "897755122",
//       referenceName: "Amit",
//     },
//   ],
// };

export const welcomeValidation = () => {
  return null;
};

export const getCustomerDetailsSchema = () => {
  return Yup.object().shape({
    personalInfo: Yup.object().shape({
      abn: Yup.string()
        .max(30, "ABN can't be more than 30 characters")
        .optional()
        .nullable(),
      tradingName: Yup.string()
        .max(50, "Trading Name can't be more than 50 characters")
        .optional()
        .nullable(),
      postalAddress: Yup.object().shape({
        address: Yup.string()
          .max(150, "Address can't be more than 150 characters")
          .required("address is required")
          .typeError("Postal address address required"),
        suburb: Yup.string()
          .max(25, "Suburb can't be more than 25 characters")
          .required("Suburb is required"),
        state: Yup.string()
          .max(25, "State can't be more than 25 characters")
          .required("State is required"),
        postCode: Yup.string()
          .max(7, "Postcode can't be more than 7 digits")
          .required("Postcode is required")
          .matches(/^[0-9 ]*$/, "Only digits are allowed"),
      }),
      creditLimit: Yup.number().optional().typeError("Only digit are allowed"),
      fax: Yup.string()
        .max(50, "Fax can't be more than 50 characters")
        .optional()
        .nullable(),
    }),
    businessInfo: Yup.object().shape({
      businessAddress: Yup.object().shape({
        postCode: Yup.string()
          .max(7, "Postcode can't be more than 7 digits")
          .required("Postcode is required")
          .matches(/^[0-9 ]*$/, "Only digits are allowed"),
        suburb: Yup.string()
          .max(25, "Suburb can't be more than 25 characters")
          .required("Suburb is required"),
        state: Yup.string()
          .max(25, "State can't be more than 25 characters")
          .required("State is required"),
        address: Yup.string()
          .max(150, "Address can't be more than 150 characters")
          .required("Address is required")
          .typeError("Business address required"),
      }),
    }),

    companyName: Yup.string()
      .max(50, "Company Name can't be more than 50 characters")
      .required("Company Name is required"),
    phoneNo: Yup.string()
      .min(10, "Phone No must be 10 digits")
      .max(10, "Phone No can't be more than 10 digits")
      .required("Phone No. is required")
      .matches(/^[0-9 ]*$/, "Only digits are allowed"),
    alternatePhoneNo: Yup.string()
      .nullable()
      .notRequired()
      .test(
        "len",
        "Contact No must be at least 10 digits",
        (val) => !val || (val && val.length >= 10 && val.length <= 10)
      ),
    emailAddress: Yup.string()
      .email("Email is invalid")
      .max(50, "Email can't be more than 50 characters")
      .required("Email is required"),
    emailAddressesForAccountStatement: Yup.string()
      .email("Email is invalid")
      .max(50, "Email can't be more than 50 characters")
      .optional()
      .nullable(),
    emailAddressesForInvoices: Yup.string()
      .email("Email is invalid")
      .max(50, "Email can't be more than 50 characters")
      .optional()
      .nullable(),
  });
};

export const getAccountDetails = () => {
  return Yup.object().shape({
    accountsTeam: Yup.object().shape({
      authorizingOfficer: Yup.string()
        .max(100, "Authorization Officer can't be more than 100 characters")
        .nullable()
        .notRequired(),
      authorizingOfficerContactNo: Yup.string()
        .matches(/^[0-9 ]*$/, "Only digits are allowed")
        .nullable()
        .notRequired()
        .test(
          "len",
          "Contact No must be at least 10 digits",
          (val) => !val || (val && val.length >= 10 && val.length <= 10)
        ),
      contactPerson: Yup.string()
        .max(100, "Contact Person can't be more than 100 characters")
        .nullable()
        .notRequired(),
      emailAddress: Yup.string()
        .email("Email Address is invalid")
        .max(100, "Email Address can't be more than 100 characters")
        .nullable()
        .notRequired(),
      phoneNumber: Yup.string()
        .matches(/^[0-9 ]*$/, "Only digits are allowed")
        .nullable()
        .notRequired()
        .test(
          "len",
          "Phone Number must be at least 10 digits",
          (val) => !val || (val && val.length >= 10 && val.length <= 10)
        ),
      position: Yup.string()
        .max(100, "Position can't be more than 100 characters")
        .nullable()
        .notRequired(),
    }),
  });
};

export const getBusinessDetailsSchema = () => {
  return Yup.object().shape({
    businessInfo: Yup.object().shape({
      businessType: Yup.string().required("Business Type is required"),
      natureOfBusiness: Yup.string()
        .max(100, "Nature of Business can't be more than 100 characters")
        .required("Nature of Business is required"),
      specialConditions: Yup.string()
        .max(100, "Special Conditions can't be more than 100 characters")
        .optional()
        .nullable(),
    }),
  });
};

export const getTradeDetailSchema = () => {
  return Yup.object().shape({
    tradeDetails: Yup.array(
      Yup.object({
        referenceName: Yup.string()
          .max(50, "Reference Name can't be more than 50 characters")
          .optional()
          .nullable(),
        contactNumber: Yup.string()
          .nullable()
          .notRequired()
          .test(
            "len",
            "Contact No must be at least 10 digits",
            (val) => !val || (val && val.length >= 10 && val.length <= 10)
          ),
        additionalContactNumber: Yup.string()
          .nullable()
          .notRequired()
          .test(
            "len",
            "Contact No must be at least 10 digits",
            (val) => !val || (val && val.length >= 10 && val.length <= 10)
          ),
      })
    )
      .optional()
      .nullable(),
  });
};

export const getCompanyDirectorSchema = () => {
  return Yup.object().shape({
    companyDirectors: Yup.array(
      Yup.object().shape({
        directorName: Yup.string()
          .max(50, "Director Name can't be more than 50 characters")
          .optional()
          .nullable(),
        directorAddress: Yup.string()
          .max(100, "Director Address can't be more than 100 characters")
          .optional()
          .nullable(),
      })
    )
      .optional()
      .nullable(),
  });
};

export const getBankDetailsSchema = () => {
  return Yup.object().shape({
    bankDetails: Yup.object().shape({
      bank: Yup.string()
        .max(50, "Bank can't be more than 50 characters")
        .required("Bank is required"),
      bsb: Yup.string()
        .max(7, "BSB can't be more than 7 characters")
        .required("BSB is required"),
      accountHolderName: Yup.string()
        .max(50, "Name of Account Holder can't be more than 50 characters")
        .required("Name of Account Holder is required"),
      accountNumber: Yup.string()
        .max(9, "Account Number can't be more than 9 characters")
        .required("Account Number is required"),
    }),
  });
};

export const getPaymentAndSubmissionSchema = () => {
  return Yup.object().shape({
    paymentMethod: Yup.array()
      .min(1, "At least one payment method is required")
      .required("Payment Method is required"),
    paymentTerms: Yup.string().required("Payment terms is required"),
    requestedAmount: Yup.string()
      .max(20, "Requested Amount can't be more than 20 characters")
      .required("Requested Amount is required"),
  });
};

export const getSignatureSchema = ({ signUrl }) => {
  return Yup.object().shape({
    signaturePersonName: Yup.string()
      .max(50, "Name can't be more than 50 characters")
      .required("Name is required"),
    signaturePersonPosition: Yup.string()
      .max(50, "Position can't be more than 50 characters")
      .required("Position is required"),
    signature: signUrl
      ? Yup.mixed().optional()
      : Yup.mixed().required("Signature is required"),
  });
};

export const getConfirmationSchema = ({ isAdminProcessed }) => {
  return Yup.object().shape({
    customerStatus: isAdminProcessed
      ? Yup.string().required("Select to submit")
      : Yup.string(),

    notes: isAdminProcessed
      ? Yup.string()
          .max(200, "Notes can't be more than 200 characters")
          .required("Notes are required")
      : Yup.string(),
    signaturePersonName: Yup.mixed().when("signRequired", {
      is: (signRequired) => signRequired === true,
      then: Yup.string()
        .max(50, "Name can't be more than 50 characters")
        .required("Name is required")
        .typeError("Name is required"),
      otherwise: Yup.string().optional().nullable(),
    }),
    signaturePersonPosition: Yup.mixed().when("signRequired", {
      is: (signRequired) => signRequired === true,
      then: Yup.string()
        .max(50, "Position can't be more than 50 characters")
        .required("Position is required")
        .typeError("Position is required"),
      otherwise: Yup.string().optional().nullable(),
    }),
    signature: Yup.mixed().when("signRequired", {
      is: (signRequired) => signRequired === true,
      then: Yup.mixed().required("Signature is required"),
      otherwise: Yup.mixed().optional().nullable(),
    }),
  });
};
