import React from 'react';
import "../components/InductionLearning/InductionLearning.css";
import Sidebar from '../components/InductionLearning/Sidebar';
import MainScreen from '../components/InductionLearning/MainScreen';
import ScreenContent from '../components/InductionLearning/ScreenContent';

const OtherScreens = () => {

   return (
      <div className="induction-learning-body">
         <div className='main-content'>
            <Sidebar />
            <MainScreen
               childComponent={<ScreenContent/>}
            />
         </div>
      </div>
   );
}

export default OtherScreens;