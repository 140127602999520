import { deleteSuccessOrFailure, showOnlyOnError, uploadSuccessOrFailure } from "../constants/ErrorHandling";
import config from "../constants/config";
import { deleteApiCallFormData, getApiCall, postFormDataApiCall, putApiCallBody, putApiCallFormData } from "../utils/api";


export async function getTrainingData() {
   const url = config.baseUrlInductionLearning + "/admin/v1/trainingList";

   try {
      const response = await getApiCall(url);
      showOnlyOnError(response.status, response.data);
      return response.data;
   } catch (err) {
      showOnlyOnError(err.response.status, err.response.data);
      return {"data": []};
   }
}

export async function deleteTraining(formData) {
   const url = config.baseUrlInductionLearning + "/admin/v1/training";

   try {
      const response = await deleteApiCallFormData(url, formData);
      deleteSuccessOrFailure(response.status, response.body);
      return response;
   } catch (err) {
      deleteSuccessOrFailure(err.response.status, err.response.data);
      return err.response;
   }
}

export async function createTraining(formData) {
   const url = config.baseUrlInductionLearning + "/admin/v1/training";

   try {
      const response = await postFormDataApiCall(url, formData);
      uploadSuccessOrFailure(response.status, response.body);
      return response.data;
   } catch (err) {
      uploadSuccessOrFailure(err.response.status, err.response.data);
      return err.response;
   }
}

export async function uploadPDF(formData, trainingId) {
   const url = config.baseUrlInductionLearning + "/admin/v1/training/" + trainingId + "/PDF/upload";

   try {
      const response = await putApiCallFormData(url, formData);
      uploadSuccessOrFailure(response.status, response.body);
      return response;
   } catch (err) {
      uploadSuccessOrFailure(err.response.status, err.response.data);
      return err.response;
   }
}

export async function uploadVideo(formData, trainingId) {
   const url = config.baseUrlInductionLearning + "/admin/v1/training/" + trainingId + "/VIDEO/upload";

   try {
      const response = await putApiCallFormData(url, formData);
      uploadSuccessOrFailure(response.status, response.body);
      return response;
   } catch (err) {
      uploadSuccessOrFailure(err.response.status, err.response.data);
      return err.response;
   }
}

export async function uploadMCQ(mcqList, trainingId) {
   const url = config.baseUrlInductionLearning + "/admin/v1/training/" + trainingId + "/mcq/upload";

   try {
      const response = await putApiCallBody(url, JSON.stringify(mcqList));
      uploadSuccessOrFailure(response.status, response.body);
      return response;
   } catch (err) {
      uploadSuccessOrFailure(err.response.status, err.response.data);
      return err.response;
   }
}

export async function uploadText(formData, trainingId) {
   const url = config.baseUrlInductionLearning + "/admin/v1/training/" + trainingId + "/text/upload";

   try {
      const response = await putApiCallFormData(url, formData);
      uploadSuccessOrFailure(response.status, response.body);
      return response;
   } catch (err) {
      uploadSuccessOrFailure(err.response.status, err.response.data);
      return err.response;
   }
}