import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaEye, FaPen } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import moment from "moment";
import {
  Button,
  FormField,
  SelectBox,
  TextBox,
  ToggleSwitch,
} from "../../../components/Form";
import config from "../../../constants/config";
import { getDriverTypes } from "../../../rtk-slice/globalSlice";
import {
  getDriverGroup,
  getDriverGroups,
  getProfileList,
  getProfiles,
  getUsersData,
} from "../../../rtk-slice/userManagement";
import { postData } from "../../../utils/api";
import { errorToast, successToast } from "../../../utils/toastFunc";
import "../UserManagement.scss";
import {
  MAX_DATE_STRING,
  userEmploymentTypeOptions,
} from "../../../constants/enums";
import TextInputField from "../../../components/Form/TextInputField";
import SwitchField from "../../../components/Form/Fields/SwitchField";
import SelectField from "../../../components/Form/Fields/SelectField";
import TextAreaField from "../../../components/Form/Fields/TextAreaField";

const SWIFT_CAFE_USER = "SWIFT CAFE USER";

const UserDetailsForm = ({
  defaultValues,
  viewEditMode,
  id,
  setCreatedUser,
  setKey,
  className,
  OnSubmitFunction,
  refreshAllUsersData,
}) => {
  const dispatch = useDispatch();
  const [viewMode, setViewMode] = useState(viewEditMode); //if we want to change viewMode inside comp
  const toggleViewMode = () =>
    setViewMode((prevViewMode) => (prevViewMode === "edit" ? "view" : "edit"));
  useEffect(() => {
    dispatch(getProfileList());
    dispatch(getDriverGroup());
  }, [dispatch]);

  const driverGroup = useSelector(getDriverGroups);
  const driverTypes = useSelector(getDriverTypes);

  let profileList = useSelector(getProfiles);
  /* This is to convert the profileList to the format that SelectBox component expects. */
  profileList =
    Array.isArray(profileList) &&
    profileList.map((profile) => ({ label: profile, value: profile }));

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required")
      .max(20, "First Name must be less than 20 characters"),
    lastName: Yup.string()
      .nullable()
      .max(20, "Last Name must be less than 20 characters"),
    emailId: Yup.string()
      .email()
      .required("Email ID is required")
      .max(100, "Email Id must be less than 100 digits"),
    username: Yup.string()
      .required("User Name is required")
      .min(5, "More than 5 characters required")
      .max(100, "User Name must be less than 100 characters")
      .matches(/^[a-zA-Z0-9_]+$/, "Only Alphanumeric and Underscore allowed"),
    profile: Yup.string().required("Profile is required"),
    driverGroupId: Yup.string()
      .nullable()
      .when("profile", {
        is: "DRIVER",
        then: Yup.string()
          .required("Driver Group is required")
          .typeError("Driver Group is required"),
      }),
    driverTypeId: Yup.string()
      .nullable()
      .when("profile", {
        is: "DRIVER",
        then: Yup.string()
          .required("Driver Type is required")
          .typeError("Driver Type is required"),
      }),
    contactNo: Yup.string()
      .min(10, "Contact number must be 10 digits")
      .max(10, "Contact number must be 10 digits")
      .typeError("Contact number is required")
      .required("Contact Number is required"),
    dateOfBirthStr: Yup.string()
      .default(null)
      .nullable()
      .when("profile", {
        is: (profile) => profile !== SWIFT_CAFE_USER,
        then: (yup) => yup.required("Date of Birth is required"),
      })
      .typeError("Date of Birth is required"),
    address: Yup.string()
      .nullable()
      .when("profile", {
        is: (profile) => profile !== SWIFT_CAFE_USER,
        then: (yup) => yup.required("Address is required"),
      })
      .max(200, "Address must be less than 200 characters"),
    suburb: Yup.string()
      .nullable()
      .max(25, "Suburb must be less than 25 characters")
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        "Only Alphanumeric characters and spaces allowed"
      ),
    postCode: Yup.string()
      .nullable()
      .max(25, "Postcode must be less than 25 characters")
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        "Only Alphanumeric characters and spaces allowed"
      ),
    state: Yup.string().nullable(),
    // .max(25, "State must be less than 25 characters"),
    // .matches(/^[a-zA-Z]*$/, "Only alphabets are allowed"),
    // Bank Details Validation
    bankName: Yup.string()
      .nullable()
      .max(50, "Bank Name must be less than 50 characters")
      .matches(/^[a-zA-Z0-9 ]*$/, "Only Alphanumeric characters allowed"),
    branchName: Yup.string()
      .nullable()
      .max(50, "Branch Name must be less than 50 characters")
      .matches(/^[a-zA-Z0-9 ]*$/, "Only Alphanumeric characters allowed"),
    accountName: Yup.string()
      .nullable()
      // .required()
      .typeError("Account number is required")
      .max(50, "Account Name must be less than 50 characters")
      .matches(/^[a-zA-Z0-9 ]*$/, "Only Alphanumeric characters allowed"),
    accountNumber: Yup.string()
      .nullable()
      .transform((value, original) => (original === "" ? null : value))
      .test(
        "max-digits",
        "value must be less than or equal to 9 digits",
        function (value) {
          if (!value) return true;
          return value.length <= 9;
        }
      ),
    bsb: Yup.string()
      .nullable()
      .transform((value, original) => (original === "" ? null : value))
      .test("min max", `value must be from 6 to 7 digits`, function (value) {
        if (!value) return true;
        return value.length >= 6 && value.length <= 7;
      }),
    criminalRecord: Yup.bool(),
    criminalRecordDetails: Yup.string().when("criminalRecord", {
      is: true,
      then: (yup) =>
        yup
          .required("Criminal Records Details Required")
          .max(250, "Details must be less than 250 characters"),
    }),
    drivingDisqualification: Yup.bool(),
    drivingDisqualificationDetails: Yup.string().when(
      "drivingDisqualification",
      {
        is: true,
        then: (yup) =>
          yup
            .required("Driver Disqualification Details Required")
            .max(250, "Details must be less than 250 characters"),
      }
    ),
    msicNumber: Yup.string()
      .nullable()
      .when("profile", {
        is: "DRIVER",
        then: Yup.string()
          .nullable()
          .max(30, "MSIC Number should be less than 30 characters")
          .matches(/^[a-zA-Z0-9]*$/, "Only Alphanumeric characters allowed"),
      }),
    // msicExpiryDateStr: Yup.date().nullable().default(null), // Not Required
    gender: Yup.string(),
    referenceId: Yup.string().max(
      20,
      "Reference Id must be less than 20 characters"
    ),

    licenseNo: Yup.string()
      .nullable()
      .when("profile", {
        is: "DRIVER",
        then: Yup.string()
          .required("License Number is required")
          .typeError("License Number is required")
          .matches(/^[a-z0-9]+$/i, "Only Alphanumeric Allowed")
          .max(30, "License No. must be less than 30 characters"),
      }),
    licenseExpiryDateStr: Yup.date()
      .nullable()
      .default(null)
      .typeError("License Expiry Date is required")
      .when("profile", {
        is: "DRIVER",
        then: (Yup) =>
          Yup.required("License Expiry Date is required").typeError(
            "License Expiry Date is required"
          ),
      })
      .when("dateOfBirthStr", (dateOfBirthStr, Yup) =>
        dateOfBirthStr && dateOfBirthStr.toString() !== "Invalid Date"
          ? Yup.nullable()
              .default(null)
              .min(
                dateOfBirthStr,
                "Expiry Date should be more than Date of birth"
              )
              .typeError("License Expiry Date is required")
          : Yup
      ),
    licenseType: Yup.string()
      .nullable()
      .when("profile", {
        is: "DRIVER",
        then: Yup.string()
          .required("License Type is required")
          .matches(/^[a-zA-Z]+$/, "License Type should only contain letters")
          .max(30, "License type should be less than 30 characters"),
      }),
    visaStatus: Yup.string()
      .nullable()
      .when("profile", {
        is: "DRIVER",
        then: Yup.string().max(
          50,
          "Visa Status should be less than 50 characters"
        ),
      }),
    bfmNo: Yup.string().max(30, "BFM should be less than 30 characters"),
    employeeType: Yup.string()
      .nullable()
      .when("profile", {
        is: (profile) => profile !== SWIFT_CAFE_USER,
        then: (yup) => yup.required("Employee Type is required"),
      }),
    gst: Yup.string(),
    superannuationFund: Yup.string()
      .nullable()
      .when("employeeType", {
        is: "Employee",
        then: Yup.string()
          .nullable()
          .matches(/^([a-zA-Z0-9 ]*)$/, "Only Alphanumeric Allowed")
          .max(30, "Superannuation Fund should be less than 30 characters"),
      }),
    superannuationNo: Yup.string()
      .nullable()
      .when("employeeType", {
        is: "Employee",
        then: Yup.string()
          .nullable()
          .max(30, "Superannuation No should be less than 30 characters")
          .matches(
            /^([a-zA-Z0-9 ]*)$/,
            "Only Alphanumeric characters are allowed"
          ),
      }),
    tfn: Yup.string()
      .nullable()
      .when("employeeType", {
        is: "Employee",
        then: Yup.string()
          .nullable()
          .max(30, "TFN should be less than 30 characters"),
      }),
    abn: Yup.string()
      .nullable()
      .when("employeeType", {
        is: "Subcontractor",
        then: Yup.string()
          .required("abn details is required")
          .typeError("abn details is required")
          .max(30, "ABN should be less than 30 characters"),
      }),
    tradingName: Yup.string()
      .nullable()
      .when("employeeType", {
        is: "Subcontractor",
        then: Yup.string()
          .nullable()
          .max(50, "Trading Name should be less than 50 characters"),
      }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    // clearErrors,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    defaultValues: { ...defaultValues },
  });

  const criminalRecord = watch("criminalRecord");
  const drivingDisqualification = watch("drivingDisqualification");

  const formatDate = (dateObj) =>
    dateObj ? moment(dateObj).format("YYYY-MM-DD") : null;

  const onCreateUser = async (formData) => {
    try {
      const saveUserUrl = config.baseUrl + config.saveUser;
      const updateUserUrl = id && config.baseUrl + config.updateUser + "/" + id;
      const submitUrl = id ? updateUserUrl : saveUserUrl;
      const postBody = {
        ...formData,
        licenseExpiryDateStr: formatDate(formData.licenseExpiryDateStr),
        dateOfBirthStr: formatDate(formData.dateOfBirthStr),
        msicExpiryDateStr: formatDate(formData.msicExpiryDateStr),
        medicalDueNext: formatDate(formData.medicalDueNext),
      };
      const res = await postData(submitUrl, postBody);
      if (res && res.data) {
        if (!viewMode) {
          successToast({ mes: "User successfully created" });
          const { id, password } = res.data;
          setCreatedUser({ id, password });
          setKey && setKey("password");
          setViewMode("view");
          // to sync with backend
          const url = config.baseUrl + config.getUserList + "?id=" + id;
          const data = await postData(url, {});
          if (data) {
            let formData = data.data?.["0"];
            formData && reset(formData);
          }
        } else {
          successToast({ mes: "User successfully updated" });
          refreshAllUsersData && dispatch(getUsersData());
        }
        OnSubmitFunction && OnSubmitFunction(res, formData);
      } else {
        errorToast({ mes: res?.message });
      }
    } catch (err) {
      errorToast({ mes: err?.message });
    }
  };
  //  console.log(errors)
  return (
    <>
      <div className={` p-3 pt-5 shadow blur-bg rounded ${className}`}>
        {viewMode && !viewEditMode && (
          <button
            title="view"
            className="btn btn-primary p-1 me-1 lh-1"
            onClick={() => toggleViewMode()}
          >
            {viewMode === "edit" ? <FaEye size={20} /> : <FaPen size={20} />}
          </button>
        )}
        <form
          autoComplete="off"
          className=""
          onSubmit={handleSubmit(onCreateUser)}
        >
          <section className="row mb-2">
            <div className="col-md-6 px-5">
              <FormField title="First Name" required={true} id="firstName">
                <TextBox
                  type="text"
                  id="firstName"
                  register={register}
                  disabled={viewMode === "view"}
                  errors={errors}
                  placeholder="First Name"
                />
              </FormField>
              <FormField title="Last Name" id="lastName">
                <TextBox
                  type="text"
                  id="lastName"
                  register={register}
                  disabled={viewMode === "view"}
                  errors={errors}
                  placeholder="Last Name"
                />
              </FormField>
              <FormField title="Email" required={true} id="emailId">
                <TextBox
                  type="text"
                  register={register}
                  disabled={viewMode === "view"}
                  errors={errors}
                  placeholder="Email"
                  id="emailId"
                />
              </FormField>
              <FormField title="User Name" required={true} id="username">
                <TextBox
                  type="text"
                  id="username"
                  register={register}
                  disabled={viewMode} //to disable if one of <view> or <edit>
                  errors={errors}
                  placeholder="User Name"
                />
              </FormField>
              <FormField title="Profile" required={true} id="profile">
                {/* only in case of viewing own profile */}
                {viewMode !== "profileMode" ? (
                  <SelectBox
                    register={register}
                    defaultLabel="Please select"
                    data={profileList}
                    disabled={viewMode} //We can also do this because viewMode can be either view or edit, and viewMode is null on createUser.
                    // value={userRole === "ADMIN" && "DRIVER"}
                    id="profile"
                    errors={errors}
                    setValue={setValue}
                    onChange={(e) => {
                      if (e.target.value !== "DRIVER") {
                        setValue("driverGroupId", null);
                        setValue("licenseNo", null);
                        setValue("licenseExpiryDate", null);
                      }
                    }}
                    displayKey="label"
                    valueKey="value"
                  />
                ) : (
                  <TextBox
                    type="text"
                    id="profile"
                    register={register}
                    disabled={viewMode}
                    errors={errors}
                    placeholder="Profile"
                  />
                )}
              </FormField>

              <FormField
                title="Employment Type"
                required={getValues("profile") !== SWIFT_CAFE_USER}
                id="employeeType"
              >
                <SelectBox
                  register={register}
                  defaultLabel="Please select"
                  data={userEmploymentTypeOptions}
                  disabled={viewMode === "view"} //We can also do this because viewMode can be either view or edit, and viewMode is null on createUser.
                  id="employeeType"
                  errors={errors}
                  setValue={setValue}
                  onChange={(e) => {
                    if (e.target.value === "Employee") {
                      setValue("abn", null);
                      setValue("tradingName", null);
                    } else if (e.target.value === "Subcontractor") {
                      setValue("tfn", null);
                      setValue("superannuationFund", null);
                      setValue("superannuationNo", null);
                    }
                  }}
                  displayKey="label"
                  valueKey="value"
                />
              </FormField>
              {getValues("profile") === "DRIVER" &&
                getValues("employeeType") && (
                  <FormField
                    title="Driver Group"
                    id="driverGroupId"
                    required={true}
                  >
                    <SelectBox
                      register={register}
                      defaultLabel="Please Select"
                      data={driverGroup}
                      setValue={setValue}
                      disabled={viewMode === "view"}
                      id="driverGroupId"
                      errors={errors}
                      displayKey="name"
                      valueKey="id"
                    />
                  </FormField>
                )}
              {getValues("profile") === "DRIVER" &&
                getValues("employeeType") && (
                  <FormField
                    title="Driver Type"
                    id="driverTypeId"
                    required={true}
                  >
                    <SelectBox
                      register={register}
                      defaultLabel="Please Select"
                      data={driverTypes}
                      setValue={setValue}
                      disabled={viewMode === "view"}
                      //   clearErrors={clearErrors}
                      id="driverTypeId"
                      errors={errors}
                      displayKey="name"
                      valueKey="id"
                    />
                  </FormField>
                )}
              {getValues("employeeType") === "Employee" && (
                <FormField title="TFN" id="tfn">
                  <TextBox
                    type="text"
                    register={register}
                    disabled={viewMode === "view"}
                    errors={errors}
                    id="tfn"
                    placeholder="TFN"
                  />
                </FormField>
              )}
              {getValues("employeeType") === "Employee" && (
                <FormField title="Superannuation Fund" id="superannuationFund">
                  <TextBox
                    type="text"
                    register={register}
                    disabled={viewMode === "view"}
                    errors={errors}
                    id="superannuationFund"
                    placeholder="Superannuation Fund"
                  />
                </FormField>
              )}
              {getValues("employeeType") === "Employee" && (
                <FormField title="Superannuation No." id="superannuationNo">
                  <TextBox
                    type="text"
                    register={register}
                    disabled={viewMode === "view"}
                    errors={errors}
                    id="superannuationNo"
                    placeholder="Superannuation Number"
                  />
                </FormField>
              )}
              {getValues("employeeType") === "Subcontractor" && (
                <FormField title="ABN Details" id="abn">
                  <TextBox
                    type="text"
                    register={register}
                    errors={errors}
                    disabled={viewMode === "view"}
                    id="abn"
                    placeholder="ABN Details"
                  />
                </FormField>
              )}
              {getValues("employeeType") === "Subcontractor" && (
                <FormField title="Trading Name" id="tradingName">
                  <TextBox
                    type="text"
                    register={register}
                    errors={errors}
                    disabled={viewMode === "view"}
                    id="tradingName"
                    placeholder="Trading Name"
                  />
                </FormField>
              )}
              {getValues("profile") === "DRIVER" && (
                <>
                  <SwitchField
                    title="Criminal Record"
                    id="criminalRecord"
                    register={register}
                    errors={errors}
                    disabled={viewMode === "view"}
                  />
                  {criminalRecord && (
                    <TextAreaField
                      required
                      title="Criminal Record Details"
                      id="criminalRecordDetails"
                      placeholder="Criminal Record Details"
                      register={register}
                      errors={errors}
                      disabled={viewMode === "view"}
                    />
                  )}
                  <SwitchField
                    title="Driving Disqualification"
                    id="drivingDisqualification"
                    register={register}
                    errors={errors}
                    disabled={viewMode === "view"}
                  />
                  {drivingDisqualification && (
                    <TextAreaField
                      required
                      title="Driving Disqualification Details"
                      id="drivingDisqualificationDetails"
                      placeholder="Driving Disqualification Details"
                      register={register}
                      errors={errors}
                      disabled={viewMode === "view"}
                    />
                  )}
                </>
              )}

              {getValues("profile") === "DRIVER" && (
                <>
                  <TextInputField
                    title="MSIC Number"
                    id="msicNumber"
                    placeholder="MSIC Number"
                    register={register}
                    errors={errors}
                    disabled={viewMode === "view"}
                  />
                  <TextInputField
                    title="MSIC Expiry Date"
                    id="msicExpiryDateStr"
                    placeholder="YYYY-MM-DD"
                    register={register}
                    errors={errors}
                    type="date"
                    max={MAX_DATE_STRING}
                    disabled={viewMode === "view"}
                  />
                  <FormField title="Visa Status" id="visaStatus">
                    <TextBox
                      type="text"
                      register={register}
                      disabled={viewMode === "view"}
                      errors={errors}
                      id="visaStatus"
                      placeholder="status"
                    />
                  </FormField>
                  <TextInputField
                    title="Medical Due Next"
                    id="medicalDueNext"
                    placeholder="YYYY-MM-DD"
                    register={register}
                    errors={errors}
                    type="date"
                    max={MAX_DATE_STRING}
                    disabled={viewMode === "view"}
                  />
                </>
              )}
            </div>

            <div className="col-md-6 px-5">
              <FormField
                title="Address"
                id="address"
                required={getValues("profile") !== SWIFT_CAFE_USER}
              >
                {/* TextBox comp misbehaving with id="address"*/}
                <input
                  id="address"
                  placeholder="Address"
                  name="address"
                  {...register("address")}
                  className={`form-control ${
                    errors["address"] ? "is-invalid" : ""
                  } `}
                  type="text"
                  disabled={viewMode === "view"}
                  // defaultValue={value}
                  autoComplete="off"
                />
                {errors && errors["address"] && (
                  <p className="invalid-feedback">
                    {" "}
                    {errors["address"].message}
                  </p>
                )}{" "}
              </FormField>
              <TextInputField
                title="Suburb/City"
                id="suburb"
                placeholder="Suburb/City"
                register={register}
                errors={errors}
                disabled={viewMode === "view"}
              />
              <TextInputField
                title="Post Code"
                id="postCode"
                placeholder="Post Code"
                register={register}
                errors={errors}
                disabled={viewMode === "view"}
              />
              <SelectField
                id="state"
                register={register}
                title="State"
                options={["NSW", "VIC", "QLD", "WA", "SA", "TAS"]}
                errors={errors}
                disabled={viewMode === "view"}
              />

              {/* Bank Details */}
              <TextInputField
                title="Bank Name"
                id="bankName"
                placeholder="Bank Name"
                register={register}
                errors={errors}
                disabled={viewMode === "view"}
              />
              <TextInputField
                title="Branch Name"
                id="branchName"
                placeholder="Branch Name"
                register={register}
                errors={errors}
                disabled={viewMode === "view"}
              />
              <TextInputField
                title="Account Name"
                id="accountName"
                placeholder="Account Name"
                register={register}
                errors={errors}
                disabled={viewMode === "view"}
              />
              <TextInputField
                title="Account Number"
                id="accountNumber"
                placeholder="Account Number"
                register={register}
                errors={errors}
                disabled={viewMode === "view"}
              />
              <TextInputField
                title="BSB"
                id="bsb"
                placeholder="BSB"
                register={register}
                errors={errors}
                disabled={viewMode === "view"}
              />
              <SelectField
                id="gender"
                register={register}
                title="Gender"
                options={["MALE", "FEMALE"]}
                errors={errors}
                disabled={viewMode === "view"}
              />
              <TextInputField
                title="Reference Id"
                id="referenceId"
                placeholder="Reference Id"
                register={register}
                errors={errors}
                disabled={viewMode === "view"}
              />
              <FormField title="Contact Number" id="contactNo" required={true}>
                <div className="input-group">
                  <span className="input-group-text" id="contactNo">
                    +61
                  </span>
                  <input
                    id="contactNo"
                    placeholder="Your 10 digits Contact Number"
                    name="contactNo"
                    {...register("contactNo")}
                    className={`form-control ${
                      errors["contactNo"] ? "is-invalid" : ""
                    }`}
                    type="number"
                    style={{
                      borderRadius: "0.375rem",
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                    aria-labelledby="Contact Number"
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    disabled={viewMode === "view"}
                    autoComplete="off"
                  />
                  {errors && errors["contactNo"] && (
                    <p className="invalid-feedback">
                      {errors["contactNo"].message}
                    </p>
                  )}
                </div>
              </FormField>

              <FormField
                title="Date of Birth"
                id="dateOfBirthStr"
                required={getValues("profile") !== SWIFT_CAFE_USER}
              >
                <TextBox
                  type="date"
                  register={register}
                  disabled={viewMode === "view"}
                  errors={errors}
                  id="dateOfBirthStr"
                  placeholder=""
                  max={new Date().toLocaleDateString("en-ca")}
                />
              </FormField>
              {getValues("profile") === "DRIVER" && (
                <FormField
                  title="License Number"
                  required={true}
                  id="licenseNo"
                >
                  <TextBox
                    type="text"
                    register={register}
                    errors={errors}
                    id="licenseNo"
                    placeholder="License Number"
                    disabled={viewMode === "view"}
                  />
                </FormField>
              )}
              {getValues("profile") === "DRIVER" && (
                <FormField
                  title="Licence Expiry Date"
                  id="licenseExpiryDateStr"
                  required={true}
                >
                  <TextBox
                    type="date"
                    register={register}
                    errors={errors}
                    max="9999-12-31"
                    id="licenseExpiryDateStr"
                    placeholder="Licence Expiry Date"
                    disabled={viewMode === "view"}
                  />
                </FormField>
              )}
              {getValues("profile") === "DRIVER" && (
                <SelectField
                  id="licenseType"
                  register={register}
                  title="Driver License Type"
                  options={["HC", "MR", "MC", "HR"]}
                  errors={errors}
                  disabled={viewMode === "view"}
                />
              )}
              {getValues("profile") === "DRIVER" && (
                <FormField title="BFM" id="bfmNo">
                  <TextBox
                    type="text"
                    register={register}
                    disabled={viewMode === "view"}
                    errors={errors}
                    id="bfmNo"
                    placeholder="BFM"
                  />
                </FormField>
              )}
              <FormField title="GST" id="gst">
                <ToggleSwitch
                  type="text"
                  register={register}
                  disabled={viewMode === "view"}
                  errors={errors}
                  id="gst"
                  scale={1.2}
                  placeholder="BFM"
                />
              </FormField>
            </div>
          </section>

          <div className="row mb-1 d-flex justify-content-end">
            <div className="col-md-2 px-3 ">
              {viewMode !== "view" && (
                <Button
                  title={viewMode === "edit" ? "Save" : "Submit"}
                  type="submit"
                  className="buttons"
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default UserDetailsForm;
