import Vehicle from "../assets/iconsWhite/vehicle.png";
import Allocators from "../assets/iconsWhite/allocators.png";
import Container from "../assets/iconsWhite/containers.png";
import Customers from "../assets/iconsWhite/customers.png";
import Jobs from "../assets/iconsWhite/jobs.png";
import Runsheets from "../assets/iconsWhite/runsheets.png";
import Yards from "../assets/iconsWhite/yards.png";
import * as Navigation from "./routes";

export const routeFinder = (name) => {
   switch (name) {
      case "Vehicles":
         return Navigation.INDUCTION_LEARNING_VEHICLE;
      case "Allocators":
         return Navigation.INDUCTION_LEARNING_ALLOCATORS;
      case "Containers":
         return Navigation.INDUCTION_LEARNING_CONTAINER;
      case "Customers":
         return Navigation.INDUCTION_LEARNING_CUSTOMER;
      case "Runsheets":
         return Navigation.INDUCTION_LEARNING_RUNSHEETS;
      case "Yards":
         return Navigation.INDUCTION_LEARNING_YARDS;
      case "Jobs":
         return Navigation.INDUCTION_LEARNING_JOBS;
   }
}

export const iconFinder = (name) => {
   switch (name) {
      case "Vehicles":
         return Vehicle;
      case "Allocators":
         return Allocators;
      case "Containers":
         return Container;
      case "Customers":
         return Customers;
      case "Runsheets":
         return Runsheets;
      case "Yards":
         return Yards;
      case "Jobs":
         return Jobs;
   }
}

export const activeButtonFinder = (name) => {
   switch (name) {
      case "Vehicles":
         return "vehicle";
      case "Allocators":
         return "allocators";
      case "Containers":
         return "containers";
      case "Customers":
         return "customers";
      case "Runsheets":
         return "runsheets";
      case "Yards":
         return "yards";
      case "Jobs":
         return "jobs";
   }
}