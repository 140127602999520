import React from "react";

const SidebarComponent = (props) => {
  return (
    <div className={props.activeBtn === true ? "sidebar-component sidebar-component-bg-color" : "sidebar-component"}>
      <img src={props.icon} alt="Icon" width={45} className="sidebar-img" />
      <p className="sidebar-name-learning">{props.name}</p>
    </div>
  );
};

export default SidebarComponent;