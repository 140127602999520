import { ResponsivePie } from "@nivo/pie";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
const GroupByColumnPie = ({
  data,
  id = "id",
  value = "value",
  theme = "purple_orange",
  ...props
}) => (
  <ResponsivePie
    data={data}
    id={id}
    value={value}
    margin={{ top: 40, right: 60, bottom: 40, left: 60 }}
    innerRadius={0.7}
    padAngle={0.7}
    cornerRadius={10}
    activeOuterRadiusOffset={8}
    colors={{ scheme: theme }}
    borderWidth={1}
    borderColor={{ theme: "background" }}
    arcLinkLabel="label"
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextColor={{ from: "color", modifiers: [] }}
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: "color" }}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor={{
      from: "color",
      modifiers: [["brighter", 3]],
    }}
    legends={[]}
    {...props}
  />
);

export default GroupByColumnPie;
