/* A React component that is used to view and edit a user. */
import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import config from "../../constants/config";
import "./UserManagement.scss";
import {
  RatesTab,
  DocumentsUpload,
  PasswordTab,
  UserDetailsForm,
  DeductionTab,
  AdditionTab,
  TfnTab,
  ProfilePicture,
} from "./TabPages";
import { getData, postData } from "../../utils/api";
import DriversOperations from "./TabPages/Driver/DriversOperations";
import InviteTab from "./TabPages/InviteTab";
import DeclarationTab from "./TabPages/DeclarationTab";
import InductionDocuments from "./TabPages/InductionDocuments";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const ViewEditUser = ({ userId, viewMode }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [driverCurrentRates, setDriverCurrentRates] = useState();
  const [driverDeductions, setDriverDeductions] = useState();
  const [driverAdditions, setDriverAdditions] = useState();
  const [driverTFN, setDriverTFN] = useState();
  const [driversDeclaration, setDriversDeclaration] = useState(null);
  const [inductionDocs, setInductionDocs] = useState(null);
  const isDriversOperation =
    window.location.pathname.includes("drivers-operation");
  const navigate = useNavigate();

  useEffect(() => {
    /**
     * It fetches the user details from the database and sets the state of the user details.
     */
    const getUserDetails = async (userId) => {
      if (userId) {
        const url = config.baseUrl + config.getUserList + "?id=" + userId;
        const data = await postData(url, {});
        // const data = await getUserDetailsById(userId);
        if (data) {
          let formData = data.data?.["0"];
          formData && setUserDetails(formData);
        }
      }
    };
    /**
     * If the userId is present, then get the user rates by id, and if the response data is an
     * array, then set the driver current rates to the response data, otherwise set the driver current
     * rates to an empty array.
     */
    const getDriverCurrentRates = async (userId) => {
      if (userId > 0) {
        const url =
          config.baseUrl + config.getDriverCurrentRates + "?driverId=" + userId;
        const resp = await getData(url);
        if (resp?.data && Array.isArray(resp.data)) {
          setDriverCurrentRates({ rates: resp.data });
        } else {
          setDriverCurrentRates({ rates: [] });
        }
      }
    };
    const getDriverDeductionDetails = async (userId) => {
      if (userId > 0) {
        const url =
          config.baseUrl + config.getDriverDeductions + "?driverId=" + userId;
        const resp = await getData(url);
        if (resp?.data && Array.isArray(resp.data)) {
          setDriverDeductions({ ...resp.data });
        } else {
          setDriverDeductions({ ...resp.data });
        }
      }
    };
    const getDriverAdditionDetails = async (userId) => {
      if (userId > 0) {
        const url =
          config.baseUrl +
          config.driverAdditionsUrls.getDriverAdditions +
          "?driverId=" +
          userId;
        const resp = await getData(url);
        if (resp?.data && Array.isArray(resp.data)) {
          setDriverAdditions({ ...resp.data });
        } else {
          setDriverAdditions({ ...resp.data });
        }
      }
    };
    const getDriverTFNsById = async (userId) => {
      if (userId > 0) {
        const url =
          config.baseUrl +
          config.driverTfnUrls.getDriverTfnDetails +
          "?driverId=" +
          userId;
        const resp = await getData(url);
        if (resp?.data && Array.isArray(resp.data)) {
          setDriverTFN({ ...resp.data });
        } else {
          setDriverTFN({ ...resp.data });
        }
      }
    };

    getUserDetails(userId);
    getDriverCurrentRates(userId);
    getDriverDeductionDetails(userId);
    getDriverAdditionDetails(userId);
    getDriverTFNsById(userId);
  }, [userId]);

  useEffect(() => {
    if (userDetails && !driversDeclaration) {
      const getDriverDeclarations = async (driverId) => {
        const url = config.baseUrl + config.getDriverDeclarations + driverId;
        const response = await getData(url);
        if (response.status === 200) {
          setDriversDeclaration(response.data.agreement);
        }
      };
      getDriverDeclarations(userDetails.userId);
    }
  }, [userDetails]);

  useEffect(() => {
    return () => {
      setDriversDeclaration(null);
      setInductionDocs(null);
    };
  }, []);

  return (
    <div className="d-flex flex-column">
      {/* using same styles as createUser component */}
      <div className="createUser__header">
        {isDriversOperation && (
          <button
            type="button"
            className="viewEditUser-btn d-flex align-items-center justify-content-center"
            onClick={() => navigate(-1)}
          >
            <IoMdArrowRoundBack style={{ marginRight: "8px" }} />
            Back
          </button>
        )}
        <div className="createUser__headerImage">
          <ProfilePicture
            submitPictureUrl={config.baseUrl + config.uploadProfilePicture}
            getPictureUrl={config.baseUrl + config.getProfilePicture}
            viewEditMode={viewMode}
            id={userId}
          />
        </div>

        <h2 className="text-white text-center p-5">
          {viewMode.toUpperCase()} USER
        </h2>
      </div>
      <div className="createEditView__bg">
        <div className="col-md-10 mx-auto py-2 tabs-user ">
          <Tabs
            className="blur-bg border-white rounded-top"
            defaultActiveKey="userdetails"
            fill
            // justify
            id="uncontrolled-tab-example"
          >
            <Tab
              title="Users Details"
              eventKey="userdetails"
              className="border-white"
            >
              {userDetails && (
                <UserDetailsForm
                  defaultValues={userDetails}
                  viewEditMode={viewMode}
                  refreshAllUsersData={true}
                  id={userId}
                  className="border-white"
                  OnSubmitFunction={(res, formData) => {
                    if (res.status === 200)
                      setUserDetails((prev) => ({
                        ...prev,
                        ...formData,
                        timestamp: res.timestamp,
                      }));
                  }}
                />
              )}
            </Tab>

            {viewMode === "edit" && !isDriversOperation && (
              <Tab title="Password Management" eventKey="password">
                <PasswordTab viewEditMode={viewMode} id={userId} />
              </Tab>
            )}
            {!isDriversOperation && (
              <Tab title="Rates" eventKey="rates">
                <div className="shadow">
                  {driverCurrentRates && (
                    <RatesTab
                      defaultValues={driverCurrentRates}
                      viewEditMode={viewMode}
                      driverId={userId}
                      submitUrl={config.baseUrl + config.saveDriverRates}
                    />
                  )}
                </div>
              </Tab>
            )}
            {!isDriversOperation && (
              <Tab title="Deductions" eventKey="deductions">
                <div className="shadow">
                  {driverDeductions && (
                    <DeductionTab
                      defaultValues={driverDeductions}
                      viewEditMode={viewMode}
                      driverId={userId}
                      submitUrl={config.baseUrl + config.saveDriverDeductions}
                    />
                  )}
                </div>
              </Tab>
            )}
            {!isDriversOperation && (
              <Tab title="Additions" eventKey="additions">
                <div className="shadow">
                  {driverAdditions && (
                    <AdditionTab
                      defaultValues={driverAdditions}
                      viewEditMode={viewMode}
                      driverId={userId}
                      submitUrl={
                        config.baseUrl +
                        config.driverAdditionsUrls.saveDriverAdditions
                      }
                    />
                  )}
                </div>
              </Tab>
            )}
            {!isDriversOperation && (
              <Tab title="TFN" eventKey="tfn">
                <div className="shadow">
                  {driverTFN && (
                    <TfnTab
                      defaultValues={driverTFN}
                      viewEditMode={viewMode}
                      driverId={userId}
                    />
                  )}
                </div>
              </Tab>
            )}
            <Tab title="Documents" eventKey="documents" disabled={false}>
              <div className=" shadow">
                <DocumentsUpload driverId={userId} viewEditMode={viewMode} />
              </div>
            </Tab>
            {userDetails?.profile === "DRIVER" && (
              <Tab title="Drivers" eventKey="drivers">
                <div className="shadow">
                  <DriversOperations
                    viewStats={{
                      mode: viewMode,
                      driver: userDetails,
                    }}
                  />
                </div>
              </Tab>
            )}
            <Tab title="Invite" eventKey="invite" disabled={!userDetails}>
              <div className="shadow">
                <InviteTab userId={userId} />
              </div>
            </Tab>
            {userDetails?.profile === "DRIVER" && driversDeclaration && (
              <Tab
                title="Declaration"
                eventKey="declaration"
                disabled={!userDetails}
              >
                <div className="shadow" style={{ textAlign: "left" }}>
                  <DeclarationTab
                    viewMode={viewMode}
                    driversDeclaration={driversDeclaration}
                    driverId={userDetails.userId}
                  />
                </div>
              </Tab>
            )}
            {userDetails?.profile === "DRIVER" && (
              <Tab
                title="Induction Docs"
                eventKey="inductionDocs"
                disabled={!userDetails}
              >
                <div className="shadow" style={{ textAlign: "left" }}>
                  <InductionDocuments
                    inductionDocs={inductionDocs}
                    setInductionDocs={setInductionDocs}
                    driverId={userDetails.userId}
                  />
                </div>
              </Tab>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ViewEditUser;
