import React from "react";
import "../SwiftTrafficSignup/swiftTrafficSignup.scss";
import PPERequirementsPdf from "./components/PPERequirementsPdf";
import { getUserData } from "../../rtk-slice/globalSlice";
import { useSelector } from "react-redux";
import LoginForDriver from "../SwiftTrafficSignup/components/LoginForDriver";

function PPERequirements() {
  const userData = useSelector(getUserData);
  return (
    <div className="swift-traffic">
      {userData && userData.success ? <PPERequirementsPdf /> : <LoginForDriver />}
    </div>
  );
}

export default PPERequirements;
