import React from 'react'

function ConditionOfCarriageContent() {
  return (
    <div className="ConditionsOfCarriage" style={{ textTransform: "initial" }}>
      <h4>Conditions of Carriage</h4>
      <p>
        The following conditions apply to all contracts for carriage of goods in
        which ACKNOWLEDGE, the placing of an order by a consignor with carrier
        of goods (which if accepted by the carrier) shall constitute assent by
        that consignor to be bound by the conditions herein.
      </p>
      <ol>
        <li>
          In these Conditions “Carrier” shall mean Swift Container Services ABN
          50 160 427 396, and its subsidiary companies, its servants and agents
          and, where the context requires, its subcontractors. “Consignor” shall
          mean the person, firm, body, corporation or company who places an
          order with the Carrier for the carriage of the Goods. “Subcontractor”
          shall mean and include; (a) Railways operated by the Commonwealth or
          any State or Territory thereof or by any other party: (b) air
          transport services operated by any party; (c) any other person, firm,
          body corporate or company with whom the Carrier may arrange the Goods;
          and (d) any person who is now or hereafter a servant, agent, employee
          or subcontractor of any of the persons or utilities referred to in (a)
          (b) or (c) above. “Goods” shall mean the goods which are the subject
          of this Contract and shall include livestock of every description and
          any other incidental items or accessories with them. “Carriage” shall
          mean where the context permits, the despatch, pick-up, carriage,
          transportation, storage, consignment, delivery agistment, resting,
          temporary depasturing, livery or any other service performed by the
          Carrier in relation to the Goods. The same meaning shall be attributed
          to the words “carry” and “carried” when used in this Contract.
        </li>
        <li>
          THE CARRIER IS NOT A COMMON CARRIER and will accept no liability as
          such. All Goods are carried by the Carrier subject only to these
          Conditions and the Carrier reserves the right to refuse the carriage
          of Goods for any person, firm, body, corporation or company and the
          carriage of any class of Goods at its discretion without assigning any
          reason therefore.
        </li>
        <li>
          The Carrier reserves the right at its absolute discretion to carry the
          Goods by any route it thinks fit and either alone or with Goods
          belonging to any other person, firm, body, corporation or company. The
          Carrier at its absolute discretion and without assigning any reason
          therefore, may deviate from or alter, at any time the route chosen by
          it for the carriage of Goods. The Consignor hereby authorizes any such
          deviation(s) or alteration(s) which shall be deemed necessary and
          reasonable in the circumstances.{" "}
        </li>
        <li>
          The Carrier reserves the right at its absolute discretion to carry the
          Goods in any manner, by any means, by any method and by any route, as
          it thinks fit. The Carrier, at its absolute discretion and without
          assigning any reason therefore, may alter at any time the manner,
          means or method chosen by it for the carriage of the Goods. The
          consignor hereby authorizes any such alteration(s) which shall be
          deemed necessary and reasonable in the circumstances PROVIDED THAT if
          the Consignor expressly directs the Carrier to use or adopt or it is
          expressly agreed that the Carrier will use or adopt a particular
          manner, method of means of carrying the Goods, the Carrier will use
          its best endeavors to use or adopt same HOWEVER if that manner, method
          or means cannot be used or adopted at the date of the planned movement
          of the Goods for any reason whatsoever, the Consignor hereby
          authorizes the Carrier to carry the Goods by or in an alternative
          manner, method of means, as the Carrier thinks fit.
        </li>
        <li>
          The Consignor hereby authorizes the Carrier (if it should think fit to
          do so) to arrange a Subcontractor or Subcontractors for the carriage
          of the Goods. Any such arrangement shall be deemed to be ratified by
          the Consignor upon delivery of the Goods to such Subcontractor,
          whether by the Consignor, the Carrier or a Subcontractor, and the
          Subcontractor who accepts delivery shall thereupon by entitled to the
          full benefit of these terms and conditions to the same extent as the
          Carrier. The Consignor hereby expressly agrees and acknowledges that,
          in so far as it may be necessary to ensure that such Subcontractor or
          Subcontractors shall be so entitled, the Carrier shall be deemed to
          enter into this Contract for its own benefit and also as agent for the
          Subcontractor or Subcontractors.
        </li>
        <li>
          The Goods are at the risk of the Consignor at all times and under no
          circumstances does the Carrier undertake to insure or arrange for the
          insurance of the Goods against any insurable risk whatsoever. The
          Consignor warrants that the Goods are fully insured against all risks
          which may arise during the Carriage of the Goods and that, unless
          specifically notified to the Carrier in writing prior to the
          commencement of the Carriage.
        </li>
        <li>
          Unless expressly agreed in writing and subject to Condition 24 of this
          Contract, the Carrier shall not be responsible in tort contract or
          otherwise for any loss of or damage or injury to or deterioration of
          the Goods or mis-delivery or failure to deliver or delay in delivery
          of the Goods either in transit, storage, agistment, livery or carriage
          for any reason whatsoever including without limiting the foregoing the
          negligence, willful act or default of the Carrier or others and the
          Consignor hereby indemnifies the Carrier accordingly. This Condition
          shall apply to all such loss of or damage or injury to or
          deterioration of the Goods or mis-delivery or failure to deliver or
          delay in delivery of the Goods as aforesaid whether or not the same
          occurs in the course of performance by the Carrier of this Contract or
          in events which are within the contemplation of the Carrier and/or
          Consignor or in events which are foreseeable by them or either of them
          or in events which would constitute a fundamental breach of contract
          or a breach of a fundamental term hereof.
        </li>
        <li>
          In no event shall the Carrier be liable whether in tort or contract or
          otherwise for any special, consequential, indirect or liquidated
          damages or losses of any nature whatsoever arising from the Carriage
          of the Goods or otherwise.
        </li>
        <li>
          The Consignor hereby agrees to indemnify the Carrier in respect of any
          loss, damage or injury caused to the carrier or any other person or
          any property of the Carrier or any other person by the Goods during
          the Carriage thereof and further agrees to indemnity and keep the
          Carrier indemnified against all damages, actions, claims, suits and
          demands made by any person in respect of such loss, damage or injury.
        </li>
        <li>
          The Carrier’s changes shall be considered earned as soon as the Goods
          are received into the hands of the Carrier. The Consignor
          (notwithstanding that it may not be the owner of the Goods) will be
          and remain responsible to the Carrier for all its proper charges
          incurred for any reason whatsoever in relation to the Carriage of the
          Goods. Unless otherwise stipulated by the Carrier, payment in full of
          charges due to the Carrier shall be made within the trading terms
          stipulated on the Carrier’s invoice.
        </li>
        <li>
          Any queries regarding review of pricing and/or services to be raised
          within 7 days of delivery date, otherwise will remain due and payable
          as per the invoice amount. A fuel surcharge and toll surcharge is
          applicable to all invoices. We reserve the right to require you to pay
          all or part of the charges for the services upon placing the order.
        </li>
        <li>
          The Consignor warrants to the Carrier that he or it is either the
          owner of the Goods or the agent authorized by the owner to deliver the
          Goods to the Carrier for the carriage thereof and to enter into this
          Contract or any variation thereof AND by entering into any arrangement
          for the carriage of the Goods the Consignor expressly accepts these
          conditions of contract for the consignee as well as for all other
          persons on whose behalf the Consignor is acting.
        </li>
        <li>
          The Consignor warrants that any person who places an order with the
          Carrier for the carriage of Goods on behalf of the Consignor is
          authorized to do so by it.
        </li>
        <li>
          The consignor hereby indemnifies the Carrier against any claims,
          suits, actions, demand, losses, costs, damages, and expenses incurred
          by the Carrier arising from a breach of one or both of the warranties
          contained in Conditions 13 and/or 14 of this Contract.
        </li>
        <li>
          This contract shall apply to the carriage of the Goods until the Goods
          are delivered to the address for delivery given to the Carrier by the
          Consignor.
        </li>
        <li>
          The Carrier is authorized to pick up the Goods from the address for
          collection and to deliver the Goods to the address for delivery given
          to the Carrier by the Consignor and shall be taken to have delivered
          the Goods for the purposes of this Contract if, at the said address it
          obtains from any persona receipt or a signed delivery order or
          acknowledgement of delivery for the Goods OR the Carrier delivers to
          the Consignor a certificate in writing signed by a duly authorized
          representative to the Carrier that the Goods were delivered at the
          address for the delivery given to the carrier by the Consignor.
        </li>
        <li>
          It is the responsibility of the Consignor to give the Carrier the
          correct address for pickup and delivery and to arrange for a
          responsible persons to be present at those addresses at the time of
          pick up and delivery to enable the Carrier to pick up the Goods and to
          make effective delivery and the Carrier shall not be liable for any
          delay in onward carriage or delivery or loss or damage resulting from
          the Consignor’s failure to comply with this Condition.
        </li>
        <li>
          In the event that the Consignor or a representative of the Consignor,
          subsequent to dispatch of the Goods, directs the Carrier to deliver
          the Goods to an address (“ the amended address”) which is not the
          address for delivery originally given to the Carrier by the Consignor
          (“the original address”), the Carrier will use its best endeavours to
          have Goods redirected to the amended address HOWEVER the Carrier does
          not promise that it will be able to redirect the Goods and is not
          responsible for any delay that may be caused by misdirection. If the
          Goods cannot be redirected and are delivered to the original address,
          the Carrier accepts no responsibility for arranging the carriage of
          the Goods from the original address to the amended address.
        </li>
        <li>
          The Consignor shall comply with all the relevant laws, customs, and
          regulations of the Commonwealth of Australia and any State or
          Territory therefore and if the Goods are to be exported, the country
          to which the Goods are to be exported, in relation to the preparation
          or handling of the Goods for carriage and shall furnish all
          information and provide all notices an documents in connection with
          the Goods as may be necessary to comply with such laws, customs and
          regulations. The Carrier accepts no responsibility for any damage,
          loss or expense incurred due to the Consignor’s failure to comply with
          such laws, customs and regulations.
        </li>
        <li>
          The Carrier shall not be held liable for its failure to comply with
          any of the terms and conditions of this Contract caused solely by
          fire, strike, war, insurrection, government restrictions, riots, acts
          of God, acts of third parties including the outbreak of any infectious
          disease or other causes beyond its control and without its fault
          HOWEVER it shall use its best endeavours to cure such default and to
          comply with the terms and conditions of this Contract as quickly as
          possible.
        </li>
        <li>
          Without derogating from Conditions 6 to 9 (inclusive) of this
          Contract, it is expressly agreed that all the rights, warranties,
          indemnities, exemption or exception from and limitations of liability
          granted to the carrier by the provisions of tis Contract shall have
          and continue to have their full force and effect in all circumstances,
          whether or not the same occur in the course of performance by the
          Carrier of the Contract or are within the contemplation of the Carrier
          and/or the Consignor or are foreseeable by them or either of them or
          would constitute a fundamental breach of contract or a breach of a
          fundamental term hereof.
        </li>
        <li>
          Notwithstanding anything herein contained, the Carrier shall be bound
          by any implied warranty under the Trade Practices Act (C’wth) of 1974
          or the Fair Trading Act (NSW) 1997 and any similar relevant
          legislation but only to the extent that such Act is applicable to this
          Contract and prevents the exclusion, restriction or modification of
          that warranty and, subject to the exceptions contained in that Act, in
          such case, the liability of the Carrier is limited to the supplying of
          the services again or the payment of the cost of having the services
          supplied again, as determined by the Carrier.
        </li>
        <li>
          This Contract shall be governed in all respects by and construed
          according to the law of the State of New South Wales and the parties
          hereto hereby agree to submit to the jurisdiction of the Courts of the
          said State.
        </li>
        <li>
          No purported variation or modification of this Contract shall have any
          effect unless in writing signed by an executive officer of the
          Carrier. It is acknowledged by the parties to this Contract that these
          Conditions and any lawful variation thereof shall constitute the
          entire agreement between the parties.
        </li>

        <li>
          “In writing” for the purposes of this Contract shall mean by way of
          letter, fax or telegram sent to the last known business address of the
          receiving party.
        </li>
        <li>
          Words importing the singular number or plural number when used in this
          Contract shall include the plural number and singular number
          respectively and words imploring the masculine gender shall include
          the feminine or neuter gender.
        </li>
        <li>
          Additional fees will be charged if referred to debt collectors plus
          any other fees associated to outsourcing the recovery of the funds. In
          addition, there will be 15% surcharge in addition to the original debt
          and an additional 18% surcharge calculated once per annum (calculated
          daily) for the operational costs of recovering the debt.
        </li>
        <li>
          NON Competition deed; I agree that upon engagement with Swift
          Container Services that I will not without agreed written consent of
          both the companies, either directly or indirectly in any capacity
          carry on, advise, solicit, provide services or be engaged with any
          party or concerned party ANY business activity which is in competition
          with the business carried on by the company; and for a period of one
          year after the termination of the engagement, for whatever reason, NOR
          will I canvass, solicit or endeavour to entice away from the company
          any person or organization which at the time of the termination of my
          engagement was a client of the company, NOR will I solicit, interfere
          with or Endeavour to entice away an employee or sub-contractor of the
          company; or cancel, procure, influence or otherwise assist any person
          to do any of the above acts. In the event of breaching this clause,
          “I” being the company engaged will be liable for damages resulting
          from “Swift Container Services” loss of goodwill by virtue of the
          Sub-Contractor or Company enticing away from the original agreement
          and such damages in respect thereof, “I” the company will be liable to
          pay by way of liquidated damages in regard to such loss. I have been
          verbally informed or by notice and understand and acknowledge the
          above.
        </li>
      </ol>
    </div>
  );
}

export default ConditionOfCarriageContent