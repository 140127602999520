import React, { useState } from "react";
import "./ScreenContent.css";
import AddItem from "./AddItem";
import { useEffect } from "react";
import { useContext } from "react";
import Spinner from "../Spinner/Spinner";
import { useLocation } from "react-router-dom";
import PlusBtn from "./PlusBtn";
import CustomPopup from "./CustomPopUp";
import EditPopUp from "./EditPopUp";
import { RefreshScreenContext } from "../../context/RefreshScreen";
import { ModulesContext } from "../../context/ModulesContext";
import { getModulesList } from "../../InductionLearningServices/Modules";
import { useLoadingContext } from "../../context/LoadingContext";
import usePopup from "../../hooks/usePopUp";
import DeletePopUp from "./DeletePopUp";
import { ModuleIdContext } from "../../context/TrainingsContext";

const ScreenContent = () => {
  const { setIsLoading } = useLoadingContext();
  const { modules, setModules } = useContext(ModulesContext);
  const { refresh, setRefresh } = useContext(RefreshScreenContext);
  const { moduleId, setModuleId } = useContext(ModuleIdContext);
  const location = useLocation();
  const onTap = location.state.onTap;
  const deletePopup = usePopup();

  const openPopupDelete = deletePopup.openPopup;
  const closePopupDelete = () => {
    deletePopup.closePopup();
  };
  const isDeleteOpen = deletePopup.isOpen;

  const [isEditOpen, setIsEditOpen] = useState(false);

  const openPopupEdit = () => {
    setIsEditOpen(true);
  };

  const closePopupEdit = () => {
    setIsEditOpen(false);
  };

  const [pdfError, setPdfError] = useState(null);
  const [videoError, setVideoError] = useState(null);

  useEffect(() => {
    const fetchModulesData = async () => {
      setIsLoading((prev) => true);
      let response = await getModulesList(onTap.id);
      setModules((prev) => response.data);
      setIsLoading((prev) => false);
      setRefresh((prev) => false);
    };

    if (onTap.id != "" || refresh) {
      fetchModulesData();
    }

  }, [onTap, refresh]);

  return (
    <div className="screen-main-container">
      <div className="heading-container-screen">
        <p className="screen-heading-title">{onTap.trainingName}</p>

        {
          modules.length != 4
            ? <div onClick={openPopupEdit} style={{ cursor: "pointer" }}>
              <PlusBtn />
            </div> : null
        }

      </div>
      {/* Table */}
      <div className="screen-table">
        <div className="screen-table-content-container">
          {
            modules.length != 0
            && modules.map((value) => {
              return <AddItem key={value.moduleId}
                name={value.moduleName}
                moduleId={value.moduleId}
                openPopupDelete={openPopupDelete}
              />
            })
          }
          {
            modules.length == 0 && <div className="no-data">Please click on <PlusBtn /> button to add Modules.</div>
          }
          <CustomPopup isOpen={isEditOpen} onClose={closePopupEdit} height="60%" width="50%">
            <EditPopUp
              pdfError={pdfError}
              videoError={videoError}
              modules={modules}
              closePopupEdit={closePopupEdit}
            />
          </CustomPopup>

          {
            moduleId != ""
              ? <CustomPopup isOpen={isDeleteOpen} onClose={closePopupDelete} height="20%" width="40%">
                <DeletePopUp
                  moduleId={moduleId}
                  closePopupDelete={closePopupDelete}
                />
              </CustomPopup>
              : null
          }

        </div>
      </div>
    </div>
  );
};

export default ScreenContent;
