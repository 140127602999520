import React, { createContext, useContext, useState } from 'react';

export const SearchContext = createContext({
   nameOfUser: ""
});

export const useSearchContext = () => {
   return useContext(SearchContext);
};

export const SearchProvider = ({ children }) => {
   const [nameOfUser, setNameOfUser] = useState("");

   return (
      <SearchContext.Provider value={{nameOfUser, setNameOfUser}}>
         {children}
      </SearchContext.Provider>
   );
};
