import React, { createContext, useState, useEffect } from 'react';
import { getTrainingData } from '../InductionLearningServices/Trainings';

export const TrainingsContext = createContext();

export const TrainingsProvider = ({ children }) => {
   const [data, setData] = useState([]);
   const [error, setError] = useState(null);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      const fetchTrainingData = async () => {
         let response = await getTrainingData();
         setData(response.data);
         setLoading(false);
      }

      if(loading) {
         fetchTrainingData();
      }

   }, [loading]);

   return (
      <TrainingsContext.Provider value={{
         data, setData,
         error, setError,
         loading, setLoading
      }}>
         {children}
      </TrainingsContext.Provider>
   );
};

export const TrainingIdContext = createContext();

export const TrainingIdProvider = ({ children }) => {
   const [trainingId, setTrainingId] = useState("");

   return (
      <TrainingIdContext.Provider value={{
         trainingId, setTrainingId
      }}>
         {children}
      </TrainingIdContext.Provider>
   );
};

export const TrainingNameContext = createContext();

export const TrainingNameProvider = ({ children }) => {
   const [trainingName, setTrainingName] = useState("Name");

   return (
      <TrainingNameContext.Provider value={{
         trainingName, setTrainingName
      }}>
         {children}
      </TrainingNameContext.Provider>
   );
};

export const ActiveButtonContext = createContext();

export const ActiveButtonProvider = ({ children }) => {
   const [activeBtn, setActiveBtn] = useState("");

   return (
      <ActiveButtonContext.Provider value={{
         activeBtn, setActiveBtn
      }}>
         {children}
      </ActiveButtonContext.Provider>
   );
};

export const ModuleIdContext = createContext();

export const ModuleIdProvider = ({ children }) => {
   const [moduleId, setModuleId] = useState("");

   return (
      <ModuleIdContext.Provider value={{
         moduleId, setModuleId
      }}>
         {children}
      </ModuleIdContext.Provider>
   );
};

export const OnTapContext = createContext();

export const OnTapProvider = ({ children }) => {
   const [onTap, setOnTap] = useState(null);

   return (
      <OnTapContext.Provider value={{
         onTap, setOnTap
      }}>
         {children}
      </OnTapContext.Provider>
   );
};