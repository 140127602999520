import React, { useContext, useState } from 'react';
import CheckBox from "../../../assets/InductionLearningAssets/check-square.png";
import View from "../../../assets/InductionLearningAssets/view.png";
import CustomPopUp from "../CustomPopUp";
import McqProgressPopUp from '../McqProgressPopUp';
import { McqProgressContext } from './../../../context/McqProgress';

const ProgressBar = (props) => {
    const { mcqList, setMcqList } = useContext(McqProgressContext);
    const [viewMcqProgress, setViewMcqProgress] = useState(false);

    const openMcqProgress = () => {
        setViewMcqProgress(true);
    }

    const closeMcqProgress = () => {
        setViewMcqProgress(false);
    }

    let value;
    if (parseFloat(props.percent) > 1) {
        value = parseFloat(props.percent) / 100;
    } else {
        value = props.percent;
    }

    const percentage = parseFloat(value).toFixed(2) * 100 + " % Done";
    const percent = parseFloat(value).toFixed(2) * 100;

    let linearGradient = "linear-gradient(to right, ";
    if (percent <= 49) {
        linearGradient += "rgba(233, 79, 61, 1) " + percent.toString() + "%, rgba(241, 195, 190, 1) " + (100 - percent).toString() + "%)";
    } else if (percent >= 75) {
        linearGradient += "rgba(52, 183, 114, 1) " + percent.toString() + "%, rgba(187, 226, 206, 1) " + (100 - percent).toString() + "%)";
    } else {
        linearGradient += "rgba(249, 178, 51, 1) " + percent.toString() + "%, rgba(246, 225, 187, 1) " + (100 - percent).toString() + "%)";
    }

    const showMcqList = () => {
        if (props.type == "mcq") {
            setMcqList((prev) => props.mcqList);
            openMcqProgress();
        }
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around"
            }}
        >
            {
                props.type == "mcq"
                    ? <div
                        style={{
                            display: "flex",
                            marginBottom: "10px"
                        }}
                    >
                        {/* <img src={CheckBox} alt='check' style={{ marginRight: "10px" }} /> */}
                        {
                            props.mcqList != null & props.mcqList != []
                                ? <div onClick={showMcqList} style={{ cursor: "pointer" }}>
                                    <img src={View} alt='view' />
                                </div>
                                : null
                        }


                    </div>
                    : <div
                        style={{
                            display: "flex",
                            marginBottom: "10px"
                        }}
                    >
                        <img src={CheckBox} style={{visibility: "hidden"}} alt='check' />
                        {/* <div onClick={showMcqList} style={{ cursor: "pointer" }}>
                        <img src={View} alt='view' />
                    </div> */}

                    </div>
            }

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "400",
                    fontSize: "10px",
                    color: "rgba(24, 33, 37, 0.89)",
                    width: "167px",
                    height: "15px",
                    borderRadius: "8px",
                    background: linearGradient
                }}
            >
                {props.percent} %
            </div>

            <CustomPopUp isOpen={viewMcqProgress} onClose={closeMcqProgress} height="90%" width="80%">
                <McqProgressPopUp
                    mcq={props.mcqList}
                />
            </CustomPopUp>
        </div>
    );
}

export default ProgressBar;