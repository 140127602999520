import { deleteSuccessOrFailure, showOnlyOnError } from "../constants/ErrorHandling";
import config from "../constants/config";
import { deleteApiCall, getApiCall } from "../utils/api";


export async function getModulesList(trainingId) {
   const url = config.baseUrlInductionLearning + "/admin/v1/training/" + trainingId + "/moduleList";

   try {
      const response = await getApiCall(url);
      return response.data;
   } catch (err) {
      showOnlyOnError(err.response.status, err.response.data);
      return {"data": []};
   }
}

export async function getModuleItem(moduleId) {
   const url = config.baseUrlInductionLearning + "/admin/v1/moduleItem/" + moduleId;

   try {
      const response = await getApiCall(url);
      return response.data;
   } catch (err) {
      return null;
   }
}

export async function getMcqModule(moduleId) {
   const url = config.baseUrlInductionLearning + "/admin/v1/moduleItem/" + moduleId + "/mcq";

   try {
      const response = await getApiCall(url);
      return response.data;
   } catch (err) {
      return null;
   }
}

export async function getTextModule(moduleId) {
   const url = config.baseUrlInductionLearning + "/admin/v1/module/view/text/" + moduleId;

   try {
      const response = await getApiCall(url);
      return response;
   } catch (err) {
      return null;
   }
}

export async function deleteModule(moduleId) { 
   const url = config.baseUrlInductionLearning + "/admin/v1/training/" + moduleId;

   try {
      const response = await deleteApiCall(url);
      deleteSuccessOrFailure(response.status, response.data);
      return response;
   } catch (err) {
      deleteSuccessOrFailure(err.response.status, err.response.data);
      return err.response;
   }
}