import React, { useContext, useEffect, useRef, useState } from 'react';
import "./EditPopUp.css";
import Button from './Button';
import OvalButton from './OvalButton';
import { useLocation } from 'react-router-dom';
import { uploadVideo } from '../../InductionLearningServices/Trainings';
import { useLoadingContext } from '../../context/LoadingContext';
import { RefreshScreenContext } from '../../context/RefreshScreen';

const EditVideoPopUp = (props) => {

   const location = useLocation();
   const trainingId = location.state.onTap.id;
   const {setIsLoading} = useLoadingContext();
   const {setRefresh} = useContext(RefreshScreenContext);

   const videoInputRef = useRef(null);

   const [videoFile, setVideoFile] = useState(null);
   const [videoFileName, setVideoFileName] = useState("MP4 format, within the file size limit 100MB");
   const [videoFileNameError, setVideoFileNameError] = useState("");

   const handleButtonClickVideo = () => {
      videoInputRef.current.click();
   };

   // Video choosing and uploading
   const handleVideo = (e) => {
      setVideoFileNameError((prev) => "");
      const selectedFile = e.target.files[0];
      const fileSizeInMB = selectedFile.size/(1024*1024);
      if(fileSizeInMB > 100) {
         setVideoFileNameError((prev) => "Video can't be greater than 100MB.");
         return;
      } else if(selectedFile.name.toLowerCase().includes(".mp4") == false) {
         setVideoFileNameError((prev) => "Only .mp4 format is allowed for Video.");
         return;
      }
      setVideoFile(selectedFile);
      setVideoFileName("Selected File: " + selectedFile.name);
   };

   const uploadVideoOnBtnClick = async () => {
      if (videoFile) {
         const formData = new FormData();
         formData.append("file", videoFile);
         setIsLoading(true);
         const response = await uploadVideo(formData, trainingId);
         setIsLoading(false);
         if(response.status == 200) {
            setRefresh((prev) => true);
            props.closePopUpEdit();
         }
      } else {
         setVideoFileNameError((prev) => "Please select a Video File.");
      }
   };

   useEffect(() => {
      if (videoFile) {
         uploadVideoOnBtnClick();
      }
   }, [videoFile]);

   return (
      <div className="edit-popup">
         <div className="edit-popup-btn-grp">
            <Button
               name="Video"
            />
            <form>
               <div onClick={handleButtonClickVideo}>
                  <OvalButton
                     name="Upload"
                     color="transparent"
                  />
               </div>
               <input
                  type="file"
                  className="form-control"
                  accept="video/*"
                  ref={videoInputRef}
                  onChange={handleVideo}
                  style={{ display: 'none' }}
               >
               </input>

               {/* we will display error message in case user select some file
                other than pdf */}
               {props.videoError && <span className="text-danger">{props.videoError}</span>}
            </form>

         </div>
         <p className="edit-popup-subtext">
            {videoFileName}
         </p>
         <p className="text-danger">
            {videoFileNameError}
         </p>

      </div>
   );
}

export default EditVideoPopUp;