import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { FaUpload } from "react-icons/fa";

import { dataURLtoFile } from "../../../utils";
import { getData, postFile } from "../../../utils/api";
import { errorToast, infoToast, successToast } from "../../../utils/toastFunc";

import "../UserManagement.scss";

const ProfilePicture = ({
  submitPictureUrl,
  getPictureUrl,
  viewEditMode,
  setProfileImg,
  id,
}) => {
  const [viewMode, setViewMode] = useState(viewEditMode ?? "view");
  const [uploadedImg, setUploadedImg] = useState(null); // file type
  const [imageUrl, setImageUrl] = useState(null); // object type for img to read and display
  const [loading, setLoading] = useState(false)
  /**
   * It fetches the profile picture from the backend and sets it to the state
   */
  useEffect(() => {
    const fetchAndSetProfilePicture = async () => {
      setLoading(true)
      const url = getPictureUrl + "/" + id;
      const response = await getData(url);
      if (response?.data) {
        let base64data = response.data;
        if (base64data.slice(0,20) === "dataimage/jpegbase64") base64data = base64data.slice(20);
        let file = dataURLtoFile(
          `data:text/plain;base64,${base64data}`,
          "profilepic.jpeg"
        );
        setUploadedImg(file);
      }
    setLoading(false)
    };
    id && fetchAndSetProfilePicture();
  }, [id]);

  useEffect(() => {
    if (uploadedImg) {
      setImageUrl(URL.createObjectURL(uploadedImg));
      setProfileImg && setProfileImg(URL.createObjectURL(uploadedImg))
    }
  }, [uploadedImg]);

  const handleFileEvent = async (e) => {
    const chosenFile = e.target.files[0];
    if (chosenFile) {
      const url = submitPictureUrl + "/" + id;
      const formData = new FormData();
      formData.append("profilepic", chosenFile);
      const response = await postFile(url, formData);

      if (response.data === "profile pic uploaded successfully") {
        successToast({ mes: "Profile Image Changed successfully" });
      } else {
        errorToast({ mes: "Error updating profile picture" });
      }
      setUploadedImg(chosenFile);
    }
  };
  /**
   * If the user is not in View || Edit mode and the user has not submitted their details, then display a toast
   * message asking to submit and create User first.
   */
  const displayMessage = () => {
    if (!id && !viewEditMode) {
      infoToast({ mes: "Please Submit User Details" });
    }
  };

  return (
    <div className="profilePicture--container">
      
      {loading ? <Spinner className="d-flex flex-column bg-blur" animation="border" variant="info" />: <img src={imageUrl} className="image" onClick={displayMessage} />}
      {viewMode !== "view" && id && (
        <>
          <input
            id="fileUpload"
            className="d-none"
            type="file"
            disabled={loading}
            // accept="image/png, imgage/jpg, image/jpeg"
            accept="image/*"
            onChange={handleFileEvent}
          />
          <label  htmlFor="fileUpload" className="btn buttons upload-btn">
            <FaUpload className="" color="white" size={20} />
          </label>
        </>
      )}
    </div>
  );
};

export default ProfilePicture;
