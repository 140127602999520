import React from "react";
function AvatarComp({ profileImg, name, role }) {
  return (
    <div className="avatarComp">
      <img
        className="avatar-img"
        width="50"
        height="50"
        src={profileImg}
      />
      <div className="text-container">
        {name && (
          <h4 className="name">{name}</h4>
        )}
        {role && (
          <div className="role">{role}</div>
        )}
      </div>
    </div>
  );
}
export default AvatarComp;
