import "../components/InductionLearning/InductionLearning.css";
import MainScreen from '../components/InductionLearning/MainScreen';
import SearchScreenMainContent from '../components/InductionLearning/SearchScreenMainContent';
import Sidebar from '../components/InductionLearning/Sidebar';

function SearchScreen() {
  return (
    <div className="induction-learning-body">
      <div className='main-content'>
        <Sidebar
          isSearch={true}
        />
        <MainScreen
          childComponent={<SearchScreenMainContent />}
        />
      </div>
    </div>

  );
}

export default SearchScreen;
