import React from "react";
import styles from "./PPERequirements.module.scss";
import image from "../../../assets/ppeRequirements/requirements.png";

const PPERequirementsCode = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Swift Transport Pty Ltd</h2>
      <p className={styles.attention}>
        <strong>PPE REQUIREMENTS</strong>
      </p>
      <div className={styles.attentionSection}>
        <p className={styles.subHeader}>
          <strong>ATTENTION ALL SWIFT DRIVERS:</strong>
        </p>
        <p className={styles.paragraph}>
          We have recently received many complaints from clients that Swift
          drivers are entering client sites without proper Personal Protective
          Equipment (PPE) that are specific to each site. The minimum
          requirement for PPE on Swift yard and on client site is Long Sleeve
          Shirt, Long Pants, Hi-Vis’s vest or clothing, Steel Cap Boots, and
          Hard hats.
        </p>

        {/* Image Section */}
        <div className={styles.imageSection}>
          <img src={image} alt="PPE Requirements" />
        </div>

        <p className={styles.note}>
          <strong>NOTE:</strong> Any drivers found not following these PPE
          requirements and not following client site rules will be disciplined
          and/or will be docked their pay for the delivery.
        </p>
        <p className={styles.disclaimer}>
          Driver signing this document acknowledges that they have read and been
          instructed and participated in the associated tasks and will
          demonstrate their ability to complete and adhere to the tasks safely.
        </p>
        <div className={styles.acknowledgement}>
          <p>
            I acknowledge that I have read and understand the Swift PPE
            Requirement:
          </p>
        </div>
      </div>
    </div>
  );
};

export default PPERequirementsCode;
