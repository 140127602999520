import React from 'react';
import ContextProvider from './ContextProvider';
import OtherScreens from './OtherScreens';

const OtherScreenMain = () => {
   return (
      <ContextProvider>
         <OtherScreens />
      </ContextProvider>
   );
};

export default OtherScreenMain;