import { store } from "../../rtk-slice/store";
import {
  activateUserById,
  deleteUserById,
} from "../../rtk-slice/userManagement";

export const driversTableClickEvents = (row) => {
  const { original: data } = row;
  const action = data.active ? "deactivate" : "activate";
  const confirmMsg =
    action === "activate"
      ? "Are you sure you want to Activate this user?"
      : "Are you sure you want to Deactivate this user?";
  switch (action) {
    case "deactivate": {
      const confirmDeactivate = window.confirm(confirmMsg);
      if (confirmDeactivate) {
        store.dispatch(deleteUserById(data.userId));
      }
      break;
    }
    case "activate": {
      const confirmActivate = window.confirm(confirmMsg);
      if (confirmActivate) {
        store.dispatch(activateUserById(data.userId));
      }
      break;
    }
    default:
      break;
  }
};
