import React from 'react';
import './McqPopUp.css';

const TextPopUp = (props) => {
   return (
      <div>
         <div
            style={{
               color: "white",
               fontWeight: "700",
               fontSize: "28px",
               margin: "15px",
               display: "flex",
               justifyContent: "center",
               alignContent: "center"
            }}
         >
            Text
         </div>
         <div className='scrollable-container'>
            <div className='scrollable-container-child'>
               {props.text}
            </div>
         </div>

      </div>
   );
}

export default TextPopUp;