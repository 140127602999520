import * as yup from 'yup'

export function createYupSchema(schema, config) {
  const { id, name, validationType, validations = [] } = config
  if (!yup[validationType]) {
    return schema
  }
  let validator = yup[validationType]()
  validations.forEach((validation) => {
    const { params, type, nullable } = validation
    if (!validator[type]) {
      return
    }
    validator = validator[type](
      ...params.map((p) =>
        typeof p === 'string' ? p.replace('##FIELD_NAME##', name) : p
      )
    )

    if (nullable) {
      validator = validator
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
    }
  })

  schema[id] = validator
  return schema
}
