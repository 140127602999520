import React, { useContext } from 'react';
import "./DeletePopUp.css";
import Exclamation from "../../assets/InductionLearningAssets/red-exclamation.png";
import OvalButton from './OvalButton';
import { RefreshScreenContext } from '../../context/RefreshScreen';
import { deleteModule } from '../../InductionLearningServices/Modules';

const DeletePopUp = (props) => {

    const { setRefresh } = useContext(RefreshScreenContext);

    const deleteItem = async () => {
        const response = await deleteModule(props.moduleId);
        setRefresh((prev) => true);
        if (response.status == 200) {
            props.closePopupDelete(); 
        }
    }

    const deleteItemOnTap = () => {
        deleteItem();
    }

    return (
        <div className="delete-popup">
            <img src={Exclamation} alt='red-exclamation' />
            <p className="delete-popup-heading">
                Are you sure you want to delete these items?
            </p>
            <p className="delete-popup-text">
                Deleting items from this cannot be undone.
            </p>

            <div className="delete-popup-button-group">
                <div onClick={deleteItemOnTap}>
                    <OvalButton
                        name="Yes, Continue"
                        color="red"
                    />
                </div>

                <div onClick={props.closePopupDelete}>
                    <OvalButton
                        name="No, Go Back"
                        color="transparent"
                    />
                </div>
            </div>
        </div>
    );
}

export default DeletePopUp;