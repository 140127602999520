import { createAsyncThunk, createSlice ,current} from "@reduxjs/toolkit";
import { postData, getData } from "../utils/api";
import { errorToast, infoToast, successToast } from "../utils/toastFunc";

import config from "../constants/config";


const initialState = {
  loading: false,
  activeUsers: [],
  userConversations: [],
  selectedConversation: null,
  selectedChat: null,
};
export const fetchActiveChatUsers = createAsyncThunk(
  "enigmaChatReducer/fetchActiveChatUsers",
  async () => {
    const url = config.baseUrl + config.enigmaChat.getAllUsers;
    const response = await getData(url);
    return response;
  }
);
export const postCreateConversation = createAsyncThunk(
  "enigmaChatReducer/postCreateConversation",
  async (body) => {
    const url = config.baseUrl + config.enigmaChat.createConversation;
    const response = await postData(url, body);
    return response;
  }
);
export const fetchUserConversations = createAsyncThunk(
  "enigmaChatReducer/fetchUserConversations",
  async () => {
    const url = config.baseUrl + config.enigmaChat.getUserConversations;
    const response = await getData(url);
    return response;
  }
);
export const fetchChatConversations = createAsyncThunk(
  "enigmaChatReducer/fetchChatConversations",
  async (id) => {
    const url = config.baseUrl + config.enigmaChat.getChatConversation + "?conversationSid=" + id;
    const response = await getData(url);
    return response;
  }
);
export const postMessage = createAsyncThunk(
  "enigmaChatReducer/postMessage",
  async (body) => {
    const url = config.baseUrl + config.enigmaChat.sendMessage;
    const response = await postData(url, body);
    return response;
  }
);
export const removeParticipant = createAsyncThunk(
  "enigmaChatReducer/removeParticipant",
  async ({conversationSid, participantSid}) => {
    const url = `${config.baseUrl}${config.enigmaChat.removeParticipant}?conversationSid=${conversationSid}&participantSid=${participantSid}`;
    const response = await postData(url);
    return response;
  }
);



export const enigmaChatSlice = createSlice({
  name: "enigmaChatReducer",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLoading: (state,action) => {
      state.loading = action.payload;
    },
    setSelectedConversation: (state, action) => {
      console.log("action", action.payload)
      localStorage.setItem("selectedConversation",JSON.stringify(action.payload))
      state.selectedConversation = action.payload;
    },
    setSelectedConversationBySid: (state,action)=> {
      const existingConv = state.userConversations
      const currentExisting = current(state).userConversations
      console.log({existingConv,currentExisting})
      const found = current(state).userConversations.find(conv => conv.conversationSid === action.payload)
      console.log("found",found)
      if (found) state.selectedConversation = found;
      else {
        console.warn("No Conversation found with sid: "+action.payload)
      }
    },
    pushMessage: (state, action) => {
      state.selectedChat?.chatMessageResponseDTOS.push(action.payload)
      // console.log("action", action.payload)
      // state.selectedConversation = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiveChatUsers.fulfilled, (state, action) => {
        state.activeUsers = action.payload.data;
      })
      .addCase(fetchUserConversations.fulfilled, (state, action) => {
        if (action.payload.data) state.userConversations = action.payload.data;
      })
      .addCase(fetchChatConversations.fulfilled, (state, action) => {
        state.selectedChat = action.payload.data;
      })
      .addCase(removeParticipant.fulfilled, (state, action) => {
        if (action.payload.status === 200 || action.payload.status === "200" ) {
          infoToast({mes: "Success"})
          state.selectedChat = null;
          state.selectedConversation = null;
        }else{
          errorToast({mes: "Something went Wrong"})
        }
      })
      .addCase(removeParticipant.rejected, (state, action) => {
          errorToast({mes: "Something went Wrong"})
      })
      .addCase(postMessage.fulfilled, (state, action) => {
        if (action.payload.status !== 200) {
          errorToast({ mes: "Message not sent" })
        }
      })
      .addCase(postCreateConversation.fulfilled, (state, action) => {
        if (action.payload?.data) {
          successToast({ mes: "Created conversation Successfully" })
          const {chatParticipantResponseDTOS,conversationSid ,identityName} = action.payload?.data
          state.userConversations.push({ conversationSid, identityName , chatParticipantResponseDTOS});
        } else {
          errorToast({ mes: "something went wrong" })
        }

      })

  },
});

export const { setSelectedConversation, pushMessage,setSelectedConversationBySid } = enigmaChatSlice.actions;

export const getAllChatUsers = (state) => state?.enigmaChat.activeUsers;
export const getSelectedConversation = (state) => state?.enigmaChat.selectedConversation;
export const getUserConversations = (state) => state?.enigmaChat.userConversations;
export const getSelectedChat = (state) => state?.enigmaChat.selectedChat;



export default enigmaChatSlice.reducer;
