import { errorToast, successToast } from "../utils/toastFunc";

export const uploadSuccessOrFailure = (statusCode, data) => {
   if (statusCode == 200) {
      successToast({ mes: "Upload Successful!" });
      return;
   }
   errorToast({ mes: data.detail });
}

export const deleteSuccessOrFailure = (status, data) => {
   if (status == 200) {
      successToast({ mes: "Delete Successful!" });
      return;
   }
   errorToast({ mes: data.detail });
}

export const showOnlyOnError = (status, data) => {
   if (status == 500) {
      errorToast({ mes: "Something went wrong." });
   } else if (status == 401) {
      errorToast({ mes: "Please Login again." });
   } else if (status != 200) {
      errorToast({ mes: data.detail });
   }
}