import React from "react";
import { useFormContext } from "react-hook-form";
import { FormField, TextBox } from "../../Form";

const fieldName = "tradeDetails";

function TradeDetails({ prev, onNext }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  const fields = [
    {
      id: 1,
      referenceName: "",
      contactNumber: "",
      additionalContactNumber: "",
    },
    {
      id: 2,
      referenceName: "",
      contactNumber: "",
      additionalContactNumber: "",
    },
    {
      id: 3,
      referenceName: "",
      contactNumber: "",
      additionalContactNumber: "",
    },
  ];

  const submit = () => {
    onNext();
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <h3 className="stepper_Header">Trade Details</h3>
      <div className="my-3">
        {fields.map((field, index) => (
          <div key={field.id} className="trade-detail">
            <div className="d-flex">
              <FormField
                className="Field_label"
                title={`Reference Name ${index + 1}`}
                id={`${fieldName}.${index}.referenceName`}
                ratio={[12, 11]}
              >
                <TextBox
                  type="text"
                  id={`${fieldName}.${index}.referenceName`}
                  register={register}
                  errors={errors}
                  placeholder="e.g., Company 1, Company 2, Company 3"
                  className="py-2"
                  getError={(error) =>
                    error?.[fieldName]?.[index]?.["referenceName"]
                  }
                />
              </FormField>
              <FormField
                className="Field_label"
                title="Contact Number"
                id="contactNo"
                ratio={[12, 11]}
              >
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id={`${fieldName}.${index}.contactNumber`}
                  >
                    +61
                  </span>
                  <TextBox
                    type="text"
                    id={`${fieldName}.${index}.contactNumber`}
                    register={register}
                    errors={errors}
                    placeholder="e.g., 123456789"
                    className="py-2"
                    getError={(error) =>
                      error?.[fieldName]?.[index]?.["contactNumber"]
                    }
                  />
                </div>
              </FormField>
              <FormField
                className="Field_label"
                title="Additional Contact Number"
                id={`${fieldName}.${index}.additionalContactNumber`}
                ratio={[12, 11]}
              >
                <TextBox
                  type="text"
                  id={`${fieldName}.${index}.additionalContactNumber`}
                  register={register}
                  errors={errors}
                  placeholder="e.g., 123456789"
                  className="py-2"
                  getError={(error) =>
                    error?.[fieldName]?.[index]?.["additionalContactNumber"]
                  }
                />
              </FormField>
            </div>
          </div>
        ))}
      </div>
      <div className="form-btn">
        <button type="button" className="credit-form-btn" onClick={prev}>
          Back
        </button>
        <button type="submit" className="credit-form-btn">
          Next
        </button>
      </div>
    </form>
  );
}

export default TradeDetails;
