import React from "react";
import { FormField, TextBox } from "../../Form";
import { useFormContext } from "react-hook-form";

function BankDetails({ prev, onNext }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const submit = () => {
    onNext();
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      <h3 className="stepper_Header">Bank Details</h3>
      <div className="my-3">
        <FormField
          className="Field_label"
          title="Bank Institution (name of bank)"
          required
          id="bankDetails.bank"
          ratio={[12, 12]}
        >
          <TextBox
            type="text"
            id="bankDetails.bank"
            register={register}
            errors={errors}
            placeholder="eg. Name of Bank"
            className="py-2"
            getError={(error) => error?.bankDetails?.bank}
          />
        </FormField>
        <FormField
          className="Field_label"
          title="BSB"
          required
          id="bankDetails.bsb"
          ratio={[12, 12]}
        >
          <TextBox
            type="text"
            id="bankDetails.bsb"
            register={register}
            errors={errors}
            placeholder="eg. 123456"
            className="py-2"
            getError={(error) => error?.bankDetails?.bsb}
          />
        </FormField>
        <FormField
          className="Field_label"
          title="Name of Account Holder"
          required
          id="bankDetails.accountHolderName"
          ratio={[12, 12]}
        >
          <TextBox
            type="text"
            id="bankDetails.accountHolderName"
            register={register}
            errors={errors}
            placeholder="eg. John Doe"
            className="py-2"
            getError={(error) => error?.bankDetails?.accountHolderName}
          />
        </FormField>
        <FormField
          className="Field_label"
          title="Account Number"
          required
          id="bankDetails.accountNumber"
          ratio={[12, 12]}
        >
          <TextBox
            type="text"
            id="bankDetails.accountNumber"
            register={register}
            errors={errors}
            placeholder="eg. 12345678"
            className="py-2"
            getError={(error) => error?.bankDetails?.accountNumber}
          />
        </FormField>
      </div>
      <div className="form-btn">
        <button type="button" className="credit-form-btn" onClick={prev}>
          Back
        </button>
        <button type="submit" className="credit-form-btn">
          Next
        </button>
      </div>
    </form>
  );
}

export default BankDetails;
