import { yupResolver } from "@hookform/resolvers/yup";
import { generate } from "@pdfme/generator";
import { image, text } from "@pdfme/schemas";
import moment from "moment";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { FormField } from "../../../components/Form";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import SignatureCanvasField from "../../../components/SignatureCanvas/SignatureCanvasField";
import config from "../../../constants/config";
import { doLogout, getUserData } from "../../../rtk-slice/globalSlice";
import { postFile } from "../../../utils/api";
import { errorToast, successToast } from "../../../utils/toastFunc";
import "../../SwiftTrafficSignup/swiftTrafficSignup.scss";
import template from "../pdf/template.json";
import "./gdpTraining.scss";

const KuehneNagelTextHeader = ({ header }) => {
  return (
    <ul className="kc-d KuehneNagel-textHeader">
      <li>{header}</li>
    </ul>
  );
};

const validationSchema = Yup.object().shape({
  document: Yup.mixed().required("Signature is required"),
});

function GdpTrainingPdf() {
  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const driverId = userData.userContext.userId;
  const { firstName, lastName } = userData.userDetails;
  const [isLoading, setIsLoading] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
  });

  const KuehneNagelPage = ({ page, children }) => {
    return (
      <div className="KuehneNagel-page">
        <div className="KuehneNagel-header">
          <img
            src="/KuehneNagel/logo1.png"
            width="200px"
            height="30px"
            alt="logo-1"
            className="logo-1"
          />
          <img
            src="/KuehneNagel/logo2.png"
            width="40px"
            height="40px"
            alt="logo-2"
            className="logo-2"
          />
        </div>
        <div className="KuehneNagel-body">{children}</div>
        <div className="KuehneNagel-footer">
          <div>
            <span>Version: 03/ Status per: 30.07.2013</span>
            <span>Editor: Zrh QM-B/ E. Franke</span>
            <span>Owner: Kuehne + Nagel Switzerland</span>
          </div>
          <div>
            <p style={{ textAlign: "right" }}>{page}</p>
          </div>
        </div>
      </div>
    );
  };

  const handleConfirm = () => {
    if (confirmAction) {
      confirmAction();
    }
    setShowConfirmModal(false);
  };

  const handleConfirmButtonClick = (action) => {
    setConfirmAction(() => action);
    setShowConfirmModal(true);
  };

  const onSubmit = async (data) => {
    handleConfirmButtonClick(async () => {
      setIsLoading(true);
      const url =
        config.baseUrl + config.userDocuments.uploadFile + "/" + driverId;
      const formData = new FormData();
      const plugins = { text, image };
      const inputs = [
        {
          date: moment().format("DD-MM-YYYY"),
          givenName: firstName,
          familyName: lastName ? lastName : "",
          signature: data.document,
        },
      ];

      const pdf = await generate({ template, inputs, plugins });
      const blob = new Blob([pdf.buffer], { type: "application/pdf" });
      const file = new File([blob], "gdp-training-sign-off.pdf", {
        type: "application/pdf",
      });
      formData.append("document", file);

      const response = await postFile(url, formData);
      window.open(URL.createObjectURL(blob));

      if (response.data === "document uploaded successfully") {
        successToast({ mes: "Document Submitted" });
        dispatch(doLogout());
      } else {
        setIsLoading(false);
        errorToast({ mes: "Error Upload" });
      }
    });
  };

  return (
    <div>
      <Container>
        <div className="KuehneNagel-container">
          <KuehneNagelPage page="1">
            <img width="100%" src="/KuehneNagel/chain.png" alt="chains" />
            <div className="p-2">
              <h4 className="kc-d KuehneNagel-textHeader">
                Basic GDP Training: Road Transport of Pharmaceutical and Medical
                Products
              </h4>
              <h6 className="kc-l KuehneNagel-subheader">
                Subcontractor Training from Kuehne + Nagel
              </h6>
            </div>
          </KuehneNagelPage>
          <KuehneNagelPage page="2">
            <KuehneNagelTextHeader header="What to I have to do? " />
            <h4 className="kc-l KuehneNagel-textHeader">
              Mode of cooling unit
            </h4>
            <ul className="k-list">
              <li>
                Many examples in the past clearly indicate that using the right
                mode for the cooling unit during the transport – independent
                from the temperature range- is an important success factor.
                <div className="w-100 g-3 my-2">
                  <div>
                    <p>Continuous Mode:</p>
                    <img
                      width="100%"
                      src="/KuehneNagel/continuous.png"
                      alt="continuous"
                    />
                  </div>
                  <div>
                    <p>Start- Stop/ Cycle Sentry:</p>
                    <img
                      width="100%"
                      src="/KuehneNagel/cycleSentry.png"
                      alt="cycleSentry"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </KuehneNagelPage>
          <KuehneNagelPage page="3">
            <KuehneNagelTextHeader header="What to I have to do? " />
            <h4 className="kc-l KuehneNagel-textHeader">Transport</h4>
            <ul className="k-list">
              <li>The mode of the cooling unit must be set to continuous.</li>
              <li>
                In case of problems with the temperature or other deviations
                during the transport immediately contact your office and/ or
                your contact at Kuehne + Nagel. This way the customer/ consignee
                can already be pre- advised.
              </li>
              <li>
                No forbidden cargo (e.g. food, chemicals, smelly cargo, plants,
                etc.) can be co- loaded during the transport.
              </li>
              <li>
                Only rest at public and secured parking areas and keep near your
                vehicle at all times.
              </li>
              <li>
                In case the air calculation (space to the ceiling, sidewalls,
                floor, etc.) is not sufficient, indicate this on- site. Should
                that not be enough to solve the situation immediately contact
                your office and/ or your contact at Kuehne + Nagel.
              </li>
              <li>
                On ferries the cooling unit must be connected to external power
                supply nevertheless correct functioning should be checked
                regularly (a upper- deck parking position which enables easy
                access should be preferred).
              </li>
            </ul>
          </KuehneNagelPage>
          <KuehneNagelPage page="4">
            <KuehneNagelTextHeader header="What to I have to do?" />
            <h4 className="kc-l KuehneNagel-textHeader">Transport</h4>
            <ul className="k-list">
              <li>
                Adhere to the instructions/ directions on the consignees’
                premises.
              </li>
              <li>
                Provide the consignee with a temperature readout or inform your
                office so that your office can provide the consignee with the
                temperature readout.
              </li>
              <li>
                The consignee must sign the CMR/ transport document with
                signature, date and time.
              </li>
              <li>
                Immediately contact your office and/ or your contact at Kuehne +
                Nagel in case the consignee notes deviations on the CMR/
                transport document and/or discovers temperature deviations.
              </li>
              <li>
                Do not argue with the consignee. In case of problems/
                uncertainties/ conflicts refer to your office and/ or your
                contact at Kuehne + Nagel.
              </li>
            </ul>
          </KuehneNagelPage>
          <KuehneNagelPage page="5">
            <KuehneNagelTextHeader header="What to I have to do?" />
            <h4 className="kc-l KuehneNagel-textHeader">
              Good documentation practices
            </h4>
            <ul className="k-list">
              <li>Write as clear and readable as possible on all documents.</li>
              <li>
                Entries shall never (partly) cover information or make it
                unreadable.
              </li>
              <li>Every signature must be combined with date and time.</li>
              <li>
                In case of corrections write the new entry right next to the old
                entry including signature and date. The old entry may only
                crossed out with a single line so that the old information can
                still be read.
                <img
                  className="my-3"
                  width="100%"
                  src="/KuehneNagel/documentationPractices.png"
                  alt="goodDoc"
                />
              </li>
              <li>Whenever possible only use blue or black ink pens.</li>
            </ul>
          </KuehneNagelPage>
          <KuehneNagelPage page="6">
            <KuehneNagelTextHeader header="How do I handle deviations?" />
            <h4 className="kc-l KuehneNagel-textHeader">
              What is a deviation?
            </h4>
            <ul className="k-list">
              <li>
                A deviation is every incident with a possible negative impact on
                the efficiency of the product.
              </li>
              <li>
                Find below some examples that ultimately need to be reported as
                quick as possible:
              </li>
              <ul>
                <li>Temperature deviation(s)</li>
                <li>Break down of the cooling unit</li>
                <li>
                  Air circulation in the loading unit is not sufficient after
                  loading
                </li>
                <li>Printed temperature readout is incomplete/ incorrect</li>
                <li>Contamination</li>
                <li>Damages</li>
                <li>Refusal of acceptance</li>
                <li>Delays</li>
                <li>Quantity issues</li>
                <li>Any remarks on the transport documents</li>
                <li>Security/ customs controls</li>
                <li>(Attempted) Theft</li>
                <li>(Suspicion of) Fake products</li>
                <li>etc.</li>
              </ul>
            </ul>
          </KuehneNagelPage>
          <KuehneNagelPage page="7">
            <KuehneNagelTextHeader header="How do I handle deviations?" />
            <h4 className="kc-l KuehneNagel-textHeader">Generic procedure</h4>
            <ul className="k-list">
              <li>Do not assume the products can not be salvaged anymore</li>
              <li>
                Immediately inform your office and/ or contact from Kuehne +
                Nagel
              </li>
              <li>Collect all relevant information</li>
              <li>
                Whenever possible, take pictures or ask someone else to take
                pictures for you.
              </li>
              <li>
                Closely align all following steps/ actions with Kuehne + Nagel.
              </li>
            </ul>
          </KuehneNagelPage>
        </div>
        <div className="swift-traffic">
          <div className="container">
            <div className="eachPage">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row className="align-items-center mt-2">
                  <Col xs={12} sm={6}>
                    <FormField
                      className="Field_label"
                      title="First Name"
                      id="firstName"
                      ratio={[3, 9]}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={firstName}
                        disabled
                      />
                    </FormField>
                  </Col>
                  <Col xs={12} sm={6}>
                    <FormField
                      className="Field_label"
                      title="Last Name"
                      id="lastName"
                      ratio={[3, 9]}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={lastName}
                        disabled
                      />
                    </FormField>
                  </Col>
                </Row>
                <SignatureCanvasField
                  label="Digital Signature"
                  signatureError={errors?.document?.message}
                  onSignatureChange={(value) => setValue("document", value)}
                />
                <div className="d-flex justify-content-center mt-5">
                  <button
                    type="submit"
                    className="credit-form-btn py-2"
                    disabled={isLoading}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ConfirmationModal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
          onConfirm={handleConfirm}
          message="Are you sure you want to submit this file?"
        />
      </Container>
    </div>
  );
}

export default GdpTrainingPdf;
