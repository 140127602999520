import React, { useContext, useEffect, useRef, useState } from 'react';
import "./EditPopUp.css";
import Button from './Button';
import OvalButton from './OvalButton';
import { useLocation } from 'react-router-dom';
import { uploadPDF } from '../../InductionLearningServices/Trainings';
import { useLoadingContext } from '../../context/LoadingContext';
import { RefreshScreenContext } from '../../context/RefreshScreen';

const EditPdfPopUp = (props) => {

   const location = useLocation();
   const trainingId = location.state.onTap.id;
   const {setIsLoading} = useLoadingContext();
   const {setRefresh} = useContext(RefreshScreenContext);

   const fileInputRef = useRef(null);

   const [pdfFile, setPdfFile] = useState(null);
   const [pdfFileName, setPdfFileName] = useState("PDF format, within the file size limit 20MB");
   const [pdfFileNameError, setPdfFileNameError] = useState("");

   const handleButtonClick = () => {
      fileInputRef.current.click();
   };

   // PDF choosing and uploading
   const handlePDF = (e) => {
      setPdfFileNameError((prev) => "");
      const selectedFile = e.target.files[0];
      const fileSizeInMB = selectedFile.size/(1024*1024);
      if(fileSizeInMB > 20) {
         setPdfFileNameError((prev) => "PDF can't be greater than 20MB.");
         return;
      } else if(selectedFile.name.toLowerCase().includes(".pdf") == false) {
         setPdfFileNameError((prev) => "Only .pdf format is allowed for PDF.");
         return;
      }
      setPdfFile(selectedFile);
      setPdfFileName("Selected File: " + selectedFile.name);
   };

   const uploadPDFOnBtnClick = async () => {
      if (pdfFile) {
         const formData = new FormData();
         formData.append("file", pdfFile);
         setIsLoading(true);
         const response = await uploadPDF(formData, trainingId);
         setIsLoading(false);
         if(response.status == 200) {
            setRefresh((prev) => true);
            props.closePopUpEdit();
         }
      } else {
         setPdfFileNameError((prev) => "Please select a PDF File.");
      }
   };

   useEffect(() => {
      if (pdfFile) {
         uploadPDFOnBtnClick();
      }
   }, [pdfFile]);

   return (
      <div className="edit-popup">
         <div className="edit-popup-btn-grp">
            <Button
               name="Document"
            />
            {/* Upload PDF */}
            <form>

               <div onClick={handleButtonClick}>
                  <OvalButton
                     name="Upload"
                     color="transparent"
                  />
               </div>

               <input
                  type="file"
                  className="form-control"
                  accept=".pdf"
                  onChange={handlePDF}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
               >
               </input>

               {/* we will display error message in case user select some file
                  other than pdf */}
               {props.pdfError && <span className="text-danger">{props.pdfError}</span>}
            </form>

         </div>
         <p className="edit-popup-subtext">
            {pdfFileName}
         </p>
         <p className="text-danger">
            {pdfFileNameError}
         </p>

      </div>
   );
}

export default EditPdfPopUp;