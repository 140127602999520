import { useContext, useEffect, useState } from "react";
import "./EditPopUp.css";
import "./AddMcqPopUp.css";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { uploadMCQ } from "../../InductionLearningServices/Trainings";
import { useLoadingContext } from "../../context/LoadingContext";
import { RefreshScreenContext } from "../../context/RefreshScreen";
import { getMcqModule } from "../../InductionLearningServices/Modules";

const AddMcqPopUp = (props) => {
   const { setIsLoading } = useLoadingContext();
   const [formCount, setFormCount] = useState(1);
   const [mcqError, setMcqError] = useState("");
   const [mcqList, setMcqList] = useState([]);
   const location = useLocation();
   const trainingId = location.state.onTap.id;
   const { setRefresh } = useContext(RefreshScreenContext);
   const emptyMcq = {
      "question": "",
      "option1": "",
      "option2": "",
      "option3": "",
      "option4": "",
      "correctAnswer": "",
   };

   const getMcq = async (moduleId) => {
      setIsLoading((prev) => true);
      let response = await getMcqModule(moduleId);
      setMcqList((prev) => [...response.data.data.mcqs]);
      setIsLoading((prev) => false);
   }

   useEffect(() => {
      if (props.moduleId != "") {
         getMcq(props.moduleId);
      } else if (props.moduleId == "") {
         setMcqList((prev) => [emptyMcq]);
      }
   }, []);

   const setError = (mcqData) => {
      if (checkIfMcqNotEmpty(mcqData.question, mcqData.option1, mcqData.option2, mcqData.option3, mcqData.option4, mcqData.correctAnswer)) {
         if (mcqData.correctAnswer.length > 1) {
            setMcqError((prev) => "Put correct answer as 1 for option1 and similarly for others.");
            return;
         }
         if (formCount < 100) {
            setFormCount(formCount + 1);
         } else {
            setMcqError((prev) => "You can't add more than 100 MCQ's");
         }
      } else {
         if (mcqData.correctAnswer.length > 1) {
            setMcqError((prev) => "Put correct answer as 1 for option1 and similarly for others.");
            return;
         }
         setMcqError((prev) => "Please add all fields before adding new MCQ.");
      }
   }

   const handleMCQForm = (e, i) => {
      const mcqData = setMCQData(e, i);

      if (checkIfMcqNotEmpty(mcqData.question, mcqData.option1, mcqData.option2, mcqData.option3, mcqData.option4, mcqData.correctAnswer)) {
         if (mcqData.correctAnswer.length > 1) {
            setMcqError((prev) => "Put correct answer as 1 for option1 and similarly for others.");
            return;
         }
      }
      setMcqList(
         (prevList) => {
            prevList[i] = mcqData;
            return prevList;
         }
      );
   };

   const checkIfMcqNotEmpty = (question, option1, option2, option3, option4, correctAnswer) => {
      if (question != "" && option1 != "" && option2 != "" && option3 != "" && option4 != "" && correctAnswer != "" && correctAnswer.length == 1) {
         return true;
      }
      return false;
   }

   const setMCQData = (e, i) => {
      setMcqError((prev) => "");
      e.preventDefault();
      const form = e.target.form;
      const question = form[`question${i}`].value;
      const option1 = form[`option1${i}`].value;
      const option2 = form[`option2${i}`].value;
      const option3 = form[`option3${i}`].value;
      const option4 = form[`option4${i}`].value;
      const correctAnswer = form[`option5${i}`].value;

      const mcqData = { question, option1, option2, option3, option4, correctAnswer };
      return mcqData;
   }

   const sendMcq = async (mcqList) => {
      setIsLoading((prev) => true);
      const response = await uploadMCQ(mcqList, trainingId);
      setIsLoading((prev) => false);
      if (response.status == 200) {
         setRefresh((prev) => true);
         setTimeout(() => {
            props.closeAddMcqPopUp();
         }, 500);
      }
   }

   const postMcqList = () => {
      const li = mcqList.length - 1;
      if (li < 0) {
         setMcqError((prev) => "Please add all fields before adding new MCQ.");
         return;
      }
      if (mcqList[li].correctAnswer.length > 1) {
         setMcqError((prev) => "Put correct answer as 1 for option1 and similarly for others.");
         return;
      }
      if (checkIfMcqNotEmpty(mcqList[li].question, mcqList[li].option1, mcqList[li].option2, mcqList[li].option3, mcqList[li].option4, mcqList[li].correctAnswer)) {
         sendMcq(mcqList);
      } else {
         setError(mcqList[li]);
      }
   }

   const handleAddInput = (e, i) => {
      const mcqData = setMCQData(e, i);
      setMcqList((prevList) => [...prevList, emptyMcq]);
   };

   const handleRemoveInput = (e, i) => {
      const mcqData = setMCQData(e, i);
      setMcqList((prevList) => {
         const newList = [...prevList.slice(0, i), ...prevList.slice(i + 1)];
         if (newList.length == 0) newList.push(emptyMcq);
         return newList;
      });
   };

   const renderForms = () => {
      const forms = [];
      for (let i = 0; i < mcqList.length; i++) {
         const key = mcqList[i].id;
         forms.push(
            <form className="form" key={key}>
               <div className="options">
                  <label htmlFor={`question${i}`}>Q. </label>
                  <input type="text" name={`question${i}`} defaultValue={mcqList[i].question} onChange={(e) => handleMCQForm(e, i)} />
               </div>

               <div className="options">
                  <label htmlFor={`option1${i}`}>01. </label>
                  <input type="text" name={`option1${i}`} defaultValue={mcqList[i].option1} onChange={(e) => handleMCQForm(e, i)} />
               </div>

               <div className="options">
                  <label htmlFor={`option2${i}`}>02. </label>
                  <input type="text" name={`option2${i}`} defaultValue={mcqList[i].option2} onChange={(e) => handleMCQForm(e, i)} />
               </div>

               <div className="options">
                  <label htmlFor={`option3${i}`}>03. </label>
                  <input type="text" name={`option3${i}`} defaultValue={mcqList[i].option3} onChange={(e) => handleMCQForm(e, i)} />
               </div>

               <div className="options">
                  <label htmlFor={`option4${i}`}>04. </label>
                  <input type="text" name={`option4${i}`} defaultValue={mcqList[i].option4} onChange={(e) => handleMCQForm(e, i)} />
               </div>

               <div className="options">
                  <label htmlFor={`option5${i}`}>Correct Answer: </label>
                  <input type="text" name={`option5${i}`} defaultValue={mcqList[i].correctAnswer} placeholder="ex. 1 or 2 (only enter the option number in digit)" onChange={(e) => handleMCQForm(e, i)} />
               </div>

               <button
                  style={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "end",
                     margin: "5px 0px 15px 0px",
                     cursor: "pointer",
                     gap: "10px",
                     border: "1px solid black",
                     padding: "0 10px",
                     borderRadius: "5px",
                     fontWeight: "500",
                     cursor: "pointer",
                     backgroundColor: "white",
                     color: "red",
                  }}
                  onClick={(e) => handleRemoveInput(e, i)}
               >
                  - Remove MCQ
               </button>

               {
                  i == mcqList.length - 1
                     ? <button
                        style={{
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "end",
                           margin: "5px 0px 15px 0px",
                           cursor: "pointer",
                           gap: "10px",
                           border: "1px solid black",
                           padding: "0 10px",
                           borderRadius: "5px",
                           fontWeight: "500",
                           cursor: "pointer",
                           backgroundColor: "white",
                        }}
                        onClick={(e) => handleAddInput(e, i)}
                     >
                        + Add MCQ
                     </button> : null
               }



               <br />
            </form>
         );
      }
      return forms;
   };

   return (
      <div className="edit-popup">
         <div className="headline-popup">
            <h2>MCQ</h2>
         </div>
         <span className="close-icon"><AiOutlineClose /></span>
         <div className="scrollable-container-parent-edit-mcq">
            <div className="scrollable-container-edit-mcq" style={{ border: "none" }}>
               {renderForms()}
            </div>
         </div>
         <div style={{ color: "red" }}>
            {mcqError}
         </div>
         <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'end', margin: '5px 0px 0px 0px', cursor: 'pointer', gap: '10px' }}>
            <button onClick={postMcqList} style={{ border: '1px solid white', padding: '0 10px', borderRadius: '5px', fontWeight: '500', cursor: 'pointer' }}>Post</button>
         </div>
      </div>
   );
};

export default AddMcqPopUp;