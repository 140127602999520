import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../constants/config";
import { fetchService } from "../utils/api";
import { errorToast, successToast } from "../utils/toastFunc";

export const useGetSfCustomerAdmin = () => {
  return useQuery({
    queryKey: ["AdminCustomer"],
    queryFn: async () =>
      await fetchService({
        url: config.AdminSfCustomer.Customer,
      }),
  });
};

export const useGetCustomerStatus = (id) => {
  return useQuery({
    queryKey: ["CustomerStatus", id],
    queryFn: async () => {
      const response = await fetchService({
        url: config.AdminSfCustomer.Customer + id,
      });
      return response;
    },
  });
};

export const useUpdateCustomerStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, status, data }) => {
      await fetchService({
        url: `${config.AdminSfCustomer.Customer}${id}/${status}`,
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    onError: (err) => {
      errorToast({ mes: err.message });
    },
    onSuccess: () => {
      successToast({ mes: "Status Updated" });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["AdminCustomer"],
      });
    },
  });
};
