import React from "react";
import Select from "react-select";
import { Controller, useFormContext } from "react-hook-form";

const MultiSelect = ({
  id,
  options,
  placeholder,
  errors,
  optionValueKey,
  optionDisplayKey,
}) => {
  const { control } = useFormContext();

  const formattedOptions = options.map((option) => {
    if (typeof option === "string" || option instanceof String) {
      return { value: option, label: option };
    }
    return { value: option[optionValueKey], label: option[optionDisplayKey] };
  });

  return (
    <>
      <div className={`form-control ${errors[id] ? "is-invalid" : ""}`}>
        <Controller
          name={id}
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <Select
              inputRef={ref}
              value={
                value
                  ? formattedOptions.filter((option) =>
                      value.includes(option.value)
                    )
                  : []
              }
              onChange={(selected) =>
                onChange(selected.map((option) => option.value))
              }
              options={formattedOptions}
              isMulti
              placeholder={placeholder}
              classNamePrefix="react-select"
            />
          )}
        />
      </div>
      {errors[id] && <p className="invalid-feedback">{errors[id].message}</p>}
    </>
  );
};

export default MultiSelect;
