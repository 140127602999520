import React from 'react';
import './McqPopUp.css';

const McqPopUp = (props) => {

   const optionStyle = {
      marginLeft: "20px"
   };

   return (
      <div>
         <div
            style={{
               color: "white",
               fontWeight: "700",
               fontSize: "28px",
               margin: "15px",
               display: "flex",
               justifyContent: "center",
               alignContent: "center"
            }}
         >
            MCQ
         </div>

         <div className='scrollable-container'>
            <div className='scrollable-container-child'>
               {
                  props.mcq.map((mcq) => <div key={mcq.id}>
                     <h5>
                        Q. {mcq.question}
                     </h5>
                     <p style={optionStyle}>1. {mcq.option1}</p>
                     <p style={optionStyle}>2. {mcq.option2}</p>
                     <p style={optionStyle}>3. {mcq.option3}</p>
                     <p style={optionStyle}>4. {mcq.option4}</p>
                     <br />
                  </div>)
               }
            </div>
         </div>

      </div>
   );
}

export default McqPopUp;