import React from "react";
import Header1 from "../../../images/SafetyAlert/Picture1.jpg";
import Header2 from "../../../images/SafetyAlert/safetyAlert.png";
import truckParking from "../../../images/SafetyAlert/Picture2.jpg";
import "./SafetyAlert.scss";

const SafetyAlert = () => {
  return (
    <div className="safety-alert-container">
      <div className="safety-alert-header">
        <img src={Header1} alt="header-1" />
        <img src={Header2} alt="header-2" />
      </div>

      <div className="safety-alert-border-box">
        <table>
          <tbody>
            <tr>
              <td className="safety-alert-row-1">Subject:</td>
              <td>Trucks Entering and Exiting Warehouses at Enfield ILC</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>Protecht ID Ref:</td>
              <td>1110767, 1110771, 1110773, 1110775, 1111422 (Hazard Obs)</td>
              <td>Date of Event: </td>
              <td>4 and 5 September 2024 </td>
            </tr>
          </tbody>
        </table>

        <div className="safety-alert-summary">
          <h5>Summary of what happened</h5>
          <p>
            Security officers have recently reported several instances of trucks
            reversing into and out of warehouse premises at the Enfield
            Intermodal Logistics Centre (ILC).
          </p>
          <img src={truckParking} alt="truckParking" />
          <p>
            When heavy vehicles make reverse movements on common roads it
            creates a safety hazard for other road users and pedestrians and
            disrupts access to other sites within the ILC precinct.
          </p>
          <p>
            All warehouses have been designed to allow heavy vehicles to enter
            and exit in a forward direction. Swept path diagrams showing entry
            and access paths for various classes of heavy vehicles are included
            in Operational Environmental Management Plans (OEMPs) for each
            warehouse.
          </p>
          <p>
            The <strong>site rules</strong> included within the NSW Ports
            Overarching Traffic Management Plan for Enfield ILC (the OTMP)
            clearly state that{" "}
            <strong>
              “All vehicles are to enter and exit each tenanted area and the ILC
              in a forward direction.”
            </strong>
          </p>
          <p>
            Warehouse tenants are responsible for ensuring that drivers are
            aware of, and comply with, the site rules as well as maintaining
            safe access paths to their sites.
          </p>
        </div>
        <div className="safety-alert-actions">
          <h5>Actions</h5>
          <ol>
            <li>
              <strong>Communicate with all HV drivers </strong>the requirement
              to enter and exit in a forward direction.
            </li>
            <li>
              Direct heavy vehicles to{" "}
              <strong>use appropriate loading docks </strong>according to their
              size and the swept path analyses provided.
            </li>
            <li>
              Keep driveways, forecourt areas, and loading docks{" "}
              <strong>free of obstructions</strong> that might prevent safe
              movement of heavy vehicles.
            </li>
            <li>
              <strong>Schedule truck movements</strong> for periods when
              sufficient space is available in driveways, forecourts, and
              loading docks to allow entry and exit in a forward direction.
            </li>
          </ol>
        </div>
      </div>
      <div className="mt-5 px-2">
        <p>Distribution: Enfield ILC Warehouse Tenants </p>
      </div>
    </div>
  );
};

export default SafetyAlert;
