import React from "react";
import { FormField, TextBox } from "../../Form";
import SelectField from "../../Form/Fields/SelectField";
import { useFormContext } from "react-hook-form";

function BusinessDetails({ prev, onNext }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const submit = () => {
    onNext();
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      <h3 className="stepper_Header">Business Details</h3>
      <div className="my-3">
        <SelectField
          title="Business Type"
          className="Field_label"
          ratio={[12, 12]}
          required
          id="businessInfo.businessType"
          options={[
            { key: "Pty Ltd", value: "PTY_LTD" },
            { key: "Sole Trader", value: "SOLE_TRADER" },
            { key: "Partnership", value: "PARTNERSHIP" },
            { key: "Trust", value: "TRUST" },
            { key: "Other", value: "OTHER" },
            { key: "None", value: "NONE" },
          ]}
          optionDisplayKey={"key"}
          optionValueKey={"value"}
          register={register}
          errors={errors}
          getError={(error) => error?.businessInfo?.businessType}
        />
        <FormField
          className="Field_label"
          title="Nature of Business"
          required
          id="businessInfo.natureOfBusiness"
          ratio={[12, 12]}
        >
          <TextBox
            type="text"
            id="businessInfo.natureOfBusiness"
            register={register}
            errors={errors}
            placeholder="Enter nature of business"
            className="py-2"
            getError={(error) => error?.businessInfo?.natureOfBusiness}
          />
        </FormField>
        <FormField
          className="Field_label"
          title="Special Conditions"
          id="businessInfo.specialConditions"
          ratio={[12, 12]}
        >
          <TextBox
            type="text"
            id="businessInfo.specialConditions"
            register={register}
            errors={errors}
            placeholder="Enter special the condition"
            className="py-2"
            getError={(error) => error?.businessInfo?.specialConditions}
          />
        </FormField>
      </div>
      <div className="form-btn">
        <button type="button" className="credit-form-btn" onClick={prev}>
          Back
        </button>
        <button type="submit" className="credit-form-btn">
          Next
        </button>
      </div>
    </form>
  );
}

export default BusinessDetails;
