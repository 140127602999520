import b64toBlob from ".";
import axios from 'axios';
import config from "../constants/config";

export const getApiCall = async (url) => {
  const userData = JSON.parse(localStorage.getItem("userdetails"));
  const token = userData.userContext.tokenId;
  const response = await axios.get(
    url, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      "tokenId": `${token}`
    }
  });
  return response;
}

export async function postFormDataApiCall(url, formData) {
  const userData = JSON.parse(localStorage.getItem("userdetails"));
  const tokenId = userData.userContext.tokenId;

  const response = await axios.post(
    url,
    formData, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      "Content-Type": 'multipart/form-data',
      "tokenId": `${tokenId}`
    },
  });

  return response;
}

export async function putApiCallFormData(url, formData) {
  const userData = JSON.parse(localStorage.getItem("userdetails"));
  const tokenId = userData.userContext.tokenId;

  const response = await axios.put(
    url,
    formData, {
    headers: {
      "Content-Type": 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      "tokenId": `${tokenId}`
    },
  });
  return response;
}

export async function putApiCallBody(url, mcqList) {
  const userData = JSON.parse(localStorage.getItem("userdetails"));
  const tokenId = userData.userContext.tokenId;
  const response = await axios.put(
    url,
    mcqList, {
    headers: {
      "Content-Type": 'application/json',
      'Access-Control-Allow-Origin': '*',
      "tokenId": `${tokenId}`
    },
  });
  return response;
}

export async function deleteApiCall(url) {
  const userData = JSON.parse(localStorage.getItem("userdetails"));
  const tokenId = userData.userContext.tokenId;
  const response = await axios.delete(
    url, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      "tokenId": `${tokenId}`
    },
  });
  return response;
}

export async function deleteApiCallFormData(url, formData) {
  const userData = JSON.parse(localStorage.getItem("userdetails"));
  const tokenId = userData.userContext.tokenId;
  const response = await fetch(url, {
    method: 'DELETE',
    body: formData,
    headers: {
      'Access-Control-Allow-Origin': '*',
      "tokenId": `${tokenId}`
    }
  });
  return response;
}

// A mock function to mimic making an async request for data
export const fetchCount = (amount = 1) => {
  return new Promise((resolve) =>
    setTimeout(() => resolve({ data: amount }), 500)
  );
}

export const headerData = {
  Source: "Desktop",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Connection: "keep-alive",
  "Access-Control-Request-Headers": "*",
  "Access-Control-Expose-Headers": "*",
  ACCEPT_ENCODING: "gzip, deflate, br",
  ACCEPT_LANGUAGE: "en-US,en;q=0.9,ru-RU;q=0.8,ru;q=0.7",
  "Upgrade-Insecure-Requests": 1
};

export const authHeader = () => {
  const userData = JSON.parse(localStorage.getItem("userdetails"));
  if (userData?.userContext?.tokenId && userData?.userContext?.userId) {
    return {
      tokenId: userData.userContext.tokenId,
      userId: userData.userContext.userId,
    };
  } else {
    // TODO: here logic to redirect to login page
  }
};

const postData = async (url = "", data = {}, headerObj = {}) => {
  try {
    let newheaders = { ...headerData, ...headerObj, ...authHeader() };
    const response = await fetch(url, {
      method: "POST",
      headers: newheaders,
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    return response.json(); // parses JSON response into native JavaScript objects
  } catch (error) {
    throw error;
  }
};

const postFile = async (
  url = "",
  data = {},
  headerObj = {},
  method = "POST"
) => {
  try {
    let newheaders = { ...headerData, ...headerObj, ...authHeader() };
    delete newheaders["Content-Type"];
    const response = await fetch(url, {
      method,
      headers: newheaders,
      body: data,
    });
    if (!response.ok) {
      throw await response.json();
    }
    return response.json();
  } catch (error) {
    throw error;
  }
};

const getData = async (url = "", headerObj = {}, isFile = false) => {
  try {
    let newheaders = { ...headerData, ...headerObj, ...authHeader() };
    const response = await fetch(url, {
      method: "GET",
      headers: newheaders,
      referrerPolicy: "no-referrer",
    });
    if (isFile) {
      return response;
    } else {
      return response.json(); // parses JSON response into native JavaScript objects
    }
  } catch (error) {
    throw error;
  }
};

const deleteData = async (url = "", headerObj = {}) => {
  try {
    let newheaders = { ...headerData, ...headerObj, ...authHeader() };
    const response = await fetch(url, {
      method: "DELETE",
      headers: newheaders,
      referrerPolicy: "no-referrer",
    });
    return response.json(); // parses JSON response into native JavaScript objects
  } catch (error) {
    throw error;
  }
};

const fetchService = async ({
  url,
  method = "GET",
  body,
  headers = {},
  onSuccessCallback,
  isFile = false,
  removeContentType = false,
}) => {
  const URL = config.baseUrl + url;
  const reqHeaders = { ...headerData, ...headers, ...authHeader() };

  if (removeContentType) delete reqHeaders["Content-Type"];

  const requestOptions = {
    method,
    headers: reqHeaders,
    body,
  };
  const response = await fetch(URL, requestOptions);
  if (!response.ok) {
    throw await response.json();
  }

  if (response.status === 200 && onSuccessCallback) {
    onSuccessCallback(await response.json());
    return;
  }

  if (isFile) return response;
  return response.json();
};

const downloadFileFromReadableStream = (data, file) => {
  const b64data = data.replace(/-/g, "+").replace(/_/g, "/");
  downloadFileFromBlob(b64toBlob(b64data, file.mimeType), file.fileName);
};

const downloadFileFromBlob = (blob, fileName) => {
  const file = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = file;
  link.download = fileName;
  // Append to html link element page
  document.body.appendChild(link);
  // Start download
  link.click();
  link.parentNode.removeChild(link);
  // clean up Url
  window.URL.revokeObjectURL(file);
};

const downloadCsv = (fileName, csvData) => {
  const element = document.createElement("a");

  element.setAttribute("href", `data:text/csv;charset=utf-8,${csvData}`);
  element.setAttribute("download", fileName);
  element.style.display = "none";

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export {
  postData,
  getData,
  deleteData,
  postFile,
  fetchService,
  downloadFileFromReadableStream,
  downloadFileFromBlob,
  downloadCsv,
};
