import React, { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { FaPowerOff } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import config from "../../constants/config";
import Ellipse1 from "../../images/Ellipse1.png";
import {
  ProfilePicture,
  UserDetailsForm,
} from "../../features/UserManagement/TabPages";
import {
  doLogout,
  fetchProfileDetails,
  getProfileDetails,
  getProfilePicture,
  getRole,
  getUserId,
  getUserName,
} from "../../rtk-slice/globalSlice";
import { dataURLtoFile } from "../../utils";
import ModalTab from "../Modal";
import AvatarComp from "./Avatar";
const UserProfileDetails = () => {
  const [profileImg, setProfileImg] = useState(null);
  const userName = useSelector(getUserName);
  const userId = useSelector(getUserId);
  const userRole = useSelector(getRole);
  const profileDetails = useSelector(getProfileDetails);
  const profilePicData = useSelector(getProfilePicture);
  const [showProfileDetails, setShowProfileDetails] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (profilePicData) {
      let picData = profilePicData;
      if (picData.slice(0, 20) === "dataimage/jpegbase64")
        picData = picData.slice(20);
      let file = dataURLtoFile(
        `data:text/plain;base64,${picData}`,
        "profilepicavatar.jpeg"
      );
      try {
        setProfileImg(URL.createObjectURL(file));
      } catch (error) {
        console.log("unable to set avatar, error:", error);
        setProfileImg(Ellipse1);
      }
    } else {
      setProfileImg(null);
    }
  }, [profilePicData]);

  const handleViewMore = () => {
    dispatch(fetchProfileDetails());
    setShowProfileDetails(true);
  };
  const handleLogout = () => {
    dispatch(doLogout());
  };
  return (
    <>
      <NavDropdown
        id="dropdown-button-dark"
        menuVariant="dark"
        title={
          userName && (
            <AvatarComp
              name={userName}
              role={userRole}
              profileImg={profileImg}
            />
          )
        }
        align="center"
        className="mx-5 fw-bold"
      >
        <NavDropdown.Item href="#/action-2" onClick={handleViewMore}>
          <CgProfile color="white" size={15} />{" "}
          <span className="fw-bold">PROFILE</span>
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="#/action-1" onClick={handleLogout}>
          <FaPowerOff color="white" size={15} />{" "}
          <span className="fw-bold">LOG OUT</span>
        </NavDropdown.Item>
      </NavDropdown>
      <ModalTab
        size={"lg"}
        onHide={() => setShowProfileDetails(false)}
        show={showProfileDetails}
        body={
          <div className="myprofile--modal">
            <div className="myprofile--picture p-1">
              <ProfilePicture
                id={userId}
                viewEditMode="edit"
                submitPictureUrl={config.baseUrl + config.uploadProfilePicture}
                getPictureUrl={config.baseUrl + config.getProfilePicture}
                setProfileImg={setProfileImg}
              />
            </div>
            {profileDetails && (
              <UserDetailsForm
                id={userId}
                OnSubmitFunction={() => dispatch(fetchProfileDetails())}
                defaultValues={{ ...profileDetails, profilePic: null }}
                viewEditMode="profileMode"
              />
            )}
          </div>
        }
      />
    </>
  );
};

export default UserProfileDetails;
