import React, { useEffect, useState, useContext } from 'react';
import "./SearchScreenMainContent.css";
import TableHeaders from './Table/TableHeaders';
import RowBuilder from './Table/RowBuilder';
import { useLocation } from 'react-router-dom';
import { McqProgressContext } from '../../context/McqProgress';
import { useSearchContext } from '../../context/SearchContext';
import { getProgress } from '../../InductionLearningServices/Search';

const SearchScreenMainContent = () => {
    const { nameOfUser } = useSearchContext();
    const location = useLocation();
    const userDetails = location.state.userDetails;
    const [tableRowsData, setTableRowsData] = useState({});
    const { setMcqList } = useContext(McqProgressContext);
    let isDataThere = true;

    let headerList = [
        { "title": "Module", "show": "1" }, { "title": "VIDEO", "show": "1" }, { "title": "MCQ", "show": "1" }, { "title": "TEXT", "show": "1" }
    ];


    useEffect(() => {
        const fetchData = async () => {
            let response = await getProgress(userDetails.userId);
            if (response === null) {
                isDataThere = false;
            } else {
                isDataThere = true;
                let dataList = response.data;

                let updatedTableData = { ...tableRowsData };

                dataList.forEach((rowData) => {
                    let newRowData = {
                        "title": rowData.trainingName,
                        "VIDEO": "0.00",
                        "MCQ": "0.00",
                        "TEXT": "0.00",
                        "ID": nameOfUser,
                        "mcqList": []
                    };

                    rowData.driverModuleProgress.forEach((item) => {
                        switch (item.type) {
                            case "VIDEO":
                                newRowData.VIDEO = item.progress.progress;
                                break;
                            case "MCQ":
                                setMcqList((prev) => item.progress.progress);
                                newRowData.mcqList = item.progress.progress;
                                newRowData.MCQ = item.progress.correctAnswerPercentage;
                                break;
                            case "TEXT":
                                newRowData.TEXT = item.progress.progress;
                                break;
                        }
                    });

                    updatedTableData[rowData.trainingName] = newRowData;
                });

                setTableRowsData(updatedTableData);
            }
        };

        fetchData();
    }, [nameOfUser, userDetails.userId]);

    return (
        <div className='search-screen-main-content'>
            <p className="screen-heading-title">
                {userDetails.name}
            </p>
            <br />
            <div className="search-screen-main-content-table-container">
                {
                    isDataThere == true
                        ? <div className="search-screen-main-content-table">
                            <TableHeaders list={headerList} />
                            {Object.values(tableRowsData).map((object, index) => (
                                <div key={index}>
                                    {object.ID === nameOfUser &&
                                        <RowBuilder object={object} last={true} />
                                    }
                                </div>
                            ))}
                        </div>
                        : <h5 style={{ color: "white" }}>No Data Available.</h5>
                }
            </div>
        </div>
    );
};

export default SearchScreenMainContent;
