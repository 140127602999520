const VERIFIED_REPORT = "Verified Reports";
const APPROVED_REPORT = "Approved Reports";

const ANNUAL_REPORT = "Annual Reports";
const TPAR_REPORT = "TPAR Reports";
const ABA_FILE = "ABA Files";

const WEEKLY_HOUR_REPORT = "Weekly Hour Report";
const DRIVER_GROUP_VEHICLE_HOURS_REPORT = "Driver Group Vehicle hours Report";

const VERIFIED_REPORT_DRIVER_GROUP_REPORT = "Verified Report Driver Group";
const APPROVED_REPORT_DRIVER_GROUP_REPORT = "Approved Report Driver Group";

const APPROVED_MODIFIED_HOURS = "Approved modified hours";

const FINALIZED_REPORT = "Finalised";

const REPORTS = {
  ANNUAL_REPORT,
  TPAR_REPORT,
  ABA_FILE,
  VERIFIED_REPORT,
  APPROVED_REPORT,
  WEEKLY_HOUR_REPORT,
  DRIVER_GROUP_VEHICLE_HOURS_REPORT,
  VERIFIED_REPORT_DRIVER_GROUP_REPORT,
  APPROVED_REPORT_DRIVER_GROUP_REPORT,
  APPROVED_MODIFIED_HOURS,
  FINALIZED_REPORT,
};

export default REPORTS;
