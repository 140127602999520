import React, { useContext } from "react";
import "./AdminMain.css";
import CustomPopup from "./CustomPopUp";
import "./DeletePopUp.css";
import OvalButton from "./OvalButton";
import Exclamation from "../../assets/InductionLearningAssets/red-exclamation.png";
import { TrainingsContext } from "../../context/TrainingsContext";
import { deleteTraining } from "../../InductionLearningServices/Trainings";
import { useLoadingContext } from "../../context/LoadingContext";


const DeleteTrainingPopUp = (props) => {

   const { setIsLoading } = useLoadingContext();
   const { setLoading } = useContext(TrainingsContext);

   const deleteItem = async (formData) => {
      setIsLoading(true);
      const response = await deleteTraining(formData);
      setIsLoading(false);
      if (response.status == 200) {
         props.closePopupDelete();
      }
      setLoading((prev) => true);
   }

   const deleteItemOnTap = () => {
      let formData = new FormData();
      formData.append('trainingId', props.trainingId);
      deleteItem(formData);
   }

   return (
      <CustomPopup isOpen={props.isDeleteOpen} onClose={props.closePopupDelete} height="20%" width="40%">
         <div className="delete-popup">
            <img src={Exclamation} alt='red-exclamation' />
            <p className="delete-popup-heading">
               Are you sure you want to delete this training?
            </p>
            <p className="delete-popup-text">
               Deleting items from this cannot be undone.
            </p>

            <div className="delete-popup-button-group">
               <div onClick={deleteItemOnTap}>
                  <OvalButton
                     name="Yes, Continue"
                     color="red" />
               </div>

               <div onClick={props.closePopupDelete}>
                  <OvalButton
                     name="No, Go Back"
                     color="transparent" />
               </div>
            </div>
         </div>
      </CustomPopup>
   );
}

export default DeleteTrainingPopUp;