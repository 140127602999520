export class ArrayFilters {
  source;
  filtered;

  constructor(source) {
    this.source = source || [];
    this.filtered = JSON.parse(JSON.stringify(this.source));
  }

  sum(column) {
    return this.filtered.reduce((a, b) => a + b[column], 0);
  }

  average(column) {
    return this.sum(column) / this.source.length;
  }

  sortAscending(column) {
    this.filtered.sort((a, b) => a[column] - b[column]);
  }

  sortDescending(column) {
    this.filtered.sort((a, b) => b[column] - a[column]);
    return this;
  }

  sortSourceDescending(column) {
    this.filtered = JSON.parse(JSON.stringify(this.source));
    this.sortDescending(column);
  }

  equals(column, value) {
    this.filtered = this.filtered.filter((v) => v[column] === value);
    return this;
  }

  notEquals(column, value) {
    this.filtered = this.filtered.filter((v) => v[column] !== value);
    return this;
  }

  greaterThan(column, value) {
    this.filtered = this.filtered.filter((v) => v[column] > value);
    return this;
  }

  greaterThanOrEqual(column, value) {
    this.filtered = this.filtered.filter((v) => v[column] >= value);
    return this;
  }

  lessThan(column, value) {
    this.filtered = this.filtered.filter((v) => v[column] < value);
    return this;
  }

  lessThanOrEqual(column, value) {
    this.filtered = this.filtered.filter((v) => v[column] <= value);
    return this;
  }

  topN(range) {
    this.filtered = this.filtered.splice(
      0,
      this.filtered.length > range ? range : this.filtered.length
    );
    return this;
  }

  removeAtIndexFromFilteredArray(start, deleteCount = 1) {
    this.filtered.splice(start, deleteCount);
    return this;
  }

  stripTimeFromDate(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  getDataForExpiredDrivers() {
    const currentDate = this.stripTimeFromDate(new Date());

    const expiredDrivers = [];
    const unexpiredDrivers = [];

    this.filtered.forEach((d) => {
      const isExpired =
        this.stripTimeFromDate(new Date(d.licenseExpiryDateStr)) < currentDate;
      if (isExpired) {
        expiredDrivers.push(d);
      } else {
        unexpiredDrivers.push(d);
      }
    });

    return {
      expired: expiredDrivers,
      unexpired: unexpiredDrivers,
    };
  }

  getWeightByColumn(column, id) {
    const arr = [];
    const obj = this.filtered.reduce((acc, stock) => {
      const { [column]: colValue } = stock;
      if (acc[colValue]) {
        acc[colValue][id] += `, ${stock[id]}`;
        acc[colValue].weight += +stock.weight.toFixed(6);
      } else {
        acc[colValue] = { ...stock };
        acc[colValue].weight = +stock.weight.toFixed(6);
      }
      return acc;
    }, {});
    Object.values(obj).forEach((stock) => arr.push({ ...stock }));
    return arr;
  }

  groupDataByColumn(column) {
    const arr = [];
    const obj = this.source.reduce((acc, element) => {
      const { [column]: colValue } = element;
      if (acc[colValue]) {
        acc[colValue].value = acc[colValue].value + 1;
      } else {
        const id = colValue ?? "Not Mentioned";
        acc[colValue] = {
          id,
          value: 1,
          label: id,
        };
      }
      return acc;
    }, {});
    Object.values(obj).forEach((el) => arr.push({ ...el }));
    return arr;
  }

  filterByColumn(column, id) {
    if (id === "Not Mentioned") {
      return this.source.filter((data) => !data?.[column]);
    }
    return this.source.filter((data) => id === data[column]);
  }

  get() {
    return this.filtered;
  }

  getSource() {
    return this.source;
  }
}
