import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import * as Yup from "yup";
import { FaEye, FaPen } from "react-icons/fa";

import { FaTrash, FaPlus } from "react-icons/fa";
import { TextBox, Button } from "../../../components/Form";
import { getData, postData } from "../../../utils/api";
import { errorToast, successToast } from "../../../utils/toastFunc";
import ModalTab from "../../../components/Modal";
import "../UserManagement.scss";
import config from "../../../constants/config";

const DeductionTab = ({
  defaultValues,
  driverId,
  submitUrl,
  viewEditMode,
  setKey,
}) => {
  const colors = { btnColor: "#435E9E" };
  const [viewMode, setViewMode] = useState(viewEditMode);
  const toggleViewMode = () =>
    setViewMode((prevViewMode) => (prevViewMode === "edit" ? "view" : "edit"));
  const [showDeductionHistory, setShowDeductionHistory] = useState(false);
  const [DeductionHistory, setDeductionHistory] = useState();
  const handleHideModal = () => setShowDeductionHistory(false);

  const validationSchema = Yup.object().shape({
    deductionDetailsDto: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().nullable(),
        deductionType: Yup.string().required("Deduction Type is Manndatory").typeError("Deduction Type is mandatory"),
        amount: Yup.number()
          .typeError("Amount is Mandatory")
          .required("Amount is Mandatory"),
        notes: Yup.string(),
        date: Yup.string(),
        active: Yup.boolean(),
      })
    ),
    deductionAmount: Yup.number()
      .required("Value is mendatory")
      .typeError("Value is mendatory"),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });

  /* A react hook form function that is used to create dynamic form fields. */
  const { fields, append, remove } = useFieldArray({
    name: "deductionDetailsDto",
    control,
  });

  /*  react hook form function that is used to watch the value of a field. */
  const deductionsArray = useWatch({
    control,
    name: "deductionDetailsDto",
  });
  const onSubmitFunction = async (formData) => {
    submitUrl = submitUrl + `?driverId=${driverId}`;
    let response = await postData(submitUrl, formData);

    if (response?.data) {
      // success response after
      successToast({ mes: "Deduction Details Saved Successfully" });
      const url =
        config.baseUrl + config.getDriverDeductions + "?driverId=" + driverId;
      const resp = await getData(url);

      if (resp?.data) {
        reset(resp.data); //this sets the values from response
      }
      if (!viewEditMode) {
        // if creating new User
        setViewMode("view"); // to enable view mode after saving deduction information
        setKey("additions"); // moving to another tab
      }
    } else {
      // else server error handling
      errorToast({ mes: "Try Again" });
    }
  };
  useEffect(() => {
    /* Calculating the total loan amount. */
    let totalLoan = Array.isArray(deductionsArray)
      ? deductionsArray.reduce(function (prev, cur) {
          return Number(prev ? prev : 0) + Number(cur.amount ? cur.amount : 0);
        }, 0)
      : 0;
    /* setting total loan and balance text boxes. */
    setValue("totalLoan", Number(totalLoan).toFixed(2));

    const reportDeductionAmount = getValues("reportDeductionAmount") || 0;
    setValue(
      "netBalance",
      Number(totalLoan - reportDeductionAmount).toFixed(2)
    );
  });

  const handleShowHistory = async () => {
    const url =
      config.baseUrl +
      config.getDriverDeductionsHistory +
      "?driverId=" +
      driverId;
    const resp = await getData(url);
    if (resp?.data) {
      setDeductionHistory(resp.data);
    }
    // console.log("history modal will open");
    setShowDeductionHistory(true);
  };
  const handleDeleteRow = (index) => {
    const confirm = window.confirm("Are you sure to Delete?");
    if (confirm) {
      remove(index);
      successToast({ mes: "Deduction row successfully deleted" });
    }
  };
  const handleAddRow = () => {
    append({
      id: null,
      deductionType: "",
      amount: 0,
      date: "",
      notes: "",
      active: true,
    });
  };

  return (
    <>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitFunction)}
        className=" blur-bg p-3"
      >
        {viewMode && !viewEditMode && (
          <button
            type="button"
            className="btn btn-primary p-1 me-1 lh-1"
            onClick={toggleViewMode}
          >
            {viewMode === "edit" ? <FaEye size={20} /> : <FaPen size={20} />}
          </button>
        )}
        <div className="col ">
          <div className="form-group d-flex bold-text">
            <div className="col-1 p-2 center-items">S.N</div>
            <div className="col-2 p-2 center-items">Deduction Type</div>
            <div className="col-2 p-2 center-items">Amount</div>
            <div className="col-2 p-2 center-items">Date</div>
            <div className="col-2 p-2 center-items">Notes</div>
            <div className="col-3 p-2 row">
              <div className="col-5">
                {viewMode !== "view" && (
                  <Button
                    type="Button"
                    className="buttons"
                    onClick={handleAddRow}
                    title="Add"
                  >
                    <FaPlus className="me-1" />
                  </Button>
                )}
              </div>
              <div className="col-6">
                <Button
                  type="Button"
                  className="buttons"
                  onClick={handleShowHistory}
                  title="History"
                />
              </div>
            </div>
          </div>

          {fields.map((item, index) => (
            <div key={index} className="form-group d-flex row">
              <div className="col-1 p-2 ">
                <div className="d-inline-block">{index + 1}</div>
              </div>

              <div className="col-2 p-2">
                <TextBox
                  id={`deductionDetailsDto[${index}].deductionType`}
                  placeholder="Deduction Type"
                  disabled={viewMode === "view"}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                />
                {errors?.deductionDetailsDto?.[index]?.deductionType && (
                  <p className="d-flex invalid-feedback">{errors["deductionDetailsDto"][index].deductionType.message}</p>
                )}{" "}
              </div>

              <div className="col-2 p-2">
                <TextBox
                  id={`deductionDetailsDto[${index}].amount`}
                  type="number"
                  disabled={viewMode === "view"}
                  register={register}
                  step=".01"
                  errors={errors}
                />
                {errors?.deductionDetailsDto?.[index]?.amount && (
                  <p className="d-flex invalid-feedback">{errors["deductionDetailsDto"][index].amount.message}</p>
                )}{" "}
              </div>
              <div className="col-2 p-2">
                <TextBox
                  id={`deductionDetailsDto[${index}].date`}
                  type="date"
                  disabled={viewMode === "view"}
                  register={register}
                  max="9999-12-31"
                  errors={errors}
                />
                {errors?.deductionDetailsDto?.[index]?.date && (
                  <p className="d-flex invalid-feedback">{errors["deductionDetailsDto"][index].date.message}</p>
                )}{" "}
              </div>
              <div className="col-2 p-2">
                <TextBox
                  register={register}
                  disabled={viewMode === "view"}
                  errors={errors}
                  id={`deductionDetailsDto[${index}].notes`}
                />
              </div>

              <div className="col-1 p-2  ">
                {viewMode !== "view" && (
                  <Button
                    type="Button"
                    className="buttons"
                    onClick={() => handleDeleteRow(index)}
                  >
                    <FaTrash className="" size={20} />
                  </Button>
                )}
              </div>
            </div>
          ))}

          {/* Total Loan  */}
          <div className="form-group d-flex row mt-2 mb-3">
            <div className="col-1 p-2 center-items ">
              <div className="d-inline-block"></div>
            </div>
            <div className="col-2 p-2">
              <Button
                title="Total Loan"
                color={colors.btnColor}
                className="cursor-default"
              />
            </div>
            <div className="col-2 p-2">
              <TextBox
                id={`totalLoan`}
                type="number"
                value={0}
                step=".01"
                disabled={true}
                register={register}
                errors={errors}
              />
            </div>

            <div className="col-5 p-2"></div>
          </div>

          {/* deduction Amount  */}
          <div className="form-group d-flex row mt-2">
            <div className="col-1 p-2 center-items ">
              <div className="d-inline-block"></div>
            </div>

            <div className="col-2 p-2">
              <Button
                title="Deduction Amount"
                color={colors.btnColor}
                className="cursor-default"
              />
            </div>

            <div className="col-2 p-2">
              <TextBox
                id={`deductionAmount`}
                type="number"
                value={0} //defaultValue
                disabled={viewMode === "view"}
                step=".01"
                register={register}
                errors={errors}
              />
            </div>
            <div className="col-5 p-2"></div>
          </div>

          {/* Report Deduction */}
          <div className="form-group d-flex row mt-2">
            <div className="col-1 p-2 center-items ">
              <div className="d-inline-block"></div>
            </div>

            <div className="col-2 p-2">
              <Button
                title="Report Deduction"
                color={colors.btnColor}
                className="cursor-default"
              />
            </div>

            <div className="col-2 p-2">
              <TextBox
                id={`reportDeductionAmount`}
                type="number"
                value={0} //defaultValue
                disabled={true}
                step=".01"
                register={register}
                errors={errors}
              />
            </div>
            <div className="col-5 p-2"></div>
          </div>

          {/* Balance */}
          <div className="form-group d-flex row mt-2">
            <div className="col-1 p-2 center-items ">
              <div className="d-inline-block"></div>
            </div>

            <div className="col-2 p-2">
              <Button
                title="Balance"
                color={colors.btnColor}
                className="cursor-default"
              />
            </div>

            <div className="col-2 p-2">
              <TextBox
                id={`netBalance`}
                type="number"
                // staticVal={totalLoan}
                // setValue={setValue}
                value={0} //defaultValue
                step=".01"
                disabled={true}
                register={register}
                errors={errors}
              />
            </div>

            <div className="col-5 p-2"></div>
          </div>
        </div>

        <div className="row p-4 ">
          <div className="col-10"></div>
          <div className="ml-auto col-2 ">
            {viewMode !== "view" && (
              <Button
                type="submit"
                className="buttons"
                title={viewMode === "edit" ? "Save" : "Submit"}
              />
            )}
          </div>
        </div>
      </form>

      {/* history */}
      <ModalTab
        size="lg"
        centered
        bodyClassName={
          "border border-secondary shadow-lg p-3 mb-5 bg-body rounded m-5"
        }
        onHide={handleHideModal}
        title="Deduction History"
        show={showDeductionHistory}
        body={
          <>
            <div className={` p-3 pt-5 pb-5  blur-bg rounded `}>
              <section className="row mb-2">
                {!Array.isArray(DeductionHistory) ? (
                  <div>NO DATA PRESENT</div>
                ) : (
                  <div className="row mb-2">
                    {[
                      "Date",
                      "Total Loan",
                      "Deduction",
                      "Report Deduction",
                      "Balance",
                    ].map((title, index) => (
                      <div
                        key={title}
                        className={`${
                          index % 2 === 0 ? "col-md-2" : "col-md-3"
                        }`}
                      >
                        <Button
                          title={title}
                          color={colors.btnColor}
                          className="cursor-default p-1"
                        />
                      </div>
                    ))}
                  </div>
                )}

                {Array.isArray(DeductionHistory) &&
                  DeductionHistory.map(
                    ({
                      date,
                      deductionAmount,
                      totalLoan,
                      balance,
                      reportDeductionAmount,
                    }) => (
                      <div key={date} className="row mb-1">
                        <div className="col-md-2">{date}</div>
                        <div className="col-md-3">{totalLoan}</div>
                        <div className="col-md-2">{deductionAmount}</div>
                        <div className="col-md-3">{reportDeductionAmount}</div>
                        <div className="col-md-2">{balance}</div>
                      </div>
                    )
                  )}
              </section>
            </div>
          </>
        }
      />
    </>
  );
};

export default DeductionTab;
