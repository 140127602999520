import React, { useEffect, useState } from "react";
import ConditionOfCarriageContent from "./ConditionOfCarriageContent";

function ConditionsOfCarriage({ prev, onNext, isCustomerAdmin }) {
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    if (isCustomerAdmin) {
      setAgree(true);
    }
  }, [isCustomerAdmin]);

  const handleCheckbox = (e) => {
    setAgree(e.target.checked);
  };
  return (
    <div>
      <ConditionOfCarriageContent />
      <div
        className="row"
        style={{
          fontSize: "17px",
        }}
      >
        <div className="col-sm-1 d-flex align-items-center justify-content-center">
          <input
            className="form-check-input border-black "
            type="checkbox"
            checked={agree}
            onChange={handleCheckbox}
          />
        </div>
        <div className="col-sm-11 my-3">
          <label
            htmlFor="agree"
            className="col-form-label text-left"
            style={{
              fontWeight: 700,
            }}
          >
            I agree above Conditions of Carriage
            <span style={{ color: "red" }}>*</span>
          </label>
        </div>
      </div>
      <div className="form-btn">
        <button type="button" className="credit-form-btn" onClick={prev}>
          Back
        </button>
        <button
          type="submit"
          className={`credit-form-btn ${!agree ? "disabled" : ""}`}
          onClick={onNext}
          disabled={!agree}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default ConditionsOfCarriage;
