import React from 'react';

const OvalButton = ({ color, name, children}) => {

    const colorUsed = (color === "red") ? "rgba(236, 29, 37, 1)" : "transparent";
    const border = (colorUsed === "transparent") ? "1px solid white" : "none";

  return (
    <div className="oval-button"
        style={
            {
                backgroundColor: colorUsed,
                border: border,
                borderRadius: "27px",
                height: "45px",
                width: "173px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 20px",
                cursor: "pointer"
            }
        }
    >
        <p
            style={{
                margin: "0",
                padding: "0",
                fontWeight: "400",
                fontSize: "16px",
                color: "white"
            }}
        >
            {name}
            {children}
        </p>
    </div>
  );
}

export default OvalButton;