import moment from "moment";
import * as yup from "yup";
import { MAX_DATE_STRING } from "../../../../constants/enums";

export const driverFormData = [
  {
    tabId: "general",
    displayName: "General",
    uniqueReferenceKey: "driverId",
    url: "/transports/driver/card",
    formFields: [
      {
        disabled: true,
        name: "Driver Employee Id",
        label: "Driver Employee Id",
        type: "text",
        validation: "Alphabetic",
        fieldId: "driverCardNo",
        id: "driverCardNo",
      },
      {
        name: "Driver ID",
        label: "Driver ID",
        type: "text",
        validation: "Alphabetic",
        fieldId: "driverId",
        id: "driverId",
        hide: true,
        hideInViewMode: true,
      },
      {
        name: "Driver Name",
        label: "Driver Name",
        type: "text",
        validation: "Alphabetic",
        fieldId: "driverName",
        id: "driverName",
        validationType: "string",
        disabled: true,
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Surname",
        disabled: true,
        label: "Surname",
        type: "text",
        validation: "Alphabetic",
        fieldId: "surName",
        id: "surName",
        validationType: "nullable",
        // validations: [
        //   {
        //     type: "max",
        //     params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
        //   },
        // ],
      },
      {
        name: "Gender",
        label: "Gender",
        type: "select",
        validation: "Alphabetic",
        fieldId: "gender",
        id: "gender",
        valueasId: true,
        options: [
          {
            id: "Male",
            label: "MALE",
          },
          {
            id: "Female",
            label: "FEMALE",
          },
        ],
        validationType: "string",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
        ],
      },
      {
        disabled: true,
        name: "Date of Birth",
        label: "Date of Birth",
        type: "date",
        validation: "Alphabetic",
        fieldId: "dateOfBirth",
        id: "dateOfBirth",
        validationType: "string",
        max: moment().format("YYYY-MM-DD"),
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
          },
        ],
      },
      {
        name: "Position",
        label: "Position",
        type: "text",
        validation: "Alphabetic",
        fieldId: "position",
        id: "position",
        validationType: "string",
        disabled: true,
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Department",
        label: "Department",
        type: "select",
        validation: "Alphabetic",
        fieldId: "department",
        id: "department",
        loadLookUp: "driverDepartments",
        lookupOptions: {
          type: "object",
          key: "id",
          label: "description",
        },
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Company Branch",
        label: "Company Branch",
        type: "text",
        validation: "Alphabetic",
        fieldId: "companyBranch",
        id: "companyBranch",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Vendor",
        label: "Vendor",
        type: "select",
        validation: "Alphabetic",
        fieldId: "vendor",
        loadLookUp: "vendorCodes",
        lookupOptions: {
          type: "object",
          key: "vendorCode",
          label: "description",
        },
        id: "vendor",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
        ],
      },
      {
        name: "Start Date",
        label: "Start Date",
        type: "date",
        validation: "Alphabetic",
        fieldId: "startDate",
        id: "startDate",
        validationType: "date",
        max: MAX_DATE_STRING,
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
        ],
      },
      {
        name: "End Date",
        label: "End Date",
        type: "date",
        validation: "Alphabetic",
        fieldId: "endDate",
        id: "endDate",
        validationType: "date",
        max: MAX_DATE_STRING,
        validations: [
          {
            type: "min",
            params: [
              yup.ref("startDate"),
              "##FIELD_NAME## should be greater than Start Date",
            ],
            nullable: true,
          },
        ],
      },
      {
        disabled: true,
        name: "Address",
        label: "Address",
        type: "textarea",
        validation: "Alphabetic",
        fieldId: "address",
        id: "address",
        validationType: "string",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
          },
          {
            type: "max",
            params: [250, "##FIELD_NAME## cannot be more than 250 characters"],
          },
        ],
      },
      {
        disabled: true,
        name: "Suburb / City",
        label: "Suburb / City",
        type: "text",
        validation: "Alphabetic",
        fieldId: "citySuburb",
        id: "citySuburb",
      },
      {
        disabled: true,
        name: "Post Code",
        label: "Post Code",
        type: "text",
        validation: "Alphabetic",
        fieldId: "postCode",
        id: "postCode",
      },
      {
        disabled: true,
        name: "State",
        label: "State",
        type: "text",
        validation: "Alphabetic",
        fieldId: "state",
        id: "state",
      },
      {
        disabled: true,
        name: "Contact No",
        label: "Contact No",
        type: "text",
        validation: "Alphabetic",
        fieldId: "contactNo",
        id: "contactNo",
        validationType: "string",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        disabled: true,
        name: "Email Address",
        label: "Email Address",
        type: "text",
        validation: "Alphabetic",
        fieldId: "emailAddress",
        id: "emailAddress",
        validationType: "string",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
          {
            type: "email",
            params: ["Must be a valid ##FIELD_NAME##"],
          },
          // {
          //   type: "max",
          //   params: [30, "##FIELD_NAME## cannot be more than 30 characters"],
          // },
        ],
      },
      // {
      //   name: "Driver Rate ID",
      //   label: "Driver Rate ID",
      //   type: "text",
      //   validation: "Alphabetic",
      //   fieldId: "driverRateId",
      //   id: "driverRateId",
      //   validationType: "string",
      //   validations: [
      //     {
      //       type: "optional",
      //       params: [],
      //       nullable: true,
      //     },
      //     {
      //       type: "max",
      //       params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
      //     },
      //   ],
      // },
      {
        disabled: true,
        name: "Medical Due Next",
        label: "Medical Due Next",
        type: "date",
        validation: "Alphabetic",
        fieldId: "medicalDueNext",
        id: "medicalDueNext",
        validationType: "nullable",
        max: MAX_DATE_STRING,
        // required: true,
        // validations: [
        //   {
        //     type: "required",
        //     params: ["##FIELD_NAME## is required"],
        //     nullable: true,
        //   },
        // ],
      },
      {
        name: "Current Location",
        label: "Current Location",
        type: "text",
        validation: "Alphabetic",
        fieldId: "currentLocation",
        id: "currentLocation",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [100, "##FIELD_NAME## cannot be more than 100 characters"],
          },
        ],
      },
      {
        name: "Passport No.",
        label: "Passport No.",
        type: "text",
        validation: "Alphabetic",
        fieldId: "passportNo",
        id: "passportNo",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [15, "##FIELD_NAME## cannot be more than 15 characters"],
          },
        ],
      },
      {
        name: "Passport Expiry Date",
        label: "Passport Expiry Date",
        type: "date",
        validation: "Alphabetic",
        fieldId: "passportExpireDate",
        id: "passportExpireDate",
        validationType: "nullable",
        max: MAX_DATE_STRING,
        // required: true,
        // validations: [
        //   {
        //     type: "required",
        //     params: ["##FIELD_NAME## is required"],
        //     nullable: true,
        //   },
        // ],
      },
      {
        name: "Remarks",
        label: "Remarks",
        type: "textarea",
        validation: "Alphabetic",
        fieldId: "remarks",
        id: "remarks",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [200, "##FIELD_NAME## cannot be more than 200 characters"],
          },
        ],
      },
      {
        name: "Tracking ID",
        label: "Tracking ID",
        type: "text",
        validation: "Alphabetic",
        fieldId: "webFleetId",
        id: "webFleetId",
        validationType: "string",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
          {
            type: "max",
            params: [100, "##FIELD_NAME## cannot be more than 100 characters"],
          },
        ],
      },
      // {
      //   name: "ST Code",
      //   label: "ST Code",
      //   type: "text",
      //   validation: "Alphabetic",
      //   fieldId: "stCode",
      //   id: "stCode",
      //   validationType: "string",
      // required: true,
      //   validations: [
      //     {
      //       type: "required",
      //       params: ["##FIELD_NAME## is required"],
      //     },
      //     {
      //       type: "max",
      //       params: [100, "##FIELD_NAME## cannot be more than 100 characters"],
      //     },
      //   ],
      // },
      {
        name: "Driver Shift",
        label: "Driver Shift",
        type: "select",
        validation: "Alphabetic",
        fieldId: "shift",
        id: "shift",
        // loadLookUp: "driverShiftEnums",
        validationType: "string",
        valueasId: true,
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
        ],
        options: [
          { label: "DAY", id: "DAY" },
          { label: "NIGHT", id: "NIGHT" },
          { label: "OTHER", id: "OTHER" },
        ],
      },
      {
        disabled: true,
        name: "License Number",
        label: "License Number",
        type: "text",
        validation: "Alphabetic",
        fieldId: "licenseNumber",
        id: "licenseNumber",
        validationType: "string",
        valueasId: true,
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
          },
          {
            type: "max",
            params: [50, "##FIELD_NAME## cannot be more than 50 characters"],
          },
        ],
        options: [],
      },
    ],
    isParentTab: true,
  },
  {
    tabId: "identification-docs",
    displayName: "Identification Docs",
    uniqueReferenceKey: "driverIdentificationId",
    url: "/transports/driver/identification",
    attachmentFieldId: "identificationAttachmentsId",
    attachmentFieldKeyName: "driverIdentificationAttachments",
    mode: "view",
    formFields: [
      {
        name: "Id",
        label: "Id",
        type: "text",
        validation: "Alphabetic",
        hideInTableMode: true,
        fieldId: "driverIdentificationId",
        id: "driverIdentificationId",
      },
      {
        name: "License Type",
        label: "License Type",
        type: "text",
        validation: "Alphabetic",
        fieldId: "licenseType",
        id: "licenseType",
        validationType: "string",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
          {
            type: "max",
            params: [10, "##FIELD_NAME## cannot be more than 10 characters"],
          },
          {
            type: "matches",
            params: [/^[a-zA-Z0-9]+$/, "##FIELD_NAME## must be alphanumeric"],
          },
        ],
      },
      {
        name: "Description",
        label: "Description",
        type: "textarea",
        validation: "Alphabetic",
        fieldId: "description",
        id: "description",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Class",
        label: "Class",
        type: "text",
        validation: "Alphabetic",
        fieldId: "docsClass",
        id: "docsClass",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [10, "##FIELD_NAME## cannot be more than 10 characters"],
          },
        ],
      },
      {
        name: "License No",
        label: "License No",
        type: "text",
        validation: "Alphabetic",
        fieldId: "licenseNo",
        id: "licenseNo",
        validationType: "string",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
          {
            type: "max",
            params: [10, "##FIELD_NAME## cannot be more than 10 characters"],
          },
        ],
      },
      {
        name: "Issue Date",
        label: "Issue Date",
        type: "date",
        validation: "Alphabetic",
        fieldId: "issueDate",
        id: "issueDate",
        validationType: "date",
        max: MAX_DATE_STRING,
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
        ],
      },
      {
        name: "Expiry Date",
        label: "Expiry Date",
        type: "date",
        validation: "Alphabetic",
        fieldId: "expiryDate",
        id: "expiryDate",
        validationType: "date",
        max: MAX_DATE_STRING,
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
          {
            type: "min",
            params: [
              yup.ref("issueDate"),
              "##FIELD_NAME## should be greater than Issue Date",
            ],
            nullable: true,
          },
        ],
      },
      {
        name: "License Expiry Date",
        label: "License Expiry Date",
        type: "date",
        validation: "Alphabetic",
        fieldId: "licenseExpiryDate",
        id: "licenseExpiryDate",
        validationType: "date",
        max: MAX_DATE_STRING,
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
        ],
      },
      {
        name: "Cost",
        label: "Cost",
        type: "number",
        validation: "Alphabetic",
        fieldId: "cost",
        id: "cost",
        validationType: "number",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
        ],
      },
      {
        name: "Restrictions",
        label: "Restrictions",
        type: "textarea",
        validation: "Alphabetic",
        fieldId: "restrictions",
        id: "restrictions",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [100, "##FIELD_NAME## cannot be more than 100 characters"],
          },
        ],
      },
      {
        name: "Attachments",
        label: "Attachments",
        type: "file",
        multiple: true,
        validation: "Alphabetic",
        fieldId: "files",
        hideInTableMode: true,
        id: "files",
        hideInViewMode: true,
      },
    ],
  },
  {
    tabId: "medical",
    displayName: "Medical",
    uniqueReferenceKey: "driverMedicalId",
    url: "/transports/driver/medical",
    attachmentFieldId: "driverMedicalAttachmentsId",
    attachmentFieldKeyName: "driverMedicalAttachments",
    mode: "view",
    formFields: [
      {
        name: "Id",
        label: "Id",
        type: "text",
        validation: "Alphabetic",
        fieldId: "driverMedicalId",
        hideInTableMode: true,
        id: "driverMedicalId",
      },
      {
        name: "Medical No",
        label: "Medical No",
        type: "text",
        validation: "Alphabetic",
        fieldId: "medicalNo",
        id: "medicalNo",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Description",
        label: "Description",
        type: "textarea",
        validation: "Alphabetic",
        fieldId: "description",
        id: "description",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [30, "##FIELD_NAME## cannot be more than 30 characters"],
          },
        ],
      },
      {
        name: "Due Date",
        label: "Due Date",
        type: "date",
        validation: "Alphabetic",
        fieldId: "dueDate",
        id: "dueDate",
        validationType: "date",
        max: MAX_DATE_STRING,
        min: moment().format("YYYY-MM-DD"),
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
        ],
      },
      {
        name: "Cost",
        label: "Cost",
        type: "number",
        validation: "Alphabetic",
        fieldId: "cost",
        id: "cost",
        validationType: "number",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
        ],
      },
      {
        name: "Condition",
        label: "Condition",
        type: "text",
        validation: "Alphabetic",
        fieldId: "severity",
        id: "severity",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [30, "##FIELD_NAME## cannot be more than 30 characters"],
          },
        ],
      },
      {
        name: "Medical Company",
        label: "Medical Company",
        type: "text",
        validation: "Alphabetic",
        fieldId: "medicalCompany",
        id: "medicalCompany",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [30, "##FIELD_NAME## cannot be more than 30 characters"],
          },
        ],
      },
      {
        name: "Doctor Name",
        label: "Doctor Name",
        type: "text",
        validation: "Alphabetic",
        fieldId: "doctorName",
        id: "doctorName",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [30, "##FIELD_NAME## cannot be more than 30 characters"],
          },
        ],
      },
      {
        name: "Attachments",
        label: "Attachments",
        type: "file",
        multiple: true,
        validation: "Alphabetic",
        fieldId: "files",
        id: "files",
        hideInViewMode: true,
        hideInTableMode: true,
      },
    ],
  },
  {
    tabId: "uniform",
    displayName: "Uniform",
    mode: "view",
    uniqueReferenceKey: "driverUniformId",
    url: "/transports/driver/uniform",
    attachmentFieldId: "driverUniformAttachmentsId",
    attachmentFieldKeyName: "driverUniformAttachments",
    formFields: [
      {
        name: "Id",
        label: "Id",
        type: "text",
        validation: "Alphabetic",
        hideInTableMode: true,
        fieldId: "driverUniformId",
        id: "driverUniformId",
      },
      {
        name: "Serial No",
        label: "Serial No",
        type: "text",
        validation: "Alphabetic",
        fieldId: "serialNo",
        id: "serialNo",
        validationType: "string",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Description",
        label: "Description",
        type: "textarea",
        validation: "Alphabetic",
        fieldId: "description",
        id: "description",
        validationType: "string",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
          },
          {
            type: "max",
            params: [100, "##FIELD_NAME## cannot be more than 100 characters"],
          },
        ],
      },
      {
        name: "Quantity",
        label: "Quantity",
        type: "number",
        validation: "Alphabetic",
        fieldId: "quantity",
        id: "quantity",
        validationType: "number",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
        ],
      },
      {
        name: "Size",
        label: "Size",
        type: "text",
        validation: "Alphabetic",
        fieldId: "size",
        id: "size",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Cost",
        label: "Cost",
        type: "number",
        validation: "Alphabetic",
        fieldId: "cost",
        id: "cost",
        validationType: "number",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
        ],
      },
      {
        name: "Issue Date",
        label: "Issue Date",
        type: "date",
        validation: "Alphabetic",
        fieldId: "issueDate",
        id: "issueDate",
        validationType: "date",
        max: MAX_DATE_STRING,
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
        ],
      },
      {
        name: "Return Date",
        label: "Return Date",
        type: "date",
        validation: "Alphabetic",
        fieldId: "returnDate",
        id: "returnDate",
        validationType: "date",
        max: MAX_DATE_STRING,
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "min",
            params: [
              yup.ref("issueDate"),
              "##FIELD_NAME## should be greater than Issue Date",
            ],
            nullable: true,
          },
        ],
      },
      {
        name: "Remarks",
        label: "Remarks",
        type: "textarea",
        validation: "Alphabetic",
        fieldId: "remarks",
        id: "remarks",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [200, "##FIELD_NAME## cannot be more than 200 characters"],
          },
        ],
      },
      {
        name: "Attachments",
        label: "Attachments",
        type: "file",
        multiple: true,
        validation: "Alphabetic",
        fieldId: "files",
        hideInTableMode: true,
        id: "files",
        hideInViewMode: true,
      },
    ],
  },
  {
    tabId: "permits",
    displayName: "Permit",
    mode: "view",
    uniqueReferenceKey: "driverPermitId",
    url: "/transports/driver/permit",
    attachmentFieldId: "driverPermitAttachmentsId",
    attachmentFieldKeyName: "driverPermitAttachments",
    formFields: [
      {
        name: "Id",
        label: "Id",
        hideInTableMode: true,
        type: "text",
        validation: "Alphabetic",
        fieldId: "driverPermitId",
        id: "driverPermitId",
      },
      {
        name: "Serial No",
        label: "Serial No",
        type: "text",
        validation: "Alphabetic",
        fieldId: "serialNo",
        id: "serialNo",
        validationType: "string",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
          },
          {
            type: "max",
            params: [10, "##FIELD_NAME## cannot be more than 10 characters"],
          },
        ],
      },
      {
        name: "Permit Name",
        label: "Permit Name",
        type: "text",
        validation: "Alphabetic",
        fieldId: "permitName",
        id: "permitName",
        validationType: "string",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Provider",
        label: "Provider",
        type: "text",
        validation: "Alphabetic",
        fieldId: "provider",
        id: "provider",
        validationType: "string",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Permit No",
        label: "Permit No",
        type: "text",
        validation: "Alphabetic",
        fieldId: "permitNo",
        id: "permitNo",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Class",
        label: "Class",
        type: "text",
        validation: "Alphabetic",
        fieldId: "permitClass",
        id: "permitClass",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Issue Date",
        label: "Issue Date",
        type: "date",
        validation: "Alphabetic",
        fieldId: "issueDate",
        id: "issueDate",
        validationType: "date",
        max: MAX_DATE_STRING,
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
        ],
      },
      {
        name: "Expiry Date",
        label: "Expiry Date",
        type: "date",
        validation: "Alphabetic",
        fieldId: "expiryDate",
        id: "expiryDate",
        validationType: "date",
        max: MAX_DATE_STRING,
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
          {
            type: "min",
            params: [
              yup.ref("issueDate"),
              "##FIELD_NAME## should be greater than Issue Date",
            ],
            nullable: true,
          },
        ],
      },
      {
        name: "Cost",
        label: "Cost",
        type: "number",
        validation: "Alphabetic",
        fieldId: "cost",
        id: "cost",
        validationType: "number",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
        ],
      },
      {
        name: "Comment",
        label: "Comment",
        type: "textarea",
        validation: "Alphabetic",
        fieldId: "comment",
        id: "comment",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [200, "##FIELD_NAME## cannot be more than 200 characters"],
          },
        ],
      },
      {
        name: "Attachments",
        label: "Attachments",
        type: "file",
        multiple: true,
        validation: "Alphabetic",
        fieldId: "files",
        id: "files",
        hideInViewMode: true,
        hideInTableMode: true,
      },
    ],
  },
  {
    tabId: "training",
    displayName: "Training",
    mode: "view",
    uniqueReferenceKey: "driverTrainingId",
    url: "/transports/driver/training",
    attachmentFieldId: "driverTrainingAttachmentsId",
    attachmentFieldKeyName: "driverTrainingAttachments",
    formFields: [
      {
        name: "Id",
        hideInTableMode: true,
        label: "Id",
        type: "text",
        validation: "Alphabetic",
        fieldId: "driverTrainingId",
        id: "driverTrainingId",
      },
      {
        name: "Certificate No",
        label: "Certificate No",
        type: "text",
        validation: "Alphabetic",
        fieldId: "certificateNo",
        id: "certificateNo",
        validationType: "string",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
          },
          {
            type: "max",
            params: [15, "##FIELD_NAME## cannot be more than 15 characters"],
          },
        ],
      },
      {
        name: "Certificate Name",
        label: "Certificate Name",
        type: "text",
        validation: "Alphabetic",
        fieldId: "certificateName",
        id: "certificateName",
        validationType: "string",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Description",
        label: "Description",
        type: "textarea",
        validation: "Alphabetic",
        fieldId: "description",
        id: "description",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Training Company",
        label: "Training Company",
        type: "text",
        validation: "Alphabetic",
        fieldId: "trainingCompany",
        id: "trainingCompany",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Training Name",
        label: "Training Name",
        type: "text",
        validation: "Alphabetic",
        fieldId: "trainerName",
        id: "trainerName",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Training Hours",
        label: "Training Hours",
        type: "text",
        validation: "Alphabetic",
        fieldId: "trainingHours",
        id: "trainingHours",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [5, "##FIELD_NAME## cannot be more than 5 characters"],
          },
        ],
      },
      {
        name: "Issue Date",
        label: "Issue Date",
        type: "date",
        validation: "Alphabetic",
        fieldId: "issueDate",
        id: "issueDate",
        validationType: "date",
        max: MAX_DATE_STRING,
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
        ],
      },
      {
        name: "Expiry Date",
        label: "Expiry Date",
        type: "date",
        validation: "Alphabetic",
        fieldId: "expiryDate",
        id: "expiryDate",
        validationType: "date",
        max: MAX_DATE_STRING,
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
          {
            type: "min",
            params: [
              yup.ref("issueDate"),
              "##FIELD_NAME## should be greater than Issue Date",
            ],
            nullable: true,
          },
        ],
      },
      {
        name: "Cost",
        label: "Cost",
        type: "number",
        validation: "Alphabetic",
        fieldId: "cost",
        id: "cost",
        validationType: "number",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
        ],
      },
      {
        name: "Comments",
        label: "Comments",
        type: "textarea",
        validation: "Alphabetic",
        fieldId: "comments",
        id: "comments",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [200, "##FIELD_NAME## cannot be more than 200 characters"],
          },
        ],
      },
      {
        name: "Attachments",
        label: "Attachments",
        type: "file",
        multiple: true,
        validation: "Alphabetic",
        fieldId: "files",
        id: "files",
        hideInViewMode: true,
        hideInTableMode: true,
      },
    ],
  },
  {
    tabId: "accidents-incidents",
    displayName: "Accidents / Incidents",
    mode: "view",
    uniqueReferenceKey: "driverIncidentId",
    url: "/transports/driver/incidents",
    attachmentFieldId: "driverIncidentAttachmentsId",
    attachmentFieldKeyName: "driverIncidentsAttachments",
    formFields: [
      {
        name: "Id",
        hideInTableMode: true,
        label: "Id",
        type: "text",
        validation: "Alphabetic",
        fieldId: "driverIncidentId",
        id: "driverIncidentId",
      },
      {
        name: "Date",
        label: "Date",
        type: "date",
        validation: "Alphabetic",
        fieldId: "date",
        id: "date",
        max: moment().format("YYYY-MM-DD"),
        validationType: "date",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
        ],
      },
      {
        name: "Location",
        label: "Location",
        type: "text",
        validation: "Alphabetic",
        fieldId: "location",
        id: "location",
        validationType: "string",
        required: true,
        validations: [
          {
            type: "required",
            params: ["##FIELD_NAME## is required"],
            nullable: true,
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Accident/Incident Description",
        label: "Accident/Incident Description",
        type: "textarea",
        validation: "Alphabetic",
        fieldId: "accidentDescription",
        id: "accidentDescription",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [100, "##FIELD_NAME## cannot be more than 100 characters"],
          },
        ],
      },
      {
        name: "Witness",
        label: "Witness",
        type: "text",
        validation: "Alphabetic",
        fieldId: "witness",
        id: "witness",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [20, "##FIELD_NAME## cannot be more than 20 characters"],
          },
        ],
      },
      {
        name: "Employee Remarks",
        label: "Employee Remarks",
        type: "textarea",
        validation: "Alphabetic",
        fieldId: "employeeRemarks",
        id: "employeeRemarks",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [100, "##FIELD_NAME## cannot be more than 100 characters"],
          },
        ],
      },
      {
        name: "Company Remarks",
        label: "Company Remarks",
        type: "textarea",
        validation: "Alphabetic",
        fieldId: "companyRemarks",
        id: "companyRemarks",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [100, "##FIELD_NAME## cannot be more than 100 characters"],
          },
        ],
      },
      {
        name: "Investigation",
        label: "Investigation",
        type: "checkbox",
        validation: "Alphabetic",
        fieldId: "investigation",
        id: "investigation",
        validationType: "string",
        hideInTableMode: true,
        validations: [
          {
            type: "optional",
            params: [],
          },
        ],
      },
      {
        name: "Investigation Date",
        label: "Investigation Date",
        type: "date",
        validation: "Alphabetic",
        fieldId: "investigationDate",
        id: "investigationDate",
        validationType: "date",
        max: MAX_DATE_STRING,
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "min",
            params: [
              yup.ref("date"),
              "##FIELD_NAME## should be greater than Start Date",
            ],
            nullable: true,
          },
        ],
      },
      {
        name: "Investigation Result",
        label: "Investigation Result",
        type: "textarea",
        validation: "Alphabetic",
        fieldId: "investigationResult",
        id: "investigationResult",
        validationType: "string",
        validations: [
          {
            type: "optional",
            params: [],
            nullable: true,
          },
          {
            type: "max",
            params: [100, "##FIELD_NAME## cannot be more than 100 characters"],
          },
        ],
      },
      {
        name: "Attachments",
        label: "Attachments",
        type: "file",
        multiple: true,
        validation: "Alphabetic",
        fieldId: "files",
        id: "files",
        hideInViewMode: true,
        hideInTableMode: true,
      },
    ],
  },
];
