import moment from "moment";

export const MAX_DATE_STRING = moment("2100-01-01").format("YYYY-MM-DD");
export const MAX_DATE_OBJECT = new Date(2100, 11, 31);

export const SUPER_ADMIN = "SUPER_ADMIN";

export const USER_MANAGEMENT = "USER_MANAGEMENT";
export const MANUAL_RUNSHEET = "MANUAL_RUNSHEET";
export const AUTO_RUNSHEET = "AUTO_RUNSHEET";
export const INDUCTION = "INDUCTION";
export const INSPECTION_REPORT = "INSPECTION_REPORT";
export const VEHICLES = "VEHICLES";
export const ENIGMA_CHAT = "ENIGMA_CHAT";
export const EXPORT_RELEASE_PLANNER = "EXPORT_RELEASE_PLANNER";
export const DRIVERS = "DRIVERS";
export const INDUCTION_LEARNING = "INDUCTION_LEARNING";
export const REPORTING_TOOL = "REPORTING_TOOL";
export const SURCHARGE_MAINTENANCE = "SURCHARGE_MAINTENANCE";
export const FINANCE_APPLICATION = "SWIFT_FINANCE";

export const userEmploymentTypeOptions = [
  { label: "Employee", value: "Employee" },
  { label: "Subcontractor", value: "Subcontractor" },
];
