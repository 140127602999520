import React from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { FaEye, FaPen } from "react-icons/fa";

import { TextBox, Button } from "../../../components/Form";
import { getData, postData } from "../../../utils/api";
import { errorToast, successToast } from "../../../utils/toastFunc";
import config from "../../../constants/config";
import "../UserManagement.scss";

const TfnTab = ({
  defaultValues,
  driverId,
  viewEditMode,
  setKey,
  className,
}) => {
  const [viewMode, setViewMode] = useState(viewEditMode); //if we want to change viewMode inside comp
  const toggleViewMode = () =>
    setViewMode((prevViewMode) => (prevViewMode === "edit" ? "view" : "edit"));

  const validationSchema = Yup.object().shape({
    annualLeave: Yup.string().required("Value is mendatory"),
    sickLeave: Yup.string().required("Value is mendatory"),
    tfnBaseHours: Yup.string().required("Value is mendatory"),
    tfnBaseRate: Yup.string().required("Value is mendatory"),
    tfnRate: Yup.string().required("Value is mendatory"),
    totalHours: Yup.string().required("Value is mendatory"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    defaultValues: { ...defaultValues },
  });
  const tfnFields = [
    {
      title: "TFN Base hours",
      fieldId: "tfnBaseHours",
      fieldType: "number",
      notesId: "tfnBaseHoursNotes",
      dateId: "tfnBaseHoursDate",
    },
    {
      title: "TFN Base Rate",
      fieldId: "tfnBaseRate",
      fieldType: "number",
      notesId: "tfnBaseRateNotes",
      dateId: "tfnBaseRateDate",
    },
    {
      title: "TFN Rate",
      fieldId: "tfnRate",
      fieldType: "number",
      notesId: "tfnRateNotes",
      dateId: "tfnRateDate",
    },
    {
      title: "Total hours",
      fieldId: "totalHours",
      fieldType: "number",
      notesId: "totalHoursNotes",
      dateId: "totalHoursDate",
    },
    {
      title: "Annual Leave",
      fieldId: "annualLeave",
      fieldType: "number",
      notesId: "annualLeaveNotes",
      dateId: "annualLeaveDate",
    },
    {
      title: "Sick Leave",
      fieldId: "sickLeave",
      fieldType: "number",
      notesId: "sickLeaveNotes",
      dateId: "sickLeaveDate",
    },
    
  ];
  const colors = { btnColor: "#435E9E" };

  const onSaveTfnDetails = async (formData) => {
    const url =
      config.baseUrl +
      config.driverTfnUrls.saveDriverTfnDetails +
      `?driverId=${driverId}`;
    let response = await postData(url, formData);
    if (response?.data) {
      successToast({ mes: "TFN Details Saved Successfully" });
      if (!viewEditMode) {

        setViewMode("view");
        const url = config.baseUrl + config.driverTfnUrls.getDriverTfnDetails + '?driverId=' + driverId;
        const resp = await getData(url);
        if (resp?.data) {
          reset(resp.data); //this sets the values from response
        }
        setKey("documents"); // moving to another tab
      }
    } else {
      // else server error handling
      errorToast({ mes: "Try Again" });
    }
  };
  return (
    <>
      <div className={` p-3 pt-5 shadow blur-bg rounded ${className}`}>
        {viewMode && !viewEditMode && (
          <button
            title="view"
            className="btn btn-primary p-1 me-1 lh-1"
            onClick={() => toggleViewMode()}
          >
            {viewMode === "edit" ? <FaEye size={20} /> : <FaPen size={20} />}
          </button>
        )}
        <form
          autoComplete="off"
          className=""
          onSubmit={handleSubmit(onSaveTfnDetails)}
        >
          <section className="row mb-5">
            <div className="col-md-12 px-5">
              {/* heading */}
              <div className="row bold-text">
                <div className="col-1 p-2 center-items">
                  <div className="d-inline-block">SN</div>
                </div>
                <div className="col-2 p-2 "></div>
                <div className="col-3 p-2">
                  <div className="d-inline-block">Hours</div>
                </div>
                <div className="col-3 p-2">
                  <div className="d-inline-block">Notes</div>
                </div>
                <div className="col-3 p-2">
                  <div className="d-inline-block">Dates</div>
                </div>
              </div>

              {/* Fields */}
              {tfnFields.map((field, index) => (
                <div className="row" key={index}>
                  <div className="col-1 p-2 center-items">
                    <div className="d-inline-block">{index + 1}</div>
                  </div>
                  <div className="col-2 p-2 ">
                    <Button
                      title={field.title}
                      color={colors.btnColor}
                      className="cursor-default"
                    />
                  </div>
                  <div className="col-3 p-2">
                    <TextBox
                      type={field.fieldType ? field.fieldType : "text"}
                      register={register}
                      disabled={viewMode === "view"}
                      errors={errors}
                      step=".01"
                      id={field.fieldId}
                    />
                  </div>
                  <div className="col-3 p-2">
                    {field?.notesId && (
                      <TextBox
                        type="text"
                        register={register}
                        placeholder="Notes"
                        disabled={viewMode === "view"}
                        errors={errors}
                        id={field.notesId}
                      />
                    )}
                  </div>
                  <div className="col-3 p-2">
                    {field?.dateId && (
                      <TextBox
                        type="date"
                        register={register}
                        placeholder="Notes"
                        disabled={viewMode === "view"}
                        errors={errors}
                        max="9999-12-31"
                        id={field.dateId}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </section>

          <div className="row mb-1 d-flex justify-content-end">
            <div className="col-md-2 px-3 ">
              {viewMode !== "view" && (
                <Button
                  title={viewMode === "edit" ? "Save" : "Submit"}
                  type="submit"
                  className="buttons"
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default TfnTab;
