import { yupResolver } from "@hookform/resolvers/yup";
import { generate } from "@pdfme/generator";
import { image, text } from "@pdfme/schemas";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import * as Yup from "yup";
import { FormField } from "../../../components/Form";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import config from "../../../constants/config";
import swiftTransportLogo from "../../../images/swift-transport-logo.png";
import { doLogout, getUserData } from "../../../rtk-slice/globalSlice";
import { postFile } from "../../../utils/api";
import { dataURLtoFile } from "../../../utils/dataURLtoFile";
import { errorToast, successToast } from "../../../utils/toastFunc";
import template from "../pdf/template.json";
import "../swiftTrafficSignup.scss";
import {
  carPark,
  container1,
  container2,
  fireext,
  footpath,
  lunchroom,
  map,
  mapAid,
  pedestrain,
  pole,
  ppe,
  toilets,
  trucksCars,
  zebracrossing,
} from "./images";

function LogoAndHeader() {
  return (
    <div className="d-flex justify-content-around align-items-center mb-5">
      <img
        src={swiftTransportLogo}
        width="250"
        height="90"
        className="d-inline-block align-top me-5"
        alt="React Bootstrap logo"
      />
      <h4 className="traffic-header">SWIFT SITE TRAFFIC MANAGEMENT PLAN</h4>
    </div>
  );
}

const validationSchema = Yup.object().shape({
  document: Yup.mixed().required("Signature is required"),
});

function SwiftTrafficPdf() {
  const signatureCanvasRef = useRef(null);
  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const driverId = userData.userContext.userId;
  const { firstName } = userData.userDetails;
  const [showFile, setShowFile] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [confirmAction, setConfirmAction] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
  });

  const clearSignature = (event) => {
    event.preventDefault();
    signatureCanvasRef.current.clear();
    setValue("document", null);
    setShowFile(null);
    setImageSrc(null);
  };

  const saveSignature = async (event) => {
    event.preventDefault();
    if (!signatureCanvasRef.current.isEmpty()) {
      const base64Png = signatureCanvasRef.current.toDataURL("image/png");
      setValue("document", base64Png);
      const signatureFile = dataURLtoFile(base64Png, "Self-Signature.png");
      setShowFile(signatureFile);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setShowFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setValue("document", base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const [isError, setIsError] = useState(null);

  useEffect(() => {
    if (errors?.document) return setIsError(errors?.document?.message);
    return setIsError(null);
  }, [errors, imageSrc]);
  useEffect(() => {
    if (showFile) {
      const url = URL.createObjectURL(showFile);
      setImageSrc(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [showFile]);

  const handleConfirm = () => {
    if (confirmAction) {
      confirmAction();
    }
    setShowConfirmModal(false);
  };

  const handleConfirmButtonClick = (action, message) => {
    setConfirmAction(() => action);
    setShowConfirmModal(true);
  };

  const onSubmit = async (data) => {
    handleConfirmButtonClick(async () => {
      setIsLoading(true);
      const url =
        config.baseUrl + config.userDocuments.uploadFile + "/" + driverId;
      const formData = new FormData();
      const plugins = { text, image };
      const inputs = [
        {
          name: `${firstName} ${userData.userDetails?.lastName}`,
          date: moment().format("DD-MM-YYYY"),
          signature: data.document,
          SignaturePlace: "Signature: .................................",
        },
      ];

      const pdf = await generate({ template, inputs, plugins });

      const blob = new Blob([pdf.buffer], { type: "application/pdf" });

      // Create a new File from the Blob
      const file = new File([blob], "traffic-mng-sign-off.pdf", {
        type: "application/pdf",
      });
      formData.append("document", file);
      const response = await postFile(url, formData);
      window.open(URL.createObjectURL(blob));

      if (response.data === "document uploaded successfully") {
        successToast({ mes: "Document Submitted" });
        dispatch(doLogout());
      } else {
        setIsLoading(false);
        errorToast({ mes: "Error Upload" });
      }
    });
  };
  return (
    <div>
      <Container className="container">
        {/* page 1 */}
        <div className="swiftTrafficLogo eachPage">
          <img
            src={swiftTransportLogo}
            width="400"
            height="250"
            className="d-inline-block align-top me-5"
            alt="React Bootstrap logo"
          />
          <h1 className="traffic-header mt-5">
            SWIFT TRAFFIC MANAGEMENT PLAN ENFIELD INTER MODAL LOGISTICS TERMINAL
          </h1>
          <h1 className="traffic-header">GATE D1 MAINLINE ROAD NSW 2136</h1>
        </div>
        {/* page 2 */}
        <div className="eachPage">
          <LogoAndHeader />
          <h3 className="traffic-header mb-4">PPE REQUIREMENTS</h3>
          <h5 className="list-header">
            Minimum PPE requirements for the site:
          </h5>
          <ul>
            <li>Hi-visibility orange vest or equivalent full body clothing</li>
            <li>Safety footwear</li>
            <li>Long sleeve shirt and pants</li>
          </ul>
          <h3 className="list-header">
            Other PPE may be required depending upon the work activities being
            undertaken:
          </h3>
          <h4 className="my-5">
            Note: Contractors may need to provide their own PPE
          </h4>
          <div className="d-flex justify-content-center">
            <img src={ppe} width="90%" height={550} alt="PPE" />
          </div>
        </div>
        {/* page 3 */}
        <div className="eachPage">
          <LogoAndHeader />
          <h3 className="traffic-header mb-4">Site Map – Emergency Response</h3>
          <ul>
            <li>
              If an evacuation is required, you will be directed to one of the
              emergency assembly points on the map below
            </li>
            <li>
              Once at the point, you will receive further instruction. Wardens
              will be on hand to assist or direct you as required
            </li>
            <li>
              There are 2 Evacuation Assembly points on site:
              <ul>
                <li>
                  South End Assembly Point – Outside Gate D1 on the right side
                  of Driveway
                </li>
                <li>
                  North End Assembly Point - Outside Gate D2 on the right side
                  of Driveway
                </li>
              </ul>
            </li>
          </ul>
          <div className="d-flex justify-content-center">
            <img src={map} width="90%" height={750} alt="PPE" />
          </div>
        </div>
        {/* page 4 */}
        <div className="eachPage">
          <LogoAndHeader />
          <ul>
            <li>
              There are First Aid Facilities and trained First Aid Officers
              onsite:
            </li>
            <li>The First Aid Kits are in the Office and the Gatehouse</li>
            <li>The defibrillator is in the office and Gatehouse</li>
            <li>
              If you require First Aid, please contact your manager or Site
              Contact. We will manage your injury and obtain additional
              treatment
            </li>
          </ul>
          <div className="d-flex justify-content-center">
            <img src={mapAid} width="90%" height={650} alt="PPE" />
          </div>
          <h5 className="list-header mt-5 mb-3">
            The map below indicates traffic management requirements:
          </h5>
          <ul>
            <li>Travel in one way direction as marked and posted</li>
            <li>Speed limit within the terminal is 10 km/hr</li>
            <li>
              Hazard lights must be switched on at entry and continue being used
              when driving through site
            </li>
            <li>
              Cars to park in designated area. Visitors to use intercom to
              reception to open boom gate
            </li>
          </ul>
        </div>
        {/* page 5 */}
        <div className="eachPage">
          <LogoAndHeader />
          <div className="mx-5">
            <img src={carPark} width={400} height={350} alt="PPE" />
          </div>
          <ul>
            <li>Give way to forklifts near operating at the warehouse area</li>
            <li>Use the designated pinning/unpinning areas provided</li>
            <li>
              Pedestrians use designated walkways at all times and give way to
              vehicles and mobile equipment
            </li>
          </ul>
          <div className="d-flex justify-content-around mb-4">
            <img src={pole} width="45%" height={350} alt="PPE" />
            <img src={footpath} width="45%" height={350} alt="PPE" />
          </div>
          <ul>
            <li>
              Mobile phone use not permitted while driving vehicles/operating
              mobile plant unless using hands free
            </li>
            <li>
              After parking your vehicle in designated parking area make your
              way to the Main office to Reception to sign in to the site and
              make your presence known
            </li>
            <li>
              Sign in and complete site induction for site and wait for
              designated Swift personnel to accompany visitors while on site
            </li>
            <li>
              All Visitors must be accompanied by a Swift employee or StaƯ
              whilst on site
            </li>
          </ul>
        </div>
        {/* page 6 */}
        <div className="eachPage">
          <LogoAndHeader />
          <div className="d-flex justify-content-around mb-4">
            <img src={zebracrossing} width="45%" height={350} alt="PPE" />
            <img src={fireext} width="45%" height={350} alt="PPE" />
          </div>
          <ul>
            <li>
              The café, toilet amenities and lunchroom are located directly
              opposite the Main office.
            </li>
          </ul>
          <div className="d-flex justify-content-around my-4">
            <img src={footpath} width="45%" height={350} alt="PPE" />
            <img src={toilets} width="45%" height={350} alt="PPE" />
          </div>
          <div className="d-flex justify-content-around my-5">
            <img src={lunchroom} width="45%" height={350} alt="PPE" />
          </div>
          <ul>
            <li>
              Stay within the barriers from the trucks in the truck parking area
              and truck routes to exit the yard.
            </li>
          </ul>
          <div className="d-flex justify-content-around mb-4">
            <img src={container1} width="45%" height={350} alt="container1" />
            <img src={container2} width="45%" height={350} alt="container2" />
          </div>
        </div>
        {/* page 7 */}
        <div className="eachPage">
          <LogoAndHeader />
          <h5 className="list-header">Swift Pedestrian Map</h5>
          <h4 className="my-4">
            Pedestrian Crossing in front of Café to truck and trailers parking
            area
          </h4>
          <div className="d-flex justify-content-center">
            <img src={pedestrain} width="90%" height={750} alt="PPE" />
          </div>
        </div>
        {/* page 8 */}
        <div className="eachPage">
          <LogoAndHeader />
          <h5 className="list-header">Site Specific Rules:</h5>
          <ul>
            <li>
              Be aware of trucks when entering and exiting through Gate D1 and
              D2
            </li>
            <li>Smoking – Only permitted on site in signed designated area</li>
            <li>
              Drug and Alcohol – SWIFT has a 0.0 Breath Alcohol reading and zero
              tolerance to illicit drug use
            </li>
            <li>No unauthorized persons to enter the Warehouse area</li>
            <li>
              Do not block the driveways at Gate D1 and Gate D2 to ensure to not
              block Mainline Road
            </li>
          </ul>
          <h5 className="list-header">Drivers Code of Conduct</h5>
          <h5>
            For the safe operation of the IMT, all drivers must read and comply
            with the following Drivers Code of Conduct.
          </h5>
          <h4 className="">All drivers must:</h4>
          <ol>
            <li>
              Hold a current and valid driver’s license for the class of vehicle
              that they operate.
            </li>
            <li>
              Operate a registered and roadworthy vehicle in accordance with the
              relevant vehicle standards regulations and laws.
            </li>
            <li>
              Operate on the road system in a safe and professional manner, with
              consideration for all road users and weather conditions.
            </li>
            <li>
              Comply with NSW road traffic regulations within the ILC; adhere to
              all Parking and TraƯic Controls (including load limits) on the
              public and internal roads. Be aware that the ILC internal roads
              are subject to the NSW Road Rules and therefore, the normal fine
              and penalties apply to breaches of the road rules
            </li>
            <li>
              Comply with any directions made by authorized officers of NSW
              Ports, Roads and Maritime Services, Stratified Municipal Council,
              City of Canterbury Banks town Council, Police or other authorized
              parties.
            </li>
            <li>
              Be mindful of pedestrians and cyclists accessing the internal road
              areas and marked road crossings.
            </li>
            <li>
              Not obstruct access to any public roads, internal roads or
              pedestrian corridors, without the prior approval of NSW Ports or
              relevant regulatory body.
            </li>
          </ol>
        </div>
        {/* page 9 */}
        <div className="eachPage">
          <LogoAndHeader />
          <div className="d-flex justify-content-center">
            <img src={trucksCars} width="90%" height={750} alt="trucksCars" />
          </div>
        </div>
        {/* page 10 */}
        <div className="eachPage">
          <LogoAndHeader />
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="no-col">No.</th>
                <th className="items-covered-col">Items Covered</th>
                <th className="initial-name-col">Initial Name</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  Are you aware that when in the Yard the correct Safety Attire
                  is: Safety Footwear, Long Sleeved Shirt, High Visibility Vest
                </td>
                <td></td>
              </tr>
              <tr>
                <td>2</td>
                <td>
                  Are you aware of what to do in an Emergency and the location
                  of the Assembly point and evacuation route, Closest Medical
                  Facility, Contact details of emergency services, Provisions
                  for emergency communications?
                </td>
                <td></td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  Are you aware of the location of the First Aid
                  facilities/Kits/Defibrillator, Who the First Aiders are and
                  how to obtain treatment?
                </td>
                <td></td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  Are you aware where all relevant firefighting equipment is
                  located? For example, fire extinguishers, hose reel, spill
                  kits, etc.
                </td>
                <td></td>
              </tr>
              <tr>
                <td>5</td>
                <td>
                  Do you know who your Health and Safety Representative(s) is?
                </td>
                <td></td>
              </tr>
              <tr>
                <td>6</td>
                <td>
                  Do you know where the amenities (including toilets and
                  drinking water) are located?
                </td>
                <td></td>
              </tr>
              <tr>
                <td>7</td>
                <td>
                  Do you know who you report incidents, injuries, and hazards
                  to?
                </td>
                <td></td>
              </tr>
              <tr>
                <td>8</td>
                <td>Have you a copy of the Site Traffic Management Plan?</td>
                <td></td>
              </tr>
              <tr>
                <td>9</td>
                <td>
                  Are you aware of all the pedestrian walkways and car park in
                  the Yard?
                </td>
                <td></td>
              </tr>
              <tr>
                <td>10</td>
                <td>
                  Are you aware that any Visitors on site must sign in at
                  Reception and must be always accompanied by a Swift
                  representative?
                </td>
                <td></td>
              </tr>
              <tr>
                <td>11</td>
                <td>Are you aware of the Yard Speed limit and traffic flow?</td>
                <td></td>
              </tr>
              <tr>
                <td>12</td>
                <td>
                  Are you aware that all containers' pins MUST be locked in and
                  the container checked prior to leaving the yard?
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <h4 className="mt-5">
            Note: Driver initialling each line, acknowledges that they have read
            and been instructed and participated in the associated tasks and
            will demonstrate their ability to complete and adhere the tasks
            safely.
          </h4>
          <h4>
            I acknowledge that I have read and understand the Swift Traffic
            Management Plan:
          </h4>
          <div className="d-flex gap-5 mt-5">
            <div className="w-100">
              <FormField
                className="Field_label"
                title="First Name"
                id="firstName"
                ratio={[2, 10]}
              >
                <input
                  type="text"
                  className="form-control"
                  value={firstName}
                  disabled
                />
              </FormField>
            </div>
            <div className="w-100">
              <FormField
                className="Field_label"
                title="Last Name"
                id="lastName"
                ratio={[2, 10]}
              >
                <input
                  type="text"
                  className="form-control"
                  value={userData.userDetails?.lastName}
                  disabled
                />
              </FormField>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="my-3">
              <FormField
                className="Field_label"
                title="Digital Signature"
                required
                id="signature"
                ratio={[12, 12]}
              >
                <SignatureCanvas
                  ref={signatureCanvasRef}
                  penColor="black"
                  canvasProps={{
                    height: 200,
                    className: `signature-canvas ${
                      errors?.signature?.message ? "is-invalid" : ""
                    }`,
                  }}
                />
                <div className="signature-btn">
                  <button
                    onClick={clearSignature}
                    className="form-control btn btn-outline-dark"
                  >
                    Clear Signature
                  </button>
                  <Button onClick={saveSignature} className="form-control">
                    Save Signature
                  </Button>
                </div>
              </FormField>
              <p>or</p>
              <FormField
                className="Field_label"
                title="Upload Signature Image"
                id="signatureFile"
                ratio={[12, 12]}
              >
                <input
                  type="file"
                  id="signatureFile"
                  accept="image/*"
                  className="form-control"
                  onChange={handleFileChange}
                />
              </FormField>
              {imageSrc && (
                <>
                  <FormField
                    className="Field_label"
                    title="Preview"
                    id="Preview"
                    ratio={[12, 12]}
                  >
                    <img
                      src={imageSrc}
                      width="100%"
                      height={300}
                      alt="Signature Preview"
                    />
                  </FormField>
                </>
              )}
              {isError && <p className="text-danger">{isError}</p>}
            </div>
            <div className="d-flex justify-content-center mt-5">
              <button
                type="submit"
                className="credit-form-btn py-2"
                disabled={isLoading}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Container>
      <ConfirmationModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        onConfirm={handleConfirm}
        message="Are you sure you want to submit this file?"
      />
    </div>
  );
}

export default SwiftTrafficPdf;
