import React from 'react';
import "./CustomPopUp.css";

function CustomPopup({ isOpen, onClose, height, width, children }) {
  return isOpen ? (
    <div className="custom-popup">
      <div className="popup-content" style={{height: {height}, width: {width}}}>
        <div className="close-container">
            <button className="close-button" onClick={onClose}>
            &times;
            </button>
        </div>
        {children}
      </div>
    </div>
  ) : null;
}

export default CustomPopup;