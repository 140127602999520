import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { fetchService } from "../../../utils/api";
import GroupByColumnPie from "./GroupByColumnPie";
import { Spinner } from "react-bootstrap";
import { formatDate } from "../../../utils";

export default function VehiclesTypeChart() {
  const [date, setDate] = useState("weekBefore");

  const {
    data: queryData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["vehicleTypeDetails", date],
    queryFn: async () =>
      await fetchService({
        url: `/dashboard/driver/vehicle-type-details?start-date=${formatDate(
          date
        )}`,
        method: "POST",
        body: JSON.stringify({}),
      }),
    staleTime: 60000 * 15,
  });

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner
          style={{ width: "36px", height: "36px" }}
          animation="border"
          variant="primary"
        />
      </div>
    );
  }

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  const totalDriversCount = queryData.data.reduce(
    (acc, { countOfDrivers }) => acc + countOfDrivers,
    0
  );

  return (
    <div className="chart-container-400 card w-100 shadow-sm">
      <select
        className="chart-select"
        defaultValue={date}
        onChange={(e) => setDate(e.target.value)}
      >
        <option value="weekBefore">Last Week</option>
        <option value="monthBefore">Last Month</option>
        <option value="sixMonthsBefore">Last 6 Months</option>
        <option value="yearBefore">Last Year</option>
      </select>
      <h6 className="card-subtitle chart-subtitle">Drivers and vehicle type</h6>
      {Array.isArray(queryData.data) && totalDriversCount === 0 ? (
        <div className="chart-noDataFound-container">No Drivers found</div>
      ) : (
        <GroupByColumnPie
          data={queryData.data}
          id="vehicleTypeName"
          value="countOfDrivers"
          theme="paired"
        />
      )}
    </div>
  );
}
