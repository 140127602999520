import React, { lazy, Suspense } from "react";
import { useParams } from "react-router-dom";

const ViewEditUser = lazy(() => import("./ViewEditUser"));

export default function DriversOperations() {
  const { viewMode, userId } = useParams();
  return (
    <Suspense fallback="Loading...">
      <ViewEditUser viewMode={viewMode} userId={userId} />
    </Suspense>
  );
}
