import React, { useContext, useEffect, useState } from "react";
import { TbPresentationAnalytics } from "react-icons/tb";
import "./Sidebar.css";
import SidebarComponent from "./SidebarComponent";
import { useLocation, useNavigate } from "react-router-dom";
import SearchComponent from "./SearchComponent";
import { INDUCTION_LEARNING_SCREENS } from "../../constants/routes";
import { OnTapContext, TrainingsContext } from "../../context/TrainingsContext";

const Sidebar = (props) => {

  const [isSearch, setIsSearch] = useState(props.isSearch);
  const handleClick = () => {
    setIsSearch(!isSearch);
  };
  const { data } = useContext(TrainingsContext);
  const { onTap, setOnTap } = useContext(OnTapContext);
  const navigate = useNavigate();
  const location = useLocation();
  let activeBtn;
  if (location.state !== null && location.state.onTap != null) {
    activeBtn = location.state.onTap.id;
  }


  const handleOnClick = (value) => {
    setOnTap(value);
  }

  useEffect(() => {
    if (onTap !== null) {
      if (activeBtn !== onTap.id) {
        navigate(INDUCTION_LEARNING_SCREENS, { state: { onTap } });
      }
    }
  }, [onTap]);

  return (
    <div className="sidebar">
      <div className="sidebar-expand" onClick={handleClick} style={{
        display: "flex",
        alignItems: "center",
        padding: "14px",
        maxWidth: "286px",
        marginTop: "10px",
        marginBottom: "10px",
      }}>
        <div style={{ marginRight: "34px" }}>
          <TbPresentationAnalytics style={{ zoom: "2" }} />
        </div>
        Driver's Analytics
      </div>
      {isSearch ? (
        <>
          <SearchComponent />
        </>
      ) : (
        <div className="scroll-sidebar">
          {
            data.length != 0 && data.map((value) => {
                const iconName = value.imageUrl
                const activeButtonName = value.id;


                return <div key={value.id} onClick={() => handleOnClick(value)}>
                  <SidebarComponent
                    icon={iconName}
                    name={value.trainingName}
                    activeBtn={activeButtonName === activeBtn ? true : false} />
                </div>
              })
          }
        </div>
      )}
    </div>
  );
};

export default Sidebar;