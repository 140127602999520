import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import isUndefined from "lodash/isUndefined";
import config from "../constants/config";
import { fetchService } from "../utils/api";
import { errorToast } from "../utils/toastFunc";
import startCase from "lodash/startCase";

const { allFiles, document, deleteRow } = config.exportReleasePlanner;

const initialState = {
  files: [],
  loading: false,
  document: null,
  selectedRow: null,
  fields: null,
  error: null,
};

export const getAllFiles = createAsyncThunk("export/getAllFiles", () =>
  fetchService({ url: allFiles }).then((response) => {
    if (response.status === 200 && response.data) {
      return response.data;
    } else if (response.status === 200 && isUndefined(response.data)) {
      return [];
    }
  })
);

export const getDocument = createAsyncThunk(
  "export/getDocumentToView",
  async ({ action, id }) => {
    const url = `${document}${action}?exportReleasePlannerId=${id}`;
    const fetchConfig = {
      url,
    };
    if (action === "write") fetchConfig.method = "POST";
    return fetchService(fetchConfig).then((response) => {
      if (response.status === 200 && response.data) {
        const res = { ...response.data };
        res.rowsData = JSON.parse(response.data.rowsData);
        return res;
      } else if (response.status === 200 && isUndefined(response.data)) {
        return null;
      }
    });
  }
);

export const closeDocument = createAsyncThunk("export/closeDocument", (id) =>
  fetchService({
    url: `${document}close?exportReleasePlannerId=${id}`,
    method: "POST",
  }).then((response) => {
    if (response.status === 200) {
      return response;
    }
  })
);

export const deleteDocument = createAsyncThunk(
  "export/deleteDocument",
  ({ id, index }) =>
    fetchService({
      url: `${document}delete?exportReleasePlannerId=${id}`,
      method: "DELETE",
    }).then((response) => {
      if (response.status === 200) {
        return index;
      } else {
        throw new Error("Some Error occurred");
      }
    })
);

export const deleteDocumentsRow = createAsyncThunk(
  "export/deleteRow",
  ({ index, rowNum, id }) =>
    fetchService({
      url: deleteRow(rowNum, id),
      method: "DELETE",
    }).then((response) => {
      if (response.status === 200) {
        return index;
      }
    })
);

const getFields = (state) => {
  let fields = null;
  if (state.document.headerNames.length > 0) {
    const metaDataKeys = [
      "rowNum",
      "createdBy",
      "createdDate",
      "lastModifiedBy",
      "lastModifiedDate",
    ];
    fields = [];
    state.document.headerNames.forEach((field) => {
      if (!metaDataKeys.includes(field)) {
        const name = startCase(field);
        fields.push({
          name,
          label: name,
          type: "text",
          fieldId: field,
          id: field,
          validationType: "string",
          validation: [
            {
              type: "max",
              params: [50, "##FIELD_NAME## cannot be more than 50 characters"],
            },
          ],
        });
      }
    });
  }
  return fields;
};

export const exportReleasePlannerSlice = createSlice({
  name: "export",
  initialState,
  reducers: {
    setDocument: (state, action) => {
      state.document = action.payload;
      if (action.payload) state.fields = getFields(state);
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllFiles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllFiles.fulfilled, (state, action) => {
      if (action.payload.length > 0) state.files = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllFiles.rejected, (state, action) => {
      state.error = "Unable to load files";
      state.loading = false;
    });
    builder.addCase(getDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDocument.fulfilled, (state, action) => {
      state.document = action.payload;
      state.loading = false;
      // Set documents fields
      state.fields = getFields(state);
    });
    builder.addCase(getDocument.rejected, (state, action) => {
      errorToast({ mes: state.error });
      state.loading = false;
    });
    builder.addCase(closeDocument.fulfilled, (state) => {
      state.document = null;
      state.loading = false;
    });
    builder.addCase(closeDocument.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(closeDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteDocument.fulfilled, (state, action) => {
      const index = action.payload;
      state.files.splice(index, 1);
      state.loading = false;
    });
    builder.addCase(deleteDocument.rejected, (state, action) => {
      errorToast({ mes: "Unable to delete document" });
      state.loading = false;
    });
    builder.addCase(deleteDocument.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteDocumentsRow.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteDocumentsRow.fulfilled, (state, action) => {
      state.loading = false;
      const index = action.payload;
      state.document.rowsData.splice(index, 1);
    });
    builder.addCase(deleteDocumentsRow.rejected, (state, action) => {
      errorToast({ mes: "Error deleting the row" });
      state.loading = false;
    });
  },
});

export const selectExportFiles = (state) => state.export.files;
export const selectExportDocument = (state) => state.export.document;
export const selectSelectedDocumentRow = (state) => state.export.selectedRow;
export const selectDocumentFormFields = (state) => state.export.fields;

export const { setDocument, setSelectedRow } =
  exportReleasePlannerSlice.actions;

export default exportReleasePlannerSlice.reducer;
