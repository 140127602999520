import React from 'react';
import './McqPopUp.css';

const McqProgressPopUp = (props) => {

   const optionStyle = {
      marginLeft: "20px",
      fontWeight: "400",
   };

   const answerStyle = {
      marginLeft: "20px",
      fontWeight: "700"
   };

   const mcqRightOrWrong = (currentOption, choiceOpted, correctAnswer) => {
      if (currentOption === correctAnswer && currentOption == choiceOpted) {
         return true;
      } else if (currentOption === choiceOpted && choiceOpted !== correctAnswer) {
         return false;
      } else {
         return null;
      }
   }

   return (
      <div>
         <div
            style={{
               color: "white",
               fontWeight: "700",
               fontSize: "28px",
               margin: "15px",
               display: "flex",
               justifyContent: "center",
               alignContent: "center"
            }}
         >
            MCQ
         </div>

         <div className='scrollable-container'>
            <div className='scrollable-container-child' style={{ color: "black" }}>
               {
                  props.mcq != [] && props.mcq != null
                     ? props.mcq.map((mcq) => {
                        let output = "You chose the right option ✅";
                        let option1 = mcqRightOrWrong("1", mcq.choiceOpted, mcq.correctAnswer);
                        let option2 = mcqRightOrWrong("2", mcq.choiceOpted, mcq.correctAnswer);
                        let option3 = mcqRightOrWrong("3", mcq.choiceOpted, mcq.correctAnswer);
                        let option4 = mcqRightOrWrong("4", mcq.choiceOpted, mcq.correctAnswer);
                        if (option1 === false || option2 === false || option3 === false || option4 === false) {
                           output = "Sorry, wrong option was selected ❌";
                        }
                        if (option1 === null && option2 === null && option3 === null && option4 === null) {
                           output = "No option was selected❕";
                        }

                        return <div key={mcq.id}>
                           <h5 style={{ fontWeight: "700" }}>
                              Q. {mcq.question}
                           </h5>
                           <p style={optionStyle} className={option1 === true ? 'option-right' : option1 === false ? 'option-wrong' : null}>1. {mcq.option1}</p>
                           <p style={optionStyle} className={option2 === true ? 'option-right' : option2 === false ? 'option-wrong' : null}>2. {mcq.option2}</p>
                           <p style={optionStyle} className={option3 === true ? 'option-right' : option3 === false ? 'option-wrong' : null}>3. {mcq.option3}</p>
                           <p style={optionStyle} className={option4 === true ? 'option-right' : option4 === false ? 'option-wrong' : null}>4. {mcq.option4}</p>
                           <p style={answerStyle}>{output}</p>
                           <br />
                        </div>
                     }) : <div>
                        <h4 style={{ color: "black" }}>No Mcq to show.</h4>
                     </div>
               }
            </div>
         </div>
      </div>
   );
}

export default McqProgressPopUp;
