import React from 'react';
import "./Card.css";


const Card = (props) => {

  return (
    <div className='induction-learning-card'>

<img src={props.icon} alt='icon' width="64px" className='induction-learning-card-img' />
      {props.name}

    </div>
  );
}

export default Card;