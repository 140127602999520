import { toast } from "react-toastify"

export const successToast = ({ mes, ...props }) => {
    toast.success(mes, {...props})
}
export const errorToast = ({ mes = "Something went wrong", ...props }) => {
    toast.error(mes, { ...props })
}
export const infoToast = ({ mes, ...props }) => {
    toast.info(mes, { ...props })
}
export const warningToast = ({ mes, ...props }) => {
    toast.warning(mes, { ...props })
}

