import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Button, FormField, TextBox } from "../../../components/Form";
import config from "../../../constants/config";
import * as Navigation from "../../../constants/routes";
import logoImage from "../../../images/swift-transport-logo.png";
import { doLoginWithOtp, getUserData } from "../../../rtk-slice/globalSlice";
import { postData } from "../../../utils/api";
import {
  errorToast,
  successToast,
  warningToast,
} from "../../../utils/toastFunc";

function LoginForDriver() {
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [disableOtp, setDisableOtp] = useState(true);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const timer = 20; //cooldown timer in seconds
  const [sendAgainTimer, setSendAgainTimer] = useState(timer);
  const userData = useSelector(getUserData);
  const dispatch = useDispatch();
  const host = config.hostsEnvironment[window.location.host];
  const isDevelopment = host === "dev" || host === "local";

  useEffect(() => {
    if (userData && userData.success) {
      console.log("driver");
    } else if (userData && !userData.success) {
      setShowErrorMsg(true);
    }
  }, [userData]);

  const validationSchemaOtp = Yup.object().shape({
    phoneNumber: Yup.string()
      .required("Phone is required")
      .test("phoneNumber", "phone no. should be 9 digits", (value) => {
        if (isDevelopment) return true; // no validation in development mode
        return value.length === 9;
      }),
    otp:
      !disableOtp &&
      Yup.string()
        .required("Otp is required")
        .max(6, "OTP should be 6 digits")
        .min(6, "OTP should be 6 digits"),
  });
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemaOtp),
    reValidateMode: "onChange",
    defaultValues: { phoneNumber: "" },
  });

  const onSubmitFunction = async (formData) => {
    setShowErrorMsg(false);
    const { otp, phoneNumber } = formData;

    const formattedPhoneNumber = isDevelopment
      ? phoneNumber
      : `+610${phoneNumber}`;

    dispatch(doLoginWithOtp({ otp, phoneNumber: formattedPhoneNumber }));
  };

  const sendOTP = async (mobile) => {
    setIsOtpSent(true);
    if (!mobile) {
      return warningToast({ mes: "Enter Phone number" });
    }
    if (mobile.length < 9) {
      return warningToast({ mes: "Enter 9 digit Phone number" });
    }
    const formattedMobile = isDevelopment ? mobile : `+610${mobile}`;
    const url = `${config.baseUrl}${config.sendOtp}?phoneNumber=${formattedMobile}`;
    const response = await postData(url, {});
    if (response.success) {
      successToast({ mes: "OTP sent successfully" });
      setIsOtpSent(true);
      setDisableOtp(false);
      const intervalId = setInterval(
        () => setSendAgainTimer((time) => time - 1),
        1000
      );
      setTimeout(() => {
        setIsOtpSent(false);
        setSendAgainTimer(timer);
        clearInterval(intervalId);
      }, timer * 1000);
    } else {
      setIsOtpSent(false);
      errorToast({ mes: response.message });
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-page container-fluid">
        <div className="login-container backdrop-blur container-fluid">
          <div className="row center-items">
            <div className="col-md-12 text-center ">
              <div className="logo-wpr h-25">
                <img src={logoImage} alt="logo" className="logo-img" />
              </div>
            </div>
            <div className="login-page-logo text-center w-100">
              <h3 className=" text-white fs-3">Track Mile - Driver</h3>
            </div>

            <div className="col-md-12 text-center">
              <form
                autoComplete="none"
                onSubmit={handleSubmit(onSubmitFunction)}
              >
                <div className="input mb-1 row">
                  <label
                    htmlFor="phoneNumber"
                    className={`col-sm-1 col-form-label text-white`}
                  >
                    {`Phone`}
                  </label>

                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-8 mb-1">
                        <div className="input-group">
                          {isDevelopment ? (
                            <TextBox
                              register={register}
                              id="phoneNumber"
                              errors={errors}
                              placeholder="Phone"
                              type="tel"
                              icon="true"
                            />
                          ) : (
                            <>
                              <span className="input-group-text" id="phoneNo">
                                +610
                              </span>
                              <TextBox
                                register={register}
                                id="phoneNumber"
                                errors={errors}
                                placeholder="Phone"
                                maxLength="9"
                                type="tel"
                                icon="true"
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <Button
                          color="rgba(0, 43, 85, 1)"
                          disabled={
                            watch("phoneNumber")?.length < 9 || isOtpSent
                          }
                          className="buttons"
                          onClick={() => sendOTP(getValues("phoneNumber"))}
                        >
                          Send OTP {isOtpSent && `(${sendAgainTimer})`}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input">
                  <FormField
                    title="OTP"
                    id="otp"
                    ratio={[1, 12]}
                    className="text-white"
                  >
                    <TextBox
                      register={register}
                      id="otp"
                      errors={errors}
                      placeholder="otp"
                      maxLength="30"
                      disabled={disableOtp}
                      type="number"
                      icon="true"
                    />
                  </FormField>
                </div>

                <div className="col-md-12">
                  {showErrorMsg && (
                    <p className="text-danger">Please check your credentials</p>
                  )}
                </div>

                <div className="col-md-12 text-center mt-2">
                  <div className="row justify-content-end">
                    <div className="col-4">
                      <Link to={Navigation.PRIVACY_POLICY}>
                        <p className=" text-decoration-none text-secondary text-end fs-6">
                          Privacy Policy
                        </p>
                      </Link>
                    </div>
                  </div>
                  <Button
                    color="rgba(0, 43, 85, 1)"
                    className="btn btn-primary login-button mb-1"
                    type="submit"
                    disabled={disableOtp}
                    title="VERIFY"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginForDriver;
