// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/bar
import { useQuery } from "@tanstack/react-query";
import { fetchService } from "../../../utils/api";
import { Spinner } from "react-bootstrap";
import { ResponsiveLine } from "@nivo/line";
import { useState } from "react";
import { formatDate } from "../../../utils";
import moment from "moment";

const LineGraphWithOptions = ({
  title,
  url,
  className = "chart-container-400",
  value,
}) => {
  const [date, setDate] = useState("weekBefore");
  const [precision, setPrecision] = useState("every 1 day");

  const {
    data: queryData,
    isLoading,
    error,
  } = useQuery({
    queryKey: [url, date],
    queryFn: async () =>
      await fetchService({
        url,
        method: "POST",
        body: JSON.stringify({
          endDate: moment().format("YYYY-MM-DD"),
          periodType: "DAY",
          startDate: formatDate(date),
        }),
      }),
  });

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner
          style={{ width: "36px", height: "36px" }}
          animation="border"
          variant="primary"
        />
      </div>
    );
  }

  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  return (
    <div className={`${className} card w-100 shadow-sm`}>
      <select
        className="chart-select"
        defaultValue={date}
        onChange={(e) => setDate(e.target.value)}
      >
        <option value="weekBefore">Last Week</option>
        <option value="monthBefore">Last Month</option>
        <option value="sixMonthsBefore">Last 6 Months</option>
        <option value="yearBefore">Last Year</option>
      </select>
      <select
        className="chart-select-precision"
        defaultValue={precision}
        onChange={(e) => setPrecision(e.target.value)}
      >
        <option value="every 1 day">Every 1 day</option>
        <option value="every 6 day">Every 6 day</option>
        <option value="every 15 day">Every 15 day</option>
        <option value="every 1 month">Every 1 month</option>
        <option value="every 3 month">Every 3 month</option>
      </select>
      <h6 className="card-subtitle chart-subtitle">{title}</h6>
      {Array.isArray(queryData.data) && queryData.data.length > 0 ? (
        <ResponsiveLine
          data={[
            {
              id: "jobsDone",
              data: queryData.data.map(({ startLocalDate, [value]: y }) => ({
                x: startLocalDate,
                y,
              })),
            },
          ]}
          margin={{ top: 50, right: 110, bottom: 70, left: 60 }}
          xScale={{
            format: "%Y-%m-%d",
            precision: "day",
            type: "time",
            useUTC: false,
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            // nice: true,
            stacked: true,
            reverse: false,
            // clamp: true,
          }}
          // yFormat=" >-.2f"
          // curve="basis"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            // tickPadding: 5,
            format: (value) => {
              const date = new Date(value);
              switch (precision) {
                case "every 1 day":
                  return moment(date).format("MMM-ddd");
                case "every 6 day":
                case "every 15 day":
                  return moment(date).format("MM-DD");
                case "every 1 month":
                  return moment(date).format("YYYY-MM-DD");
                case "every 3 month":
                  return moment(date).format("YYYY-MM");
                default:
                  return moment(date).format("YYYY-MM");
              }
            },
            tickRotation: 40,
            tickValues: precision,
            legend: "Time Scale",
            legendOffset: 50,
            legendPosition: "middle",
          }}
          colors={{ scheme: "set1" }}
          lineWidth={3}
          enablePoints={false}
          enableGridX={false}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh
          legends={[]}
          animate={false}
        />
      ) : (
        <div className="chart-noDataFound-container">No Drivers found</div>
      )}
    </div>
  );
};

export default LineGraphWithOptions;
