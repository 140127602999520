import React from "react";
import "../SwiftTrafficSignup/swiftTrafficSignup.scss";
import SafetyAlertPdf from "./components/SafetyAlert";
import { useSelector } from "react-redux";
import { getUserData } from "../../rtk-slice/globalSlice";
import LoginForDriver from "../SwiftTrafficSignup/components/LoginForDriver";

function SafetyAlert() {
  const userData = useSelector(getUserData);
  return (
    <div className="swift-traffic">
      {userData && userData.success ? <SafetyAlertPdf /> : <LoginForDriver />}
    </div>
  );
}

export default SafetyAlert;
