import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FcDownload } from "react-icons/fc";
import config from "../../../constants/config";
import {
  downloadFileFromBlob,
  fetchService,
  getData
} from "../../../utils/api";
import { errorToast } from "../../../utils/toastFunc";

const Document = ({ name, url }) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    fetchService({
      method: "GET",
      url: config.downloadInductionDoc + url,
      isFile: true,
    })
      .then((response) => {
        if (response.status === 200) return response.blob();
        else errorToast({ mes: "Error downloading the file" });
      })
      .then((stream) => {
        downloadFileFromBlob(stream, url);
      })
      .catch((error) => {
        errorToast({
          mes: error?.message?.split?.(":")?.[1] ?? "Something went wrong!",
        });
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <li>
      <div className="d-flex mt-3">
        {name}
        {url ? (
          <button
            onClick={handleDownload}
            className="btn p-0 ms-2"
            style={{ border: "none" }}
            disabled={loading}
          >
            {loading ? (
              <Spinner
                style={{ width: "16px", height: "16px" }}
                animation="border"
                variant="primary"
              />
            ) : (
              <FcDownload />
            )}
          </button>
        ) : (
          <span className="ms-2 text-primary">N/A</span>
        )}
      </div>
    </li>
  );
};

export default function InductionDocuments({
  driverId,
  inductionDocs,
  setInductionDocs,
}) {
  useEffect(() => {
    const getAllInductionDocs = async (id) => {
      const url = config.baseUrl + config.getInductionAllDocs + id;
      try {
        const response = await getData(url);
        if (response.status === 200) {
          setInductionDocs(response.data);
        }
      } catch (error) {
        console.log(error);
        setInductionDocs("Error");
      }
    };

    if (driverId && inductionDocs === null) {
      getAllInductionDocs(driverId);
    }
  }, [driverId, inductionDocs, setInductionDocs]);

  if (inductionDocs === null) {
    return (
      <div className="p-3">
        <Spinner
          className="d-flex flex-column"
          animation="border"
          variant="primary"
        />
      </div>
    );
  }

  if (inductionDocs === "Error") {
    return <div className="p-3">Something went wrong</div>;
  }

  return (
    <div className="p-3 w-100">
      <ol>
        <Document
          name="Sub-contractor Form"
          url={inductionDocs?.subcontractorFormUrl}
        />
        <Document name="License Back" url={inductionDocs?.abnUrl} />
        <Document
          name="Driver Assessment"
          url={inductionDocs?.driverAssessmentUrl}
        />
        <Document name="MSIC" url={inductionDocs?.msicUrl} />
        <Document name="Passport" url={inductionDocs?.passportUrl} />
        <Document name="Medical" url={inductionDocs?.medicalUrl} />
        <Document
          name="Driving License"
          url={inductionDocs?.driversLicenceUrl}
        />
        <Document name="RTA" url={inductionDocs?.rtaUrl} />
        <Document name="Sign" url={inductionDocs?.signUrl} />
        <Document name="Profile pic" url={inductionDocs?.profileUrl} />
        <Document name="Insurance" url={inductionDocs?.insuranceDocumentUrl} />
      </ol>
    </div>
  );
}
