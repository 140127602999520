import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import * as Yup from "yup";
import { FaEye, FaPen, FaTrash, FaPlus } from "react-icons/fa";

import { TextBox, Button } from "../../../components/Form";
import { getData, postData } from "../../../utils/api";
import { errorToast, successToast } from "../../../utils/toastFunc";
import "../UserManagement.scss";
import ModalTab from "../../../components/Modal";
import config from "../../../constants/config";

const AdditionTab = ({
  defaultValues,
  driverId,
  submitUrl,
  viewEditMode,
  setKey,
}) => {
  const colors = { btnColor: "#435E9E" };
  const [viewMode, setViewMode] = useState(viewEditMode);
  const toggleViewMode = () =>
    setViewMode((prevViewMode) => (prevViewMode === "edit" ? "view" : "edit"));

  const [showAdditionHistory, setShowAdditionHistory] = useState(false);
  const [additionHistory, setAdditionHistory] = useState();
  const handleHideModal = () => setShowAdditionHistory(false);

  const validationSchema = Yup.object().shape({
    additionDetailsDto: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().nullable(),
        additionType: Yup.string().required("Addition Type is Mandatory").typeError("Addition Type is Mandatory"),
        amount: Yup.number()
          .typeError("Amount is Mandatory")
          .required("Amount is mendatory"),
        notes: Yup.string(),
        date: Yup.string(),
        active: Yup.boolean(),
      })
    ),
    paidAmount: Yup.number()
      .required("Value is mendatory")
      .typeError("Value is mendatory"),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });
  /* A react hook form function that is used to create dynamic form fields. */
  const { fields, append, remove } = useFieldArray({
    name: "additionDetailsDto",
    control,
  });

  /*  react hook form function that is used to watch the value of a field. */
  const additionsArray = useWatch({
    control,
    name: "additionDetailsDto",
  });
  const watchpaidAmount = useWatch({
    control,
    name: "paidAmount",
  });

  const onSubmitFunction = async (formData) => {
    submitUrl = submitUrl + `?driverId=${driverId}`;
    let response = await postData(submitUrl, formData);
    if (response?.data) {
      // success response after
      successToast({ mes: "Addition Details Saved Successfully" });
      if (!viewEditMode) {
        // if creating new User
        // to get the updated values from db after saving details (specially id for updating further)
        const url = config.baseUrl + config.driverAdditionsUrls.getDriverAdditions + '?driverId=' + driverId;
        const resp = await getData(url);
        if (resp?.data) {
          reset(resp.data); //this sets the values from db
        }
        setViewMode("view"); // to enable view mode after saving addition information
        setKey("tfn"); // moving to another tab
      }
    } else {
      // else server error handling
      errorToast({ mes: "Try Again" });
    }
  };
  /* Calculating the total Additions amount. */
  let totalAdditionsAmount = Array.isArray(additionsArray)
    ? additionsArray.reduce(function (prev, cur) {
        return Number(prev ? prev : 0) + Number(cur.amount ? cur.amount : 0);
      }, 0)
    : 0;

  /* setting total Additions and balance text boxes. */
  setValue("totalAdditionsAmount", Number(totalAdditionsAmount).toFixed(2));
  const paidAmount = watchpaidAmount ? Number(watchpaidAmount).toFixed(2) : 0;
  setValue("netBalance", Number(totalAdditionsAmount - paidAmount).toFixed(2));

  const handleShowHistory = async () => {
    const url = config.baseUrl + config.driverAdditionsUrls.getDriverAdditionsHistory + '?driverId=' + driverId;
    const resp = await getData(url);
    if (resp?.data) {
      setAdditionHistory(resp.data);
    }
    setShowAdditionHistory(true);
  };
  const handleDeleteRow = index => {
    const confirm = window.confirm("Are you sure to Delete?");
    if (confirm) {
      remove(index)
      successToast({mes:"Addition row successfully deleted"})
    };
  }
  const handleAddRow = () => {
    append({
      id: null,
      additionType: "",
      amount: 0,
      date: "",
      notes: "",
      active: true,
    });
  };
  //   console.log(errors);
  return (
    <>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitFunction)}
        className=" blur-bg p-3"
      >
        {viewMode && !viewEditMode && (
          <button
            title="view"
            type="button"
            className="btn btn-primary p-1 me-1 lh-1"
            onClick={() => toggleViewMode()}
          >
            {viewMode === "edit" ? <FaEye size={20} /> : <FaPen size={20} />}
          </button>
        )}
        <div className="col ">
          <div className="form-group d-flex bold-text">
            <div className="col-1 p-2 center-items">S.N</div>
            <div className="col-2 p-2 center-items">Addition Type</div>
            <div className="col-2 p-2 center-items">Amount</div>
            <div className="col-2 p-2 center-items">Date</div>
            <div className="col-2 p-2 center-items">Notes</div>
            <div className="col-3 p-2 row">
              <div className="col-5">
                {viewMode !== "view" && (
                  <Button
                    type="Button"
                    className="buttons"
                    onClick={handleAddRow}
                    title="Add"
                  >
                    <FaPlus className="me-1" />
                  </Button>
                )}
              </div>
              <div className="col-6">
                <Button
                  type="Button"
                  className="buttons"
                  onClick={handleShowHistory}
                  title="History"
                >
                </Button>
              </div>
            </div>
          </div>
          {fields.map((item, index) => (
            <div key={index} className="form-group d-flex row">
              <div className="col-1 p-2">
                <div className="d-inline-block">{index + 1}</div>
              </div>
              <div className="col-2 p-2">
                <TextBox
                  id={`additionDetailsDto[${index}].additionType`}
                  placeholder="addition Type"
                  disabled={viewMode === "view"}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                />
                {errors?.additionDetailsDto?.[index]?.additionType && (
                  <p className="d-flex invalid-feedback">{errors["additionDetailsDto"][index].additionType.message}</p>
                )}{" "}
              </div>
              <div className="col-2 p-2">
                <TextBox
                  id={`additionDetailsDto[${index}].amount`}
                  type="number"
                  step=".01"
                  disabled={viewMode === "view"}
                  register={register}
                  errors={errors}
                />
                {errors?.additionDetailsDto?.[index]?.amount && (
                  <p className="d-flex invalid-feedback">{errors["additionDetailsDto"][index].amount.message}</p>
                )}{" "}
              </div>
              <div className="col-2 p-2">
                <TextBox
                  id={`additionDetailsDto[${index}].date`}
                  type="date"
                  disabled={viewMode === "view"}
                  max="9999-12-31"
                  register={register}
                  errors={errors}
                />
              </div>
              <div className="col-2 p-2">
                <TextBox
                  register={register}
                  //   value={item.note}
                  disabled={viewMode === "view"}
                  errors={errors}
                  id={`additionDetailsDto[${index}].notes`}
                />
              </div>
              <div className="col-1 p-2">
                {viewMode !== "view" && (
                  <Button
                    type="Button"
                    className="buttons"
                    onClick={() => handleDeleteRow(index)}
                  >
                    <FaTrash className="" size={20} />
                  </Button>
                )}
              </div>
            </div>
          ))}

          {/* Total Addition Amount  */}
          <div className="form-group d-flex row mt-1 mb-3">
            <div className="col-1 p-2 center-items">
              <div className="d-inline-block"></div>
            </div>
            <div className="col-2 p-2">
              <Button
                title="Total Additions"
                color={colors.btnColor}
                className="cursor-default"
              />
            </div>
            <div className="col-2 p-2">
              <TextBox
                id={`totalAdditionsAmount`}
                type="number"
                value={0}
                step=".01"
                disabled={true}
                register={register}
                errors={errors}
              />
            </div>
            <div className="col-5 p-2"></div>
          </div>
          {/* Paid Amount */}
          <div className="form-group d-flex row mt-2">
            <div className="col-1 p-2 center-items">
              <div className="d-inline-block"></div>
            </div>
            <div className="col-2 p-2">
              <Button
                title="Total Paid"
                color={colors.btnColor}
                className="cursor-default"
              />
            </div>
            <div className="col-2 p-2">
              <TextBox
                id={`paidAmount`}
                type="number"
                value={0} //defaultValue
                step=".01"
                disabled={viewMode === "view"}
                register={register}
                errors={errors}
              />
            </div>
            <div className="col-5 p-2"></div>
          </div>
          {/* Balance*/}
          <div className="form-group d-flex row mt-1">
            <div className="col-1 p-2 center-items">
              <div className="d-inline-block"></div>
            </div>
            <div className="col-2 p-2">
              <Button
                title="Balance"
                color={colors.btnColor}
                className="cursor-default"
              />
            </div>
            <div className="col-2 p-2">
              <TextBox
                id={`netBalance`}
                type="number"
                value={0}
                step=".01"
                disabled={true}
                register={register}
                errors={errors}
              />
            </div>
            <div className="col-5 p-2"></div>
          </div>
        </div>
        <div className="row p-4 ">
          <div className="col-10"></div>
          <div className="ml-auto col-2 ">
            {viewMode !== "view" && (
              <Button type="submit" className="buttons" title={viewMode === "edit" ? "Save" : "Submit"}/>
            )}
          </div>
        </div>
      </form>
      {/* Modal for history */}
      <ModalTab
        size="lg"
        centered
        bodyClassName={
          "border border-secondary shadow-lg p-3 mb-5 bg-body rounded m-5"
        }
        onHide={handleHideModal}
        title="Addition History"
        show={showAdditionHistory}
        body={
          <>
            <div className={` p-3 pt-5 pb-5  blur-bg rounded `}>
              <section className="row mb-2">
                {!Array.isArray(additionHistory) ? (
                  <div>NO DATA PRESENT</div>
                ) : (
                  <div className="row mb-2">
                    {["Date", "Total Additions", "Paid Amount", "Balance"].map(
                      (title) => (
                        <div key={title} className="col-md-3">
                          <Button
                            title={title}
                            color={colors.btnColor}
                            className="cursor-default"
                          />
                        </div>
                      )
                    )}
                  </div>
                )}

                {Array.isArray(additionHistory) &&
                  additionHistory.map(
                    ({ date, paidAmount, totalAdditionAmount, balance }) => (
                      <div key={date} className="row mb-1">
                        <div className="col-md-3">{date}</div>
                        <div className="col-md-3">{totalAdditionAmount}</div>
                        <div className="col-md-3">{paidAmount}</div>
                        <div className="col-md-3">{balance}</div>
                      </div>
                    )
                  )}
              </section>
            </div>
          </>
        }
      />
    </>
  );
};

export default AdditionTab;
