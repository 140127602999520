import React from 'react';

const styleSheet = {
    color: "rgba(24, 33, 37, 0.89)",
    fontWeight: "700",
    fontSize: "14px",
    borderRadius: "8px",
    width: "148px",
    height: "45px",
    backgroundColor: "rgba(217, 217, 217, 0.76)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
}

const Button = (props) => {
  return (
    <div className='buttom-main' style={styleSheet}>
      {
        props.name == "PDF"
        ? "DOCUMENT" : props.name
      }
    </div>
  );
}

export default Button;