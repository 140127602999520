import "../FinanceApplication.scss";

export const CommonBoxWithHeader = ({ title, children }) => {
  return (
    <div className="dashboard">
      <div className="dashboard_header">
        <h3 className="">{title}</h3>
      </div>
      <div className="dashboard_bottom">{children}</div>
    </div>
  );
};
