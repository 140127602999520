import React from "react";
import Form from "react-bootstrap/Form";

const SelectField = ({
  title,
  id,
  ratio = [4, 8],
  required = false,
  register,
  errors,
  disabled,
  value = "",
  options,
  optionValueKey,
  optionDisplayKey,
  getError,
  className,
}) => {
  const error = getError?.(errors) || errors?.[id];
  const renderOptions = () => {
    if (Array.isArray(options) && options.length) {
      return options.map((option) => {
        if (typeof option === "string" || option instanceof String) {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        }
        return (
          <option key={option[optionValueKey]} value={option[optionValueKey]}>
            {option[optionDisplayKey]}
          </option>
        );
      });
    }
    return null;
  };

  return (
    <div className="row mb-3">
      {title && (
        <label
          htmlFor={id}
          className={`col-sm-${ratio[0]} col-form-label ${className}`}
        >
          {title}
          {required && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      <div className={`col-sm-${ratio[1]}`}>
        <Form.Select
          aria-label="Default select example"
          className={`form-select ${error ? "is-invalid" : ""}`}
          id={id}
          {...register(id)}
          defaultValue={value}
          disabled={disabled}
        >
          <option style={{ color: "green" }} disabled value="">
            Please select a value
          </option>
          {renderOptions()}
        </Form.Select>
        {error && <p className="invalid-feedback"> {error.message}</p>}
      </div>
    </div>
  );
};

export default SelectField;
