import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import config from "../../../constants/config";
import { fetchService } from "../../../utils/api";
import { errorToast, successToast } from "../../../utils/toastFunc";

export default function InviteTab({ userId }) {
  const [message, setMessage] = useState("");

  const inviteUser = () => {
    const url = `${config.sendSmsToSingleUser}/${userId}?messageContent=${message}`;

    fetchService({
      url,
      method: "POST",
      onSuccessCallback: () => {
        successToast({ mes: "Invitation send successfully" });
      },
    }).catch((error) => {
      errorToast({ mes: error.message });
    });
  };

  return (
    <div className="p-3 text-start">
      <h3>Invite User</h3>
      <InputGroup className="mt-3">
        <Form.Control
          as="textarea"
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          value={message}
          aria-label="Text Message"
          placeholder="Write a message"
        />
      </InputGroup>
      <div className="d-flex">
        <Button
          onClick={inviteUser}
          disabled={message === ""}
          className="mt-3 ms-auto"
        >
          Invite
        </Button>
      </div>
    </div>
  );
}
