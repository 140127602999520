import React, { useMemo } from "react";
import { ColumnFilter } from "../UsersDisplay";
import TableComp from "../../../components/Table";

export default function LogsTable({ row }) {
  const columns = useMemo(() => {
    return [
      {
        Header: "Field Key",
        accessor: "fieldKey",
        Filter: ColumnFilter,
        width: 60,
      },
      {
        Header: "Old Value",
        accessor: "fieldOldValue",
        Filter: ColumnFilter,
      },
      {
        Header: "New Value",
        accessor: "fieldNewValue",
        Filter: ColumnFilter,
      },
    ];
  }, []);

  return (
    <TableComp columns={columns} data={row.original?.fieldLogsList || []} />
  );
}
