import React from "react";
import { useFormContext } from "react-hook-form";
import { FormField, TextBox } from "../../Form";

function AccountDetails({ prev, onNext }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const submit = () => onNext();
  return (
    <form onSubmit={handleSubmit(submit)}>
      <h3 className="stepper_Header">Account Details</h3>
      <div className="my-3">
        <FormField
          className="Field_label"
          title="Accounts Team Contact Name"
          id="accountsTeam.contactPerson"
          ratio={[12, 12]}
        >
          <TextBox
            type="text"
            id="accountsTeam.contactPerson"
            register={register}
            errors={errors}
            placeholder="Enter the name of the Contact Person"
            className="py-2"
            getError={(error) => error?.accountsTeam?.contactPerson}
          />
        </FormField>
        <FormField
          className="Field_label"
          title="Telephone"
          id="accountsTeam.phoneNumber"
          ratio={[12, 12]}
        >
          <div className="input-group">
            <span className="input-group-text" id="accountsTeam.phoneNumber">
              +61
            </span>
            <TextBox
              type="text"
              id="accountsTeam.phoneNumber"
              register={register}
              errors={errors}
              placeholder="Enter the Telephone"
              className="py-2"
              getError={(error) => error?.accountsTeam?.phoneNumber}
            />
          </div>
        </FormField>
        <FormField
          className="Field_label"
          title="Authorizing Officer"
          id="accountsTeam.authorizingOfficer"
          ratio={[12, 12]}
        >
          <TextBox
            type="text"
            id="accountsTeam.authorizingOfficer"
            register={register}
            errors={errors}
            placeholder="Enter the name of the Authorization Officer"
            className="py-2"
            getError={(error) => error?.accountsTeam?.authorizingOfficer}
          />
        </FormField>
        <FormField
          className="Field_label"
          title="Telephone"
          id="accountsTeam.authorizingOfficerContactNo"
          ratio={[12, 12]}
        >
          <div className="input-group">
            <span className="input-group-text" id="accountsTeam.phoneNumber">
              +61
            </span>
            <TextBox
              type="text"
              id="accountsTeam.authorizingOfficerContactNo"
              register={register}
              errors={errors}
              placeholder="Enter the Telephone"
              className="py-2"
              getError={(error) =>
                error?.accountsTeam?.authorizingOfficerContactNo
              }
            />
          </div>
        </FormField>
        <FormField
          className="Field_label"
          title="Accounts Contact Email"
          id="accountsTeam.emailAddress"
          ratio={[12, 12]}
        >
          <TextBox
            type="email"
            id="accountsTeam.emailAddress"
            register={register}
            errors={errors}
            placeholder="Enter the email address"
            className="py-2"
            getError={(error) => error?.accountsTeam?.emailAddress}
          />
        </FormField>
        <FormField
          className="Field_label"
          title="Position"
          id="accountsTeam.position"
          ratio={[12, 12]}
        >
          <TextBox
            type="text"
            id="accountsTeam.position"
            register={register}
            errors={errors}
            placeholder="Enter the position"
            className="py-2"
            getError={(error) => error?.accountsTeam?.position}
          />
        </FormField>
      </div>
      <div className="form-btn">
        <button type="button" className="credit-form-btn" onClick={prev}>
          Back
        </button>
        <button type="submit" className="credit-form-btn">
          Next
        </button>
      </div>
    </form>
  );
}

export default AccountDetails;
