import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './PDFPopUp.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();


export default function PDFPopUp(props) {

  return (
    <div className="video-popup">
      <div className="video-popup-title">
        {props.name} Inspection Rules
      </div>

      <iframe
        className="view-pdf"
        src={props.pdfFile}
        allowFullScreen="true"
        webkitAllowFullScreen="true"
        mozallowFullScreen="true"
      />

    </div>
  );
}
