import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
// import { ConnectedRouter } from 'connected-react-router';
import { store } from "./rtk-slice/store";
import AppRoutes from "./app.routes";
// import history from './utils/history';
import { BrowserRouter } from "react-router-dom";
// import Container from 'react-bootstrap/Container';
import reportWebVitals from "./reportWebVitals";
// import "./index.scss";
import { LoadingProvider } from "./context";
import "./theme/theme.scss";
// import { LoadingProvider } from "./components/context";
import ToastComp from "./components/ToastComp";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const container = document.getElementById("root");
const root = createRoot(container);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60_000 * 2,
    },
  },
});

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <LoadingProvider>
        <BrowserRouter>
          {/* <ConnectedRouter history={history}> */}
          {/* <Container fluid> */}
          <AppRoutes />
          <ToastComp />
          {/* </Container> */}
          {/* </ConnectedRouter> */}
        </BrowserRouter>
      </LoadingProvider>
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
