import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import "./driver-details.scss";
import { driverFormData } from "./driverFormsData";
import DriverForm from "./components/DriverForm";
import DetailsTable from "./components/DetailsTable";

import { fetchService, getData } from "../../../../utils/api";
import config from "../../../../constants/config";
import { errorToast } from "../../../../utils/toastFunc";
import {
  selectAllLookUpsData,
  selectIsLookUpDataFilled,
} from "../../../../rtk-slice/globalSlice";
import { useSelector } from "react-redux";
import useLoading from "../../../../hooks/useLoading";

function generateFormFieldsWithLookup(formFields, lookup) {
  const fields = [...formFields];

  fields.forEach((field) => {
    if (field.loadLookUp && field.lookupOptions.type === "array") {
      if (!lookup[field.loadLookUp]) field.options = [];
      else
        field.options = lookup[field.loadLookUp].map((option) => ({
          id: option,
          label: option,
        }));
    }
    if (field.loadLookUp && field.lookupOptions.type === "object") {
      if (!lookup[field.loadLookUp]) field.options = [];
      else
        field.options = lookup[field.loadLookUp].map((option) => ({
          id: option[field.lookupOptions.key],
          label: option[field.lookupOptions.label],
        }));
    }
  });

  return fields;
}

export default function DriversOperations({ viewStats }) {
  const { setLoading } = useLoading();
  const lookup = useSelector(selectAllLookUpsData);
  const isLookUpDataFilled = useSelector(selectIsLookUpDataFilled);
  /**
   * Mode is the view the user wants to access driver
   * Mode : view, edit
   */
  const { mode, driver } = viewStats;

  /**
   * Data of the current tab user has clicked
   * Default tab is general
   */
  const [activeTab, setActiveTab] = useState(driverFormData[0]);

  /**
   * Stores data fetched for the tabs
   */
  const [details, setDetails] = useState({
    general: {},
    "identification-docs": [],
    medical: [],
    uniform: [],
    permits: [],
    training: [],
    "accidents-incidents": [],
  });

  /**
   * Store the data of selected driver details and it's view mode
   * mode : view, edit
   */
  const [selectedDetails, setSelectedDetails] = useState({
    mode: "",
    data: {},
  });

  /**
   * Fetch this details again when I user changes details from User Details tab
   * timestamp is added to driver object only when user details are updated
   */
  useEffect(() => {
    if (driver?.timestamp) {
      fetchService({
        url:
          config.driverAdditionsUrls.getDriverCardByDriverIdUsingGET +
          driver.userId,
        onSuccessCallback: (response) => {
          setDetails((prevDetails) => ({
            ...prevDetails,
            general: response.data,
          }));
          setSelectedDetails({ mode, data: response.data });
        },
      });
    }
  }, [driver?.timestamp]);

  /**
   * Set Driver details in "general" object (tab) in details state.
   */
  useEffect(() => {
    if (isEmpty(details.general) && !isEmpty(driver)) {
      const url =
        config.baseUrl +
        config.driverAdditionsUrls.getDriverCardByDriverIdUsingGET +
        driver.userId;
      setLoading(true);
      getData(url)
        .then((response) => {
          setDetails((prevDetails) => ({
            ...prevDetails,
            general: response.data,
          }));
          setSelectedDetails({ mode, data: response.data });
        })
        .catch((error) => {
          errorToast({ mes: error?.message ?? "Something went wrong!" });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [details.general, driver, mode]);

  useEffect(() => {
    if (isLookUpDataFilled && activeTab.tabId === "general") {
      const newTab = driverFormData[0];
      newTab.formFields = generateFormFieldsWithLookup(
        newTab.formFields,
        lookup
      );
      setActiveTab(newTab);
    }
  }, [lookup, isLookUpDataFilled]);

  const handleTabSelect = (tabId) => {
    const tab = driverFormData.find((tab) => tab.tabId === tabId);
    if (tab.tabId === "general")
      tab.formFields = generateFormFieldsWithLookup(tab.formFields, lookup);
    setActiveTab(tab);

    if (tab.tabId === "general")
      setSelectedDetails({
        mode,
        data: details.general,
      });
    else
      setSelectedDetails({
        mode: "",
        data: {},
      });

    /**
     * Fetch details for the tab if it's not already in details state.
     * And set it in details state .
     */
    if (isEmpty(details[tabId]) && details[tabId] !== "general") {
      const url = `${config.baseUrl}${tab.url}?driverCardId=${details.general.driverCardId}`;
      setLoading(true);
      getData(url)
        .then((response) => {
          if (response.data)
            setDetails((prevDetails) => ({
              ...prevDetails,
              [tabId]: response.data,
            }));
        })
        .catch((error) => {
          errorToast({ mes: error?.message ?? "Something went wrong!" });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const renderHeaderByTab = () => {
    if (
      !activeTab.isParentTab &&
      selectedDetails.mode === "" &&
      mode === "edit"
    )
      return (
        <button
          className="btn btn-primary"
          onClick={() =>
            setSelectedDetails({
              mode: "edit",
              data: {},
            })
          }
        >
          {`Add ${activeTab.displayName}`}
        </button>
      );
    return null;
  };

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={activeTab.tabId}
      onSelect={(tabId) => handleTabSelect(tabId)}
      className="mb-3"
    >
      {driverFormData.map((tab) => (
        <Tab
          key={tab.tabId}
          eventKey={tab.tabId}
          title={tab.displayName}
          disabled={
            tab.tabId !== "general"
              ? isNull(details.general.driverCardId)
              : false
          }
        >
          <Tab.Content className="p-2">
            {tab.tabId === activeTab.tabId && (
              <div className="drivers-additional-details-container">
                {renderHeaderByTab()}
                <DriverForm
                  activeTab={activeTab}
                  viewStats={viewStats}
                  details={structuredClone(details)}
                  selectedDetails={structuredClone(selectedDetails)}
                  setSelectedDetails={setSelectedDetails}
                  setDetails={setDetails}
                />
                {activeTab.tabId !== "general" && (
                  <DetailsTable
                    selectedDetails={{ ...selectedDetails }}
                    details={structuredClone(details)}
                    activeTab={activeTab}
                    setSelectedDetails={setSelectedDetails}
                    setDetails={setDetails}
                  />
                )}
              </div>
            )}
          </Tab.Content>
        </Tab>
      ))}
    </Tabs>
  );
}
