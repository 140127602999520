import React from 'react';
import PlusButton from "../../assets/InductionLearningAssets/plusBtn.png";

const PlusBtn = () => {
  return (
    <div>
        <img src={PlusButton} alt='Plus Button' />
    </div>
  );
}

export default PlusBtn;