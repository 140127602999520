import React from "react";

const CheckField = ({
  title,
  id,
  required = false,
  register,
  errors,
  disabled = false,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-1 d-flex align-items-center justify-content-center">
          <input
            id={id}
            {...register(id)}
            className={`form-check-input me-2 ${
              errors[id] ? "is-invalid" : ""
            }`}
            type="checkbox"
            disabled={disabled}
          />
        </div>
        <div className="col-sm-11">
          <label htmlFor={id} className="col-form-label text-left">
            {title}
            {required && <span style={{ color: "red" }}>*</span>}
          </label>
        </div>
      </div>

      {errors?.[id] && (
        <p className="invalid-feedback"> {errors[id].message}</p>
      )}
    </>
  );
};

export default CheckField;
