import React from "react";
import { toast } from "react-toastify";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { AiFillDelete } from "react-icons/ai";
import { Form } from "../../../../../components/Form/Form";
import {
  downloadFileFromReadableStream,
  fetchService,
} from "../../../../../utils/api";
import { errorToast, successToast } from "../../../../../utils/toastFunc";
import useLoading from "../../../../../hooks/useLoading";

export default function DriverForm({
  activeTab,
  viewStats,
  details,
  selectedDetails,
  setSelectedDetails,
  setDetails,
}) {
  const { mode } = viewStats;
  const { setLoading } = useLoading();

  const handleDownloadFile = async (id, attachment) => {
    setLoading(true);
    fetchService({
      method: "GET",
      url: `/${activeTab.url}/attachments/1?attachmentId=${id}`,
      isFile: true,
    })
      .then((response) => {
        if (response.status === 200) return response.text();
        else errorToast({ mes: "Error downloading the file" });
      })
      .then((stream) => {
        downloadFileFromReadableStream(stream, attachment);
      })
      .catch((error) => {
        errorToast({ mes: error?.message ?? "Something went wrong!" });
      })
      .finally(() => setLoading(false));
  };

  const handleDeleteAttachment = (id, index) => {
    setLoading(true);
    fetchService({
      method: "DELETE",
      url: `${activeTab.url}/attachments/1?attachmentId=${id}`,
      onSuccessCallback: (response) => {
        if (response) {
          successToast({ mes: "File Deleted successfully" });
          const { tabId, uniqueReferenceKey, attachmentFieldKeyName } =
            activeTab;
          const newDetails = structuredClone(details);

          const editedDetailIndex = newDetails[tabId].findIndex(
            (tab) =>
              tab[uniqueReferenceKey] ===
              selectedDetails.data[uniqueReferenceKey]
          );

          newDetails[tabId][editedDetailIndex][attachmentFieldKeyName].splice(
            index,
            1
          );

          setSelectedDetails((prevSelectedDetails) => ({
            mode: prevSelectedDetails.mode,
            data: newDetails[tabId][editedDetailIndex],
          }));

          setDetails(newDetails);
        }
      },
    })
      .catch((error) => {
        errorToast({ mes: error?.message ?? "Something went wrong!" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = (values) => {
    let method = "POST";
    let toastMessage = `Driver ${activeTab.displayName} details updated successfully`;

    setLoading(true);

    activeTab.formFields.forEach((field) => {
      if (field.type === "date" && values[field.fieldId]) {
        values[field.fieldId] = moment(values[field.fieldId]).format(
          "yyyy-MM-DD"
        );
      }
    });

    const formData = new FormData();

    if (!isNil(details.general.driverCardId))
      values["driverCardId"] = details.general.driverCardId;

    if (activeTab.tabId !== "general") {
      if (!isNil(values[activeTab.uniqueReferenceKey])) method = "PUT";
      const files = Array.from(values.files ?? []);
      if (files?.length > 0) {
        files.forEach((attachment) => {
          formData.append("files", attachment);
        });
      }
      delete values.files;
      formData.append("jsonString", JSON.stringify(values));
    }

    fetchService({
      method,
      url: activeTab.url,
      removeContentType: activeTab.tabId !== "general",
      body: activeTab.tabId === "general" ? JSON.stringify(values) : formData,
      onSuccessCallback: (response) => {
        if (activeTab.tabId === "general") {
          setDetails((prevDetails) => ({
            ...prevDetails,
            general: response.data,
          }));
          setSelectedDetails({ mode, data: response.data });
        } else {
          if (method === "POST") {
            /**
             * Push The new object in the details array of activeTab.tabId
             */
            setDetails((prevDetails) => {
              const tabDetails = prevDetails[activeTab.tabId];
              tabDetails.push(response.data);
              return { ...prevDetails, [activeTab.tabId]: tabDetails };
            });
            setSelectedDetails({ mode: "", data: {} });
          } else if (method === "PUT") {
            /**
             * Find Index of selected detail from details array of activeTab.tabId
             * Replace the element on that index with the response
             * Set new details state and selectedDetails state
             */
            const { uniqueReferenceKey } = activeTab;
            const editedDetailsIndex = details[activeTab.tabId].findIndex(
              (tab) =>
                tab[uniqueReferenceKey] === response.data[uniqueReferenceKey]
            );

            const tabDetails = structuredClone(details[activeTab.tabId]);

            tabDetails.splice(editedDetailsIndex, 1, response.data);

            setDetails((prevDetails) => ({
              ...prevDetails,
              [activeTab.tabId]: tabDetails,
            }));

            setSelectedDetails((prevSelectedDetails) => ({
              mode: prevSelectedDetails.mode,
              data: response.data,
            }));
          }
        }
        toast.success(toastMessage);
      },
    })
      .catch((error) => {
        let mes = "Something went wrong!";
        if (error?.message?.split?.(":")?.length > 1)
          mes = error?.message?.split?.(":")?.[1];
        else if (error?.message) mes = error.message;
        errorToast({
          mes,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const attachments = selectedDetails.data[activeTab["attachmentFieldKeyName"]];

  if (activeTab.tabId !== "general" && selectedDetails.mode === "") return null;

  return (
    <Form
      onSubmit={onSubmit}
      formData={activeTab.formFields}
      columns={4}
      uniqueReferenceKey={activeTab.uniqueReferenceKey}
      formMode={selectedDetails.mode}
      defaultValues={selectedDetails.data}
      loading={false}
    >
      <div className="col-12">
        {activeTab.tabId !== "general" &&
          Array.isArray(attachments) &&
          !isEmpty(attachments) && (
            <div className="row">
              <div className="col-md-2">
                <label>Attachments</label>
              </div>
              <div className="col-md-6">
                <ol className="attachment-list">
                  {attachments?.map((attachment, i) => (
                    <li key={attachment[activeTab["attachmentFieldId"]]}>
                      <div className="d-flex">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleDownloadFile(
                              attachment[activeTab.attachmentFieldId],
                              attachment
                            );
                          }}
                          title="Download File"
                          className="btn attachment-download-btn"
                        >
                          {attachment.fileName}
                        </button>
                        {selectedDetails.mode === "edit" && (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              handleDeleteAttachment(
                                attachment[activeTab["attachmentFieldId"]],
                                i
                              );
                            }}
                            title="Delete Attachment"
                            className="btn attachment-delete-btn"
                          >
                            <AiFillDelete />
                          </button>
                        )}
                      </div>
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          )}
        <div className="module-footer">
          <div>
            {activeTab.tabId !== "general" && selectedDetails.mode !== "" && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setSelectedDetails({ mode: "", data: {} })}
              >
                Cancel
              </button>
            )}
            {selectedDetails.mode === "edit" && (
              <button type="submit" className="btn btn-primary ms-2">
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </Form>
  );
}
