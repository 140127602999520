const VEHICLE_TYPES = {
  TRAILER: "trailer",
  TRUCK: "truck",
  FORKLIFT: "forklift",
  MISCELLANEOUS: "miscellaneous"
};

const headers = {};

headers[VEHICLE_TYPES.TRUCK] = {
  vehicleTruckId: "ID",
  licensePlatNo: "License Plate No",
  truckType: "Truck Type",
  colour: "Colour",
  model: "Model",
  registrationRenewalDate: "Registration Renewal Date",
};

headers[VEHICLE_TYPES.TRAILER] = {
  vehicleTrailerId: "ID",
  licensePlatNo: "License Plate No",
  trailerType: "Trailer Type",
  model: "Model",
  registrationRenewalDate: "Registration Renewal Date",
};

headers[VEHICLE_TYPES.FORKLIFT] = {
  vehicleForkLiftId: "ID",
  licensePlatNo: "License Plate No",
  manufacturedDate: "Manufactured Date",
  colour: "Colour",
  model: "Model",
  registrationRenewalDate: "Registration Renewal Date",
};

headers[VEHICLE_TYPES.MISCELLANEOUS] = {
  vehicleMiscellaneousId: "ID",
  licensePlatNo: "License Plate No",
  manufacturedDate: "Manufactured Date",
  colour: "Colour",
  model: "Model",
  vehicleName: 'Vehicle Name',
  registrationRenewalDate: "Registration Renewal Date",
};

const VEHICLE_TYPES_UNIQUE_IDS = {
  [VEHICLE_TYPES.TRUCK]: "vehicleTruckId",
  [VEHICLE_TYPES.TRAILER]: "vehicleTrailerId",
  [VEHICLE_TYPES.FORKLIFT]: "vehicleForkLiftId",
  [VEHICLE_TYPES.MISCELLANEOUS]: "vehicleMiscellaneousId"
};

export { VEHICLE_TYPES, headers, VEHICLE_TYPES_UNIQUE_IDS };
