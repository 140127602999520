import React, { useContext, createContext, useState } from 'react';
import Spinner from '../components/Spinner/Spinner';

export const LoadingContext = createContext({
   isLoading: false,
});

export const useLoadingContext = () => {
   return useContext(LoadingContext);
}

export const LoadingProvider = ({ children }) => {
   const [isLoading, setIsLoading] = useState(false);

   return (
      <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
         {isLoading ? <Spinner /> : null}
         {children}
      </LoadingContext.Provider>
   );
};

export const useIsLoadingFalse = () => {
   const {isLoading, setIsLoading} = useLoadingContext();
   if (isLoading === true) {
      setIsLoading(false);
   }
   return isLoading;
};
