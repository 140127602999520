import { useEffect } from "react";
import { useState } from "react";
import { FaTrash, FaUpload, FaDownload } from "react-icons/fa";
import { Button } from "../../../components/Form";
import config from "../../../constants/config";
import { deleteData, getData, postFile } from "../../../utils/api";
import {
  errorToast,
  successToast,
  warningToast,
} from "../../../utils/toastFunc";

const MAX_COUNT = 20;
const MAX_SIZE = 5000000;

const DocumentsUpload = ({ driverId,viewEditMode }) => {
  const [driverDocuments, setDriverDocuments] = useState([]);
  const [viewMode, setViewMode] = useState(viewEditMode);
  useEffect(() => {
    driverId && fetchUserDocuments();
  }, []);

  /**
   * It fetches all the documents of a driver.
   */
  const fetchUserDocuments = async () => {
    const url =
      config.baseUrl + config.userDocuments.getAllFiles + "/" + driverId;
    const response = await getData(url);
    if (response.data && Array.isArray(response.data)) {
      setDriverDocuments(response.data);
    } else {
      setDriverDocuments([]);
    }
  };

  /**
   * It takes a file from the user, sends it to the server, and then fetches the user's documents from
   * the server
   */
  const handleFileEvent = async (e) => {
    if (driverDocuments.length >= MAX_COUNT){
      return warningToast({mes:`File Limit of ${MAX_COUNT} exceeded`})
    }
    const chosenFile = e.target.files[0];
    if (chosenFile) {
      /* Checking if the file size is greater than 5MB. If it is, it will show a warning message. */
      if (chosenFile.size > MAX_SIZE) {
        return warningToast({ mes: "File should be less than 5 MB" });
      }
      const url =
        config.baseUrl + config.userDocuments.uploadFile + "/" + driverId;
      /* Creating a form data object and appending the file to it. */
      const formData = new FormData();
      formData.append("document", chosenFile);
      const response = await postFile(url, formData);

      if (response.data === "document uploaded successfully") {
        successToast({ mes: "Upload Successful" });
      } else {
        errorToast({ mes: "Error Upload" });
      }
      fetchUserDocuments();
    }
  };

  const handleDeleteFile = async (docId) => {
    const url =
      config.baseUrl +
      config.userDocuments.deleteFile +
      "/" +
      driverId +
      "/" +
      docId;
    let confirm = window.confirm("Are you sure you want to delete this file?");
    if (confirm) {
      const response = await deleteData(url);
      if (response.data === "File deleted successfully.") successToast({mes:"File deleted successfully"});
      fetchUserDocuments();
    }
  };

  const handleDownloadFile = async (fileName) => {
    const url =
      config.baseUrl +
      config.userDocuments.downloadFile +
      "/" +
      driverId +
      "/" +
      fileName;
    getData(url, {}, true)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const file = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = file;
        link.download = fileName;
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        link.parentNode.removeChild(link);
        // clean up Url
        window.URL.revokeObjectURL(file);
        successToast({ mes: "File downloaded!" });
      });
  };
  return (
    <>
      <div className="form-group mb-3 p-3 documentsTab">
        <div className="col-12 p-2">
          {viewMode !== "view" && <div className="m-2 d-flex flex-row-reverse mb-2">
            <input
              id="documentUpload"
              className="d-none"
              type="file"
              // accept="application/pdf, image/png, imgage/jpg, image/jpeg"
              onChange={handleFileEvent}
              // disabled={driverDocuments.length >= MAX_COUNT}
            />

            <label htmlFor="documentUpload" className="btn buttons text-white">
              <FaUpload className="" color="white" size={20} /> Upload Document
            </label>
          </div>}
        </div>

        <div className="p-5 mb-5">
          {driverDocuments.length ? (
            driverDocuments.map((file, index) => (
              <div key={index} className="mb-1 p-1">
                <div className="col">
                  <div className="row documents">
                    <div className="col-1 documents__Sn ">{index + 1}.</div>
                    <div className="col-9 documents__Name">{file.fileName}</div>
                    <div className="col-1">
                      <Button
                        onClick={() => handleDownloadFile(file.fileName)}
                        className="buttons"
                      >
                        <FaDownload size={20} className="download__icon" />
                      </Button>
                    </div>
                   {viewMode !== "view" && <div className="col-1">
                      <Button
                        onClick={() => handleDeleteFile(file.docId)}
                        className="buttons"
                      >
                        <FaTrash size={20} className="delete__icon" />
                      </Button>
                    </div>}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center">No Documents Uploaded</div>
          )}
        </div>
      </div>
    </>
  );
};

export default DocumentsUpload;
