import React, { useContext, useEffect, useState } from 'react';
import "./EditPopUp.css";
import Button from './Button';
import OvalButton from './OvalButton';
import CustomPopup from './CustomPopUp';
import AddMcqPopUp from './AddMcqPopUp';
import AddTextPopUp from './AddTextPopUp';
import { RefreshScreenContext } from '../../context/RefreshScreen';
import EditPdfPopUp from './EditPdfPopUp';
import EditVideoPopUp from './EditVideoPopUp';

const EditPopUp = (props) => {
    const [isAddTextOpen, setIsAddTextOpen] = useState(false);
    const [isAddMcqOpen, setIsAddMcqOpen] = useState(false);
    const [mcqFile, setMcqFile] = useState("Upto 100 MCQ can be added.");
    const [textFile, setTextFile] = useState("Text upto 2 MB can be added.");
    const { setRefresh } = useContext(RefreshScreenContext);

    const closeAddMcqPopUp = () => {
        setIsAddMcqOpen(false);
    }
    const openAddMcqPopUp = () => {
        setIsAddMcqOpen(true);
    }
    const closeAddTextPopUp = () => {
        setIsAddTextOpen(false);
    }
    const openAddTextPopUp = () => {
        setIsAddTextOpen(true);
    }

    useEffect(() => {
        if(props.modules.length == 4) {
            props.closePopupEdit();
        }
    }, [props.modules]);

    const checkIfNotPresent = (name) => {
        let moduleNames = [];
        props.modules.forEach(element => {
            moduleNames.push(element.moduleName);
        });

        if (moduleNames.includes(name)) {
            return false;
        }
        return true;
    }

    return (
        <div className="edit-popup">
            {
                checkIfNotPresent("PDF")
                    ? <EditPdfPopUp
                        pdfError={props.pdfError}
                    /> : null
            }
            {
                checkIfNotPresent("VIDEO")
                    ? <EditVideoPopUp
                        video={props.videoError}
                    /> : null
            }
            {
                checkIfNotPresent("MCQ")
                    ? <>
                        <div className="edit-popup-btn-grp">
                            <Button
                                name="MCQ"
                            />
                            <div onClick={openAddMcqPopUp}>
                                <OvalButton
                                    name="Upload"
                                    color="transparent"
                                />
                            </div>
                        </div>
                        <p className="edit-popup-subtext">
                            {mcqFile}
                        </p>
                    </> : null
            }
            {
                checkIfNotPresent("TEXT")
                    ? <>
                        <div className="edit-popup-btn-grp">
                            <Button
                                name="TEXT"
                            />
                            <div onClick={openAddTextPopUp}>
                                <OvalButton
                                    name="Upload"
                                    color="transparent"
                                />
                            </div>
                        </div>
                        <p className="edit-popup-subtext">
                            {textFile}
                        </p>
                    </> : null
            }

            <CustomPopup isOpen={isAddMcqOpen} onClose={closeAddMcqPopUp} height="80%" width="80%">
                <AddMcqPopUp
                    closeAddMcqPopUp={closeAddMcqPopUp}
                    moduleId = ""
                />
            </CustomPopup>

            <CustomPopup isOpen={isAddTextOpen} onClose={closeAddTextPopUp} height="80%" width="80%">
                <AddTextPopUp
                    closeAddTextPopUp={closeAddTextPopUp}
                    moduleId = ""
                />
            </CustomPopup>
        </div>
    );
}

export default EditPopUp;