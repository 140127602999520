import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

import config from "../../constants/config";
import "./UserManagement.scss";
import {
  RatesTab,
  DocumentsUpload,
  PasswordTab,
  UserDetailsForm,
  DeductionTab,
  AdditionTab,
  TfnTab,
  ProfilePicture,
} from "./TabPages";

const CreateUser = () => {
  const [createdUser, setCreatedUser] = useState();
  const [key, setKey] = useState("userdetails");
  return (
    <>
      <div className='createUser__header'>
        <div className="createUser__headerImage">
          <ProfilePicture
            submitPictureUrl={config.baseUrl + config.uploadProfilePicture}
            getPictureUrl={config.baseUrl+config.getProfilePicture}
            // viewEditMode={viewMode}
            id={createdUser?.id}
          />
        </div>
        <h2 className="text-white text-center p-5">Create User</h2>
      </div>
      <div className="createEditView__bg">
        <div className="col-md-10 mx-auto py-2 tabs-user ">
          <Tabs
            className="blur-bg border-white rounded-top"
            fill
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab
              title="Users Details"
              eventKey="userdetails"
              className="border-white"
            >
              <UserDetailsForm
                id={createdUser?.id}
                setCreatedUser={setCreatedUser}
                setKey={setKey}
                className="border-white"
              />
            </Tab>

            <Tab
              title="Password Management"
              eventKey="password"
              disabled={!createdUser}
            >
              <div className="shadow">
              <PasswordTab
                id={createdUser?.id}
                setKey={setKey} 
              />
              </div>
            </Tab>

            <Tab title="Rates" eventKey="rates" disabled={!createdUser}>
              <div className="shadow">
                <RatesTab
                  driverId={createdUser?.id}
                  submitUrl={config.baseUrl + config.saveDriverRates}
                  setKey={setKey}
                />
              </div>
            </Tab>

            <Tab title="Deductions" eventKey="deductions" disabled={!createdUser}>
              <div className="shadow">
                <DeductionTab
                  submitUrl={config.baseUrl + config.saveDriverDeductions}
                  driverId={createdUser?.id}
                  setKey={setKey}
                />
              </div>
            </Tab>
            <Tab title="Additions" eventKey="additions" disabled={!createdUser}>
              <div className="shadow">
                <AdditionTab
                  submitUrl={config.baseUrl + config.driverAdditionsUrls.saveDriverAdditions}
                  driverId={createdUser?.id}
                  setKey={setKey}
                />
              </div>
            </Tab>
            <Tab title="TFN" eventKey="tfn" disabled={!createdUser}>
              <TfnTab
                driverId={createdUser?.id}
                setKey={setKey}
              />
            </Tab>
            <Tab title="Documents" eventKey="documents" disabled={!createdUser}>
              <div className=" shadow">
                <DocumentsUpload
                  driverId={createdUser?.id}
                  setKey={setKey}
                />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default CreateUser;
