import { showOnlyOnError } from "../constants/ErrorHandling";
import config from "../constants/config";
import { getApiCall } from "../utils/api";

export async function getDriversList() {
   const url = config.baseUrlInductionLearning + "/admin/v1/drivers" ;

   try {
      const response = await getApiCall(url);
      showOnlyOnError(response.status, response.data);
      return response.data;
   } catch (err) {
      showOnlyOnError(err.response.status, err.response.data);
      return err.response.data;
   } 
}

export async function getProgress(userId) {
   const url = config.baseUrlInductionLearning + "/admin/v1/progress/" + userId;

   try {
      const response = await getApiCall(url);
      showOnlyOnError(response.status, response.data);
      return response.data;
   } catch (err) {
      showOnlyOnError(err.response.status, err.response.data);
      return null;
   } 
}