import React from "react";

const TextAreaField = ({
  title,
  id,
  ratio,
  required = false,
  placeholder,
  register,
  errors,
  type,
  disabled,
  value = "",
}) => {
  const ratioOfFields = ratio ? ratio : [4, 8];

  return (
    <div className="row mb-3">
      <label
        htmlFor={id}
        className={`col-sm-${ratioOfFields[0]} col-form-label`}
      >
        {title}
        {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <div className={`col-sm-${ratioOfFields[1]} `}>
        <textarea
          id={id}
          placeholder={placeholder}
          name={id}
          {...register(id)}
          className={`form-control ${errors[id] ? "is-invalid" : ""}`}
          type={type}
          disabled={disabled}
          defaultValue={value}
          autoComplete="off"
        />
        {errors?.[id] && (
          <p className="invalid-feedback"> {errors[id].message}</p>
        )}
      </div>
    </div>
  );
};

export default TextAreaField;
