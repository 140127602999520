import React from "react";
import "./RowBuilder.css";
import ProgressBar from "./ProgressBar";

const RowBuilder = (props) => {
  return !props.last ? (
    <div style={{ display: "flex" }}>
      <div className="table-row-title">{props.object.title}</div>
      <div className="table-row-item">
        <ProgressBar
            percent = {props.object.VIDEO}
            type = "video"
        />
        </div>
      <div className="table-row-item">
        <ProgressBar
            percent = {props.object.MCQ}
            mcqList = {props.object.mcqList}
            type = "mcq"
        />
        </div>
      <div className="table-row-item">
        <ProgressBar
            percent = {props.object.TEXT}
            type = "text"
        />
        </div>
    </div>
  ) : (
    <div style={{ display: "flex" }}>
      <div className="table-row-title-last">{props.object.title}</div>
      <div className="table-row-item-last">
        <ProgressBar
            percent = {props.object.VIDEO}
            type = "video"
        />
        </div>
      <div className="table-row-item-last">
        <ProgressBar
            percent = {props.object.MCQ}
            mcqList = {props.object.mcqList}
            type = "mcq"
        />
        </div>
      <div className="table-row-item-last">
        <ProgressBar
            percent = {props.object.TEXT}
            type = "text"
        />
        </div>
    </div>
  );
};

export default RowBuilder;