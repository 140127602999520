import React, { useMemo, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { fetchService } from "../../../utils/api";
import {
  formatDate,
  generateDriversEditViewDeleteColumn,
  generateTableColumns,
} from "../../../utils";
import TableComp from "../../../components/Table";
import Form from "react-bootstrap/Form";

export default function NotInRunsheetsDriversModal({ endpoint, header }) {
  const [startDate, setStartDate] = useState("weekBefore");
  const [modalShow, setModalShow] = React.useState(false);

  const {
    isLoading,
    error,
    data: queryData,
  } = useQuery({
    queryKey: [endpoint, startDate],
    queryFn: () =>
      fetchService({
        url: endpoint,
        method: "POST",
        body: JSON.stringify({
          "start-date": formatDate(startDate),
        }),
      }),
  });

  const columns = useMemo(() => {
    if (isLoading || error) return [];
    let columns = [];
    if (queryData.data.count) {
      columns = generateTableColumns(queryData.data.driverDetailDTOS[0]);
      columns.push(generateDriversEditViewDeleteColumn("driverId", true));
    }
    return columns;
  }, [queryData]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner
          style={{ width: "36px", height: "36px" }}
          animation="border"
          variant="primary"
        />
      </div>
    );
  }

  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  const handleClose = () => setModalShow(false);

  return (
    <>
      <Button
        variant="light"
        className="card-title details-card-btn"
        onClick={() => setModalShow(true)}
      >
        {queryData.data.count}
      </Button>

      <Modal
        show={modalShow}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <Form.Select
              className="mb-3 ms-auto w-50"
              defaultValue={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            >
              <option value="weekBefore">Last Week</option>
              <option value="monthBefore">Last Month</option>
              <option value="sixMonthsBefore">Last 6 Months</option>
              <option value="yearBefore">Last Year</option>
            </Form.Select>
          </div>
          <TableComp data={queryData.data.driverDetailDTOS} columns={columns} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
