import React from 'react'
import 'react-toastify/dist/ReactToastify.css';
// import './ReactToastify.css'
import {ToastContainer} from "react-toastify"

const ToastComp = () => {
  return (
    <ToastContainer />
  )
}

export default ToastComp