import React from "react";

const SwitchField = ({
  title,
  id,
  ratio = [4, 8],
  required = false,
  register,
  errors,
  disabled = false,
  value = "",
}) => {
  return (
    <div className="row mb-3">
      <label htmlFor={id} className={`col-sm-${ratio[0]} col-form-label`}>
        {title}
        {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <div className={`col-sm-${ratio[1]} `}>
        <div className="form-check form-switch h-100 d-flex align-items-center">
          <input
            id={id}
            {...register(id)}
            className={`form-check-input ${errors[id] ? "is-invalid" : ""}`}
            type="checkbox"
            role="switch"
            disabled={disabled}
            defaultValue={value}
          />
        </div>
        {errors?.[id] && (
          <p className="invalid-feedback"> {errors[id].message}</p>
        )}
      </div>
    </div>
  );
};

export default SwitchField;
