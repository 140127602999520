import React, { useContext, useEffect, useState, useRef } from "react";
import "./AdminMain.css";
import Button from "./Button";
import PlusBtn from "./PlusBtn";
import Card from "./Card";
import { routeFinder, activeButtonFinder } from "../../constants/UtilFunctions";
import { useNavigate } from 'react-router-dom';
import { INDUCTION_LEARNING_SCREENS } from "../../constants/routes";
import "./DeletePopUp.css";
import { BsTrash } from "react-icons/bs";
import { ActiveButtonContext, OnTapContext, TrainingsContext } from "../../context/TrainingsContext";
import usePopup from "../../hooks/usePopUp";
import DeleteTrainingPopUp from "./DeleteTrainingPopUp";
import CreateTrainingPopUp from "./CreateTrainingPopUp";

const AdminMain = () => {
  const navigate = useNavigate();
  const { data } = useContext(TrainingsContext);
  const { activeBtn, setActiveBtn } = useContext(ActiveButtonContext);
  const { onTap, setOnTap } = useContext(OnTapContext);
  const [trainingId, setTrainingId] = useState("");
  const [iconFileName, setIconFileName] = useState("PNG & JPG Format is allowed till 1MB");
  const [iconFile, setIconFile] = useState(null);
  const [trainingName, setTrainingName] = useState('');

  const handleOnClick = (value) => {
    const activeButtonName = activeButtonFinder(value.trainingName);
    setActiveBtn(activeButtonName);
    setOnTap(value);
  }

  useEffect(() => {
    if (onTap) {
      navigate(INDUCTION_LEARNING_SCREENS, { state: { onTap } });
    }
  }, [onTap, activeBtn, navigate]);

  useEffect(() => {
    setOnTap(null);
  }, []);

  // Using usePopup hook
  const deletePopup = usePopup();
  const createTrainingPopup = usePopup();

  const openPopupDelete = (id) => {
    setTrainingId((prev) => id);
    deletePopup.openPopup();
  }
  const closePopupDelete = deletePopup.closePopup;
  const isDeleteOpen = deletePopup.isOpen;

  const openPopupCreateTraining = () => {
    setIconFile("");
    setIconFileName("PNG & JPG Format is allowed till 1MB");
    setTrainingName((prev) => "");
    createTrainingPopup.openPopup();
  };
  const closePopupCreateTraining = createTrainingPopup.closePopup;
  const isCreateTrainingOpen = createTrainingPopup.isOpen;

  return (
    <div className="admin-main">
      <div className="admin-heading">
        <p className="heading-text">Induction Admin</p>
        <div className="buttons-dashboard">
          <Button name="Training" />
          <div className="btn" onClick={openPopupCreateTraining} style={{ cursor: "pointer" }}>
            <PlusBtn />
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="main-container-cover main-container-scrollable">
          {
            data.map((value) => {
              const routeName = routeFinder(value.trainingName);

              return <div key={value.id}>
                <div className="card-in-main-container" onClick={() => { handleOnClick(value) }} >
                  <Card icon={value.imageUrl} name={value.trainingName} id={value.id} />
                </div>
                <br />
                <div className="induction-learning-delete-training" onClick={() => openPopupDelete(value.id)}>
                  <BsTrash style={{ marginRight: "5px" }} /> Delete
                </div>
              </div>
            })
          }
        </div>
      </div>

      <DeleteTrainingPopUp
        trainingId={trainingId}
        isDeleteOpen={isDeleteOpen}
        closePopupDelete={closePopupDelete}
      />

      <CreateTrainingPopUp
        isCreateTrainingOpen={isCreateTrainingOpen}
        closePopupCreateTraining={closePopupCreateTraining}
        iconFile={iconFile}
        setIconFile={setIconFile}
        iconFileName={iconFileName}
        setIconFileName={setIconFileName}
        trainingName={trainingName}
        setTrainingName={setTrainingName}
      />
    </div>
  );
};

export default AdminMain;