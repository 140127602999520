import React, { useRef, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button } from "react-bootstrap";
import { dataURLtoFile } from "../../utils";
import { FormField } from "../Form";

const SignatureCanvasField = ({
  label,
  signatureError,
  onSignatureChange,
  canvasProps = {},
}) => {
  const signatureCanvasRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [showFile, setShowFile] = useState(null);

  const clearSignature = (event) => {
    event.preventDefault();
    signatureCanvasRef.current.clear();
    onSignatureChange(null);
    setShowFile(null);
    setImageSrc(null);
  };

  const saveSignature = async (event) => {
    event.preventDefault();
    if (!signatureCanvasRef.current.isEmpty()) {
      const base64Png = signatureCanvasRef.current.toDataURL("image/png");
      const signatureFile = dataURLtoFile(base64Png, "Self-Signature.png");
      setShowFile(signatureFile);
      onSignatureChange(base64Png); // Pass the base64 signature to the parent
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        onSignatureChange(base64String); // Pass the base64 image to the parent
      };
      reader.readAsDataURL(file);
      setShowFile(file);
    }
  };

  useEffect(() => {
    if (showFile) {
      const url = URL.createObjectURL(showFile);
      setImageSrc(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [showFile]);

  return (
    <div className="my-3">
      <FormField
        className="Field_label"
        title={label || "Digital Signature"}
        required
        id="signature"
        ratio={[12, 12]}
      >
        <SignatureCanvas
          ref={signatureCanvasRef}
          penColor="black"
          canvasProps={{
            height: 400,
            className: `signature-canvas ${signatureError ? "is-invalid" : ""}`,
            ...canvasProps,
          }}
        />
        <div className="signature-btn">
          <Button onClick={clearSignature} variant="outline-dark">
            Clear Signature
          </Button>
          <Button onClick={saveSignature}>Save Signature</Button>
        </div>
      </FormField>
      <p>or</p>
      <FormField
        className="Field_label"
        title="Upload Signature Image"
        ratio={[2, 10]}
      >
        <input
          type="file"
          id="signatureFile"
          accept="image/*"
          className="form-control"
          onChange={handleFileChange}
        />
      </FormField>
      {imageSrc && (
        <FormField title="Preview" ratio={[12, 12]}>
          <img
            src={imageSrc}
            width="100%"
            height={300}
            alt="Signature Preview"
          />
        </FormField>
      )}
      {signatureError && <p className="text-danger">{signatureError}</p>}
    </div>
  );
};

export default SignatureCanvasField;
