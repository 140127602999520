import React from "react";
import { useFormContext } from "react-hook-form";
import { FormField, TextBox } from "../../Form";
import SelectField from "../../Form/Fields/SelectField";

function Confirmation({ onSubmit }) {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  if (getValues("customerStatus") === "NOT_RESPONDED") {
    setValue("customerStatus", "");
  }
  const isSignRequired = getValues("signRequired");
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="stepper_Header">Confirmation</h3>
      <div className="my-3">
        <FormField
          className="Field_label"
          title="Approved Amount"
          required
          id="approvedAmount"
        >
          <TextBox
            type="number"
            id="approvedAmount"
            register={register}
            errors={errors}
            className="py-2"
            disabled
          />
        </FormField>
        <FormField
          className="Field_label"
          title="Request Amount"
          required
          id="requestedAmount"
        >
          <TextBox
            type="number"
            id="requestedAmount"
            register={register}
            errors={errors}
            className="py-2"
          />
        </FormField>
        <FormField
          className="Field_label"
          title="Confirm"
          required
          id="customerStatus"
        >
          <SelectField
            ratio={[12, 12]}
            id="customerStatus"
            options={[
              { key: "I agree with the approved value", value: "CONFIRMED" },
              {
                key: "I disagree with the approved value and expected more",
                value: "DECLINED",
              },
            ]}
            optionDisplayKey={"key"}
            optionValueKey={"value"}
            register={register}
            errors={errors}
            getError={(error) => error?.personalInfo?.gender}
          />
        </FormField>
        <FormField className="Field_label" title="Notes" required id="notes">
          <textarea
            id="notes"
            placeholder="write about it"
            {...register("notes")}
            className={`form-control ${errors.notes ? "is-invalid" : ""}`}
          />
          {errors.notes && (
            <p className="invalid-feedback">{errors.notes.message}</p>
          )}
        </FormField>
      </div>
      {!isSignRequired && (
        <div className="form-btn">
          <button type="submit" className="credit-form-btn">
            Submit
          </button>
        </div>
      )}
    </form>
  );
}

export default Confirmation;
