import React, { useContext, useState, useRef } from "react";
import "./AdminMain.css";
import Button from "./Button";
import CustomPopup from "./CustomPopUp";
import "./DeletePopUp.css";
import OvalButton from "./OvalButton";
import { TrainingsContext } from "../../context/TrainingsContext";
import { createTraining } from "../../InductionLearningServices/Trainings";
import { useLoadingContext } from "../../context/LoadingContext";

const CreateTrainingPopUp = (props) => {

   const { setIsLoading } = useLoadingContext();
   const { setLoading } = useContext(TrainingsContext);
   const fileInputRef = useRef(null);
   const [iconResourceError, setIconResourceError] = useState(null);

   // PDF choosing and uploading
   const handleButtonClick = () => {
      fileInputRef.current.click();
   };

   const sendTraining = async (formData) => {
      setIconResourceError("");
      setIsLoading(true);
      const response = await createTraining(formData);
      setIsLoading(false);
      if (response.message == "SUCCESS" || response.status == 200) {
         props.closePopupCreateTraining();
         setLoading((prev) => true);
      }
   }

   const handleTraining = (e) => {
      setIconResourceError("");
      let selectedFile = e.target.files[0];
      if (selectedFile.name.toLowerCase().includes(".png") || selectedFile.name.toLowerCase().includes(".jpg")) {
         props.setIconFile((prev) => selectedFile);
         props.setIconFileName("Selected File: " + selectedFile.name);
         return;
      }
      setIconResourceError("Only .png & .jpg formats are allowed.");
   };

   const saveToCreateTraining = () => {
      setIconResourceError("");
      if (!props.iconFile) {
         setIconResourceError("Training Icon is required.");
         return;
      }
      if (!props.trainingName) {
         setIconResourceError("Training name is required.");
         return;
      }
      const regex = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s]{1,15}$/;

      if (!regex.test(props.trainingName)) {
         // Check for minimum length and alphanumeric requirement
         if (props.trainingName.length === 0) {
            setIconResourceError("Training Name can't be empty.");
            return;
         } else if (props.trainingName.length > 15) {
            setIconResourceError("Training Name should not be greater than 15 characters.");
            return;
         } else if (!/\w/.test(props.trainingName)) {
            setIconResourceError("At least one alphanumeric character is required.");
            return;
         } else {
            setIconResourceError("Only Alphanumeric characters and spaces are allowed in Training Name.");
            return;
         }
      }

      let formData = new FormData();
      formData.append("imageUrl", props.iconFile); // Make sure iconFile is defined
      formData.append("trainingName", props.trainingName);
      sendTraining(formData);
   }

   // Add Training components
   const stylesTextField = {
      padding: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      fontSize: '16px',
      width: '300px',
      outline: 'none'
   };

   const trainingNameLabel = {
      color: "white",
      marginRight: "10px"
   }

   

   const handleInputChange = (e) => {
      setIconResourceError("");
      props.setTrainingName((prev) => e.target.value);
   };

   return (
      <CustomPopup isOpen={props.isCreateTrainingOpen} onClose={props.closePopupCreateTraining} height="20%" width="40%">
         <div className="edit-popup">
            <div className="edit-popup-btn-grp">
               <Button
                  name="Training"
               />
               {/* Upload PDF */}
               <form>
                  <div onClick={handleButtonClick}>
                     <OvalButton
                        name="Select Icon"
                        color="transparent"
                     />
                  </div>
                  <input
                     type="file"
                     className="form-control"
                     onChange={handleTraining}
                     ref={fileInputRef}
                     accept=".png, .jpg"
                     style={{ display: 'none' }}
                  >
                  </input>
               </form>
            </div>

            <br />
            <label htmlFor="customInput" style={trainingNameLabel}>Training Name: <span className="red-text">*</span></label>
            <input
               style={stylesTextField}
               type="text"
               id="customInput"
               value={props.trainingName}
               onChange={handleInputChange}
               placeholder="Add Training Name"
            />

            <p className="edit-popup-subtext">
               {props.iconFileName}
               {iconResourceError && <p className="text-danger">{iconResourceError}</p>}
            </p>

            <div onClick={saveToCreateTraining} className='edit-popup-subtext-2 btn-center'>
               Save
            </div>

         </div>
      </CustomPopup>
   );
}

export default CreateTrainingPopUp;