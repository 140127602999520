import React from "react";
import { Container, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import * as Navigate from "../../constants/routes";
import swiftTransportLogo from "../../images/swift-transport-logo.png";
import "./PrivacyPolicy.scss";
const index = () => {
  return (
    <div>
      <Navbar bg="" expand="lg" className="navbar--Header">
        <Container className="py-1 navbar--container">
          <NavLink className="nav-link me-auto" to={Navigate.HOME}>
            <img
              src={swiftTransportLogo}
              width="110"
              height="80"
              className="d-inline-block align-top me-5"
              alt="React Bootstrap logo"
            />
          </NavLink>
          <div className="col-sm-1"></div>
        </Container>
      </Navbar>
      <Container className="privacy-policy">
        <div className="section">
          <div className="main-heading">PRIVACY POLICY</div>
          <div className="body main-body">
            <div className="sub-body">
              Swift Transport Pty Ltd built the Swift Trackmile application.
              This SERVICE is provided by Swift Transport Pty Ltd at no cost and
              is intended for use as is for Induction purpose .
            </div>
            <div className="sub-body">
              This page is used to inform visitors regarding our policies with
              the collection, use, and disclosure of Personal Information if
              anyone decided to use our Service.
            </div>
            <div className="sub-body">
              If you choose to use our Service, then you agree to the collection
              and use of information in relation to this policy. The Personal
              Information that we collect is used for providing and improving
              the Service. We will not use or share your information with anyone
              except as described in this Privacy Policy.
            </div>
            <div className="sub-body">
              The terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions, which are accessible at Swift Trackmile
              Induction unless otherwise defined in this Privacy Policy.
            </div>
          </div>
        </div>

        <div className="section">
          <div className="heading">Information Collection and Use</div>
          <div className="body">
            <div className="sub-body">
              For a better experience, while using our Service, we may require
              you to provide us with certain personally identifiable
              information, including but not limited to Swift Admin Team . The
              information that we request will be retained by us and used as
              described in this privacy policy.
            </div>
            <div className="sub-body">
              The application does use third-party services that may collect
              information used to identify you.
            </div>
            <div className="sub-body">
              Link to the privacy policy of third-party service providers used
              by the application.
            </div>

          </div>
        </div>
        <div className="section">
          <div className="heading">Personal and sensitive user data</div>
          <div className="body">
            <div className="sub-body">
              We require in app for the user to enter certain personally
              identifiable information. All the personal and sensitive
              information received will be only limited to Swift Admin Team. The
              information will not be disclosed to any third party . The
              information will be used by Swift to keep a record and utilize as
              follows
              <ol type="a" >
                <li>Personal information -For record purpose and contact number will be used to contact the user if needed </li>
                <li>License Details -For record purpose </li>
                <li>Bank Details -For providing payment to the drivers .</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="heading">Log Data</div>
          <div className="body">
            We want to inform you that whenever you use our Service, in a case
            of an error in the application we collect data and information
            (through third-party products) on your phone called Log Data. This
            Log Data may include information such as your device Internet
            Protocol (“IP”) address, device name, operating system version, the
            configuration of the application when utilizing our Service, the
            time and date of your use of the Service, and other statistics.
          </div>
        </div>
        <div className="section">
          <div className="heading">Cookies</div>
          <div className="body">
            <div className="sub-body">
              Cookies are files with a small amount of data that are commonly
              used as anonymous unique identifiers. These are sent to your
              browser from the websites that you visit and are stored on your
              device's internal memory.
            </div>
            <div className="sub-body">
              {" "}
              This Service does not use these “cookies” explicitly. However, the
              application may use third-party code and libraries that use
              “cookies” to collect information and improve their services. You
              have the option to either accept or refuse these cookies and know
              when a cookie is being sent to your device. If you choose to
              refuse our cookies, you may not be able to use some portions of
              this Service.
            </div>
          </div>
        </div>

        <div className="section">
          <div className="heading">Service Providers</div>
          <div className="body">
            <div className="sub-body">
              We may employ third-party companies and individuals due to the
              following reasons:
              <ul>
                <li>To facilitate our Service</li>
                <li>To provide the Service on our behalf</li>
                <li>To perform Service-related services</li>
                <li>or To assist us in analyzing how our Service is used.</li>
              </ul>
            </div>
            <div className="sub-body">
              We want to inform users of this Service that these third parties
              have access to their Personal Information. The reason is to
              perform the tasks assigned to them on our behalf. However, they
              are obligated not to disclose or use the information for any other
              purpose.
            </div>
          </div>
        </div>
        <div className="section">
          <div className="heading">Security</div>
          <div className="body">
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
          </div>
        </div>
        <div className="section">
          <div className="heading">Children’s Privacy</div>
          <div className="body">
            These Services do not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from children
            under 13 years of age. In the case we discover that a child under 13
            has provided us with personal information, we immediately delete
            this from our servers. If you are a parent or guardian and you are
            aware that your child has provided us with personal information,
            please contact us so that we will be able to do the necessary
            actions.
          </div>
        </div>
        <div className="section">
          <div className="heading">Links to Other Sites</div>
          <div className="body">
            This Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </div>
        </div>
        <div className="section">
          <div className="heading">Changes to This Privacy Policy</div>
          <div className="body">
            <div className="sub-body">We may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on this
            page. </div>
            <div className="sub-body">This policy is effective as of 2022-10-18</div>
            
          </div>
        </div>
        <div className="section">
          <div className="heading">Support Contact</div>
          <div className="body">
            <div className="sub-body">For support please contact below</div>
            <div className="sub-body"><span className="fw-bolder">Contact No</span>: ±61449940514 </div>
            <div className="sub-body"><span className="fw-bolder">Email Id</span>: support@trackmile.com.au</div>
            <div className="sub-body"><span className="fw-bolder">Name</span>: Manisha Gupta</div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default index;
