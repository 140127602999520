import React from "react";
import {
  useTable,
  useFilters,
  useExpanded,
  useGlobalFilter,
} from "react-table";

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <span>
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
        placeholder="Search all columns..."
        style={{
          marginBottom: "10px",
          padding: "5px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      />
    </span>
  );
};

const TableComp = ({
  data = [],
  columns,
  getRowProps = () => {},
  tableOptions,
  renderRowSubComponent,
  enableGlobalFilter = false,
}) => {
  // const columns = useMemo(()=>columns, [])
  // const data = useMemo(()=>data, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      ...tableOptions,
    },
    useFilters,
    useGlobalFilter,
    useExpanded
  );

  const { globalFilter } = state;

  return (
    <div>
      {enableGlobalFilter && (
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      <div className="tableContainer">
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      style: { minWidth: column.minWidth, width: column.width },
                    })}
                  >
                    {column.render("Header")}
                    <div className="">
                      {column.canFilter ? column.render("Filter") : null}{" "}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {data?.length === 0 && (
              <tr>
                <td colSpan={visibleColumns.length}>
                  <div className="d-flex justify-content-center">
                    No data found
                  </div>
                </td>
              </tr>
            )}

            {data?.length > 0 &&
              rows.map((row) => {
                prepareRow(row);
                const rowProps = row.getRowProps();
                const { role, ...restRowProps } = rowProps; // Destructure to remove `role`
                return (
                  <React.Fragment {...restRowProps}>
                    <tr {...getRowProps(row)}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className={` ${cell.column.className || ""}`}
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                                ...cell.column.styles,
                              },
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded && renderRowSubComponent ? (
                      <tr>
                        <td colSpan={visibleColumns.length}>
                          {renderRowSubComponent({ row })}
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableComp;
