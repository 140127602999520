import React from "react";
import "./swiftTrafficSignup.scss";
import SwiftTrafficPdf from "./components/SwiftTrafficPdf";
import LoginForDriver from "./components/LoginForDriver";
import { getUserData } from "../../rtk-slice/globalSlice";
import { useSelector } from "react-redux";

function SwiftTrafficSignup() {
  const userData = useSelector(getUserData);

  return (
    <div className="swift-traffic">
      {userData && userData.success ? <SwiftTrafficPdf /> : <LoginForDriver />}
    </div>
  );
}

export default SwiftTrafficSignup;
