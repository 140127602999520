import React from "react";
import { getUserData } from "../../rtk-slice/globalSlice";
import { useSelector } from "react-redux";
import "./components/gdpTraining.scss";
import LoginForDriver from "../SwiftTrafficSignup/components/LoginForDriver";
import GdpTrainingPdf from "./components/GdpTrainingPdf";

function GdpTraining() {
  const userData = useSelector(getUserData);

  return (
    <div className="KuehneNagel">
      {userData && userData.success ? <GdpTrainingPdf /> : <LoginForDriver />}
    </div>
  );
}

export default GdpTraining;
