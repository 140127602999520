import React, { useContext, useEffect, useState } from "react";
import "./AddItem.css";
import Button from "./Button";
import View from "../../assets/InductionLearningAssets/view.png";
import Edit from "../../assets/InductionLearningAssets/edit.png";
import Trash from "../../assets/InductionLearningAssets/trash.png";
import CustomPopup from "./CustomPopUp";
import PDFPopUp from "./PDFPopUp";
import TextPopUp from "./TextPopUp";
import McqPopUp from "./McqPopUp";
import { getMcqModule, getModuleItem, getTextModule } from "../../InductionLearningServices/Modules";
import { RefreshScreenContext } from "../../context/RefreshScreen";
import { ModuleIdContext, TrainingIdContext } from "../../context/TrainingsContext";
import usePopup from "../../hooks/usePopUp";
import EditPdfPopUp from "./EditPdfPopUp";
import AddMcqPopUp from "./AddMcqPopUp";
import AddTextPopUp from "./AddTextPopUp";
import EditVideoPopUp from "./EditVideoPopUp";

const AddItem = (props) => {
  const nameComponent = props.name;
  const { refresh, setRefresh } = useContext(RefreshScreenContext);
  const { moduleId, setModuleId } = useContext(ModuleIdContext);
  const { trainingId } = useContext(TrainingIdContext);

  // Using usePopup hook
  const viewPopup = usePopup();
  const editPopup = usePopup();

  const openPopupView = viewPopup.openPopup;
  const closePopupView = viewPopup.closePopup;
  const isViewOpen = viewPopup.isOpen;

  const openPopupEdit = editPopup.openPopup;
  const closePopupEdit = () => {
    editPopup.closePopup();
  };
  const isEditOpen = editPopup.isOpen;

  // pdf file onChange state
  const [pdfFile, setPdfFile] = useState(null);
  const [mcqFile, setMcqFile] = useState([]);
  const [text, setText] = useState("");
  const [pdfError, setPdfError] = useState(null);
  const [videoError, setVideoError] = useState(null);

  const clickDelete = () => {
    setModuleId((prev) => props.moduleId);
    props.openPopupDelete();
  }


  const fetchData = async () => {
    if (props.name.toLowerCase().includes("text")) {
      let response = await getTextModule(props.moduleId);
      if (response != null) { setText((prevState) => response.data); }
    } if (props.name.toLowerCase().includes("mcq")) {
      let response = await getMcqModule(props.moduleId);
      if (response != null) { setMcqFile((prevState) => response.data.data.mcqs); }
    } if (props.name.toLowerCase().includes("video")) {
      let response = await getModuleItem(props.moduleId);
      if (response != null) { setPdfFile((prevState) => response.data.data.videoLink); }
    } else {
      let response = await getModuleItem(props.moduleId);
      if (response != null) { setPdfFile((prevState) => response.data.data); }
    }

  }
  useEffect(() => {
    fetchData();
  }, [refresh]);

  let viewComponent;
  let editComponent;
  if (nameComponent.toLowerCase().includes("text")) {
    viewComponent = (
      <TextPopUp
        text={text}
      />
    );
    editComponent = (
      <AddTextPopUp
        closeAddTextPopUp={closePopupEdit}
        moduleId={props.moduleId}
      />
    );
  } else if (nameComponent.toLowerCase().includes("mcq")) {
    viewComponent = (
      <McqPopUp
        mcq={mcqFile}
      />
    );
    editComponent = (
      <AddMcqPopUp
        closeAddMcqPopUp={closePopupEdit}
        moduleId={props.moduleId}
      />
    );
  } else if (nameComponent.toLowerCase().includes("video")) {
    viewComponent = (
      <PDFPopUp
        pdfFile={pdfFile}
      />
    );
    editComponent = (
      <EditVideoPopUp
        videoError={videoError}
        closePopUp={closePopupEdit}
      />
    );
  } else {
    viewComponent = (
      <PDFPopUp
        pdfFile={pdfFile}
      />
    );
    editComponent = (
      <EditPdfPopUp
        pdfError={pdfError}
        closePopUp={closePopupEdit}
      />
    );
  }

  return (
    <>
      <div className="add-item">
        <Button name={nameComponent} />
        <div
          className="view-btn"
          style={{ marginLeft: "57px" }}
          onClick={openPopupView}
        >
          <p className="view-btn-text">View</p>
          <img src={View} alt="view icon" />
        </div>
        <div className="view-btn" onClick={openPopupEdit}>
          <p className="view-btn-text">Edit</p>
          <img src={Edit} alt="view icon" />
        </div>
        <div
          className="view-btn"
          style={{ marginRight: "0px" }}
          onClick={clickDelete}
        >
          <p className="view-btn-text">Delete</p>
          <img src={Trash} alt="view icon" />
        </div>
      </div>

      <CustomPopup isOpen={isEditOpen} onClose={closePopupEdit} height="60%" width="50%">
        {editComponent}
      </CustomPopup>
      <CustomPopup isOpen={isViewOpen} onClose={closePopupView} height="90%" width="80%">
        {viewComponent}
      </CustomPopup>
    </>
  );
};

export default AddItem;