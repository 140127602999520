import { useQuery } from "@tanstack/react-query";
import { fetchService } from "../utils/api";

export const useDriverLogs = (id) => {
  const url = `/user/${id}/logs`;

  return useQuery({
    queryKey: ["user-logs", id],
    queryFn: async () => await fetchService({ url }),
  });
};
