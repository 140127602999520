import React, { useEffect, useState } from 'react';
import "./SearchComponent.css";
import SearchItem from './SearchItem';
import ProfilePicture from "../../assets/InductionLearningAssets/profile-picture.jpg";
import { useNavigate } from 'react-router-dom';
import { INDUCTION_LEARNING_SEARCH_SCREEN } from '../../constants/routes.jsx';
import { useSearchContext } from '../../context/SearchContext.jsx';
import { getDriversList } from '../../InductionLearningServices/Search.jsx';
import "./SearchBar.css";
import Search from "../../assets/InductionLearningAssets/search.png";

const SearchComponent = () => {

    const { nameOfUser, setNameOfUser } = useSearchContext();
    const [userList, setUserList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate();
    const performNavigation = (userDetails) => {
        navigate(INDUCTION_LEARNING_SEARCH_SCREEN, { state: { userDetails } });
    }
    const handleButtonClick = (userDetails) => {
        setNameOfUser(userDetails.userId);
        performNavigation(userDetails);
    };

    useEffect(() => {
        const fetchData = async () => {
            let response = await getDriversList();
            setUserList(response.data);
        }

        fetchData();
    }, []);

    const SearchBar = () => {
        const handleSearch = (event) => {
            setSearchTerm((prev) => event.target.value);
        };

        return (
            <div className='search-bar-input-container'>
                <form>
                    <input
                        type='text'
                        placeholder='Search for Driver'
                        className='search-bar'
                        onChange={handleSearch}
                    />
                    <img src={Search} alt='search icon' className='search-icon' />
                </form>
            </div>
        );
    }

    return (
        <div className='search-component'>
            {SearchBar()}
            <div className='user-list-search-component'>
                {
                    userList != null && userList != []
                        ? userList.map(
                            (value) => searchTerm === ''
                                ? <div key={value.userId} className={nameOfUser === value.userId ? "selected-user-color" : "link-tag-style"} onClick={() => { handleButtonClick(value) }}>
                                    <SearchItem
                                        name={value.name}
                                        photoUrl={ProfilePicture}
                                    />
                                    <div className={nameOfUser === value.userId ? "selected-user" : null}></div>
                                </div>
                                : value.name.toLowerCase().includes(searchTerm.toLowerCase())
                                    ? <div key={value.userId} className={nameOfUser === value.userId ? "selected-user-color" : "link-tag-style"} onClick={() => { handleButtonClick(value) }}>
                                        <SearchItem
                                            name={value.name}
                                            photoUrl={ProfilePicture}
                                        />
                                        <div className={nameOfUser === value.userId ? "selected-user" : null}></div>
                                    </div>
                                    : null

                        ) : <h5 className="link-tag-style">No Driver's Available.</h5>
                }
            </div>

        </div>
    );
}

export default SearchComponent;