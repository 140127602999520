import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import config from "../../constants/config";
import * as Navigation from "../../constants/routes";
import logoImage from "../../images/swift-transport-logo.png";
import {
  doLogin,
  doLoginWithOtp,
  getUserData
} from "../../rtk-slice/globalSlice";
import { postData } from "../../utils/api";
import { errorToast, successToast, warningToast } from "../../utils/toastFunc";
import { Button, FormField, TextBox } from "../Form";
import "./LoginComp.scss";

const hideLoginWithPhoneBtn = false;

const LoginComponent = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const userData = useSelector(getUserData);
  const [loginWithUsername, setLoginWithUsername] = useState(true);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [disableOtp, setDisableOtp] = useState(true);

  const timer = 20; //cooldown timer in seconds
  const [sendAgainTimer, setSendAgainTimer] = useState(timer);
  const [isOtpSent, setIsOtpSent] = useState(false);

  useEffect(() => {
    if (userData && userData.success) {
      Navigate(Navigation.HOME);
    } else if (userData && !userData.success) {
      setShowErrorMsg(true);
    }
  }, [userData]);

  const validationSchemaPass = Yup.object().shape({
    username: Yup.string().required("User name is required"),
    password: Yup.string().required("Password is required"),
  });
  const validationSchemaOtp = Yup.object().shape({
    phoneNumber: Yup.string().required("Phone is required"),
    otp:
      !disableOtp &&
      Yup.string()
        .required("Otp is required")
        .max(6, "OTP should be 6 digits")
        .min(6, "OTP should be 6 digits"),
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      loginWithUsername ? validationSchemaPass : validationSchemaOtp
    ),
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const onSubmitFunction = (formData) => {
    setShowErrorMsg(false);
    const authUserWithUsername = async () => {
      const username = formData.username;
      const password = btoa(formData.password);
      dispatch(doLogin({ username, password }));
    };
    const authUserWithPhone = async () => {
      const { otp, phoneNumber } = formData;
      dispatch(doLoginWithOtp({ otp, phoneNumber }));
    };
    loginWithUsername ? authUserWithUsername() : authUserWithPhone();
  };
  const toggleLoginMethod = () => {
    setLoginWithUsername((prev) => !prev);
    setDisableOtp(true);
    reset();
  };
  const sendOTP = async (mobile) => {
    if (!mobile) {
      return warningToast({ mes: "Enter Phone number" });
    }
    const url = `${config.baseUrl}${config.sendOtp}?phoneNumber=${mobile}`;
    const response = await postData(url, {});
    if (response.success) {
      successToast({ mes: "OTP sent successfully" });
      setIsOtpSent(true);
      setDisableOtp(false);
      const intervalId = setInterval(
        () => setSendAgainTimer((time) => time - 1),
        1000
      );
      setTimeout(() => {
        setIsOtpSent(false);
        setSendAgainTimer(timer);
        clearInterval(intervalId);
      }, timer * 1000);
    } else {
      errorToast({ mes: response.message });
    }
  };
  return (
    <div className="login-wrapper">
      <div className="login-page container-fluid">
        <div className="login-container backdrop-blur container-fluid">
          <div className="row center-items">
            <div className="col-md-12 text-center ">
              <div className="logo-wpr h-25">
                <img src={logoImage} alt="logo" className="logo-img" />
              </div>
              <div className="login-page-logo text-center w-100">
                <h3 className=" text-white fs-3">Track Mile - Admin</h3>
              </div>
            </div>

            <div className="col-md-12 text-center">
              <form
                autoComplete="none"
                onSubmit={handleSubmit(onSubmitFunction)}
              >
                {loginWithUsername ? (
                  <>
                    <div className="input mb-1">
                      <FormField
                        title="Username"
                        id="username"
                        ratio={[2, 12]}
                        className="text-white"
                      >
                        <TextBox
                          register={register}
                          id="username"
                          errors={errors}
                          placeholder="User Name"
                          maxLength="30"
                          type="text"
                          icon="true"
                        />
                      </FormField>
                    </div>

                    <div className="input">
                      <FormField
                        title="Password"
                        id="password"
                        ratio={[2, 12]}
                        className="text-white"
                      >
                        <TextBox
                          register={register}
                          id="password"
                          errors={errors}
                          placeholder="Password"
                          maxLength="30"
                          type="password"
                          icon="true"
                        />
                      </FormField>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="input mb-1 row">
                      <label
                        htmlFor="phoneNumber"
                        className={`col-sm-1 col-form-label text-white`}
                      >
                        {`Phone`}
                      </label>

                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-8 mb-1">
                            <TextBox
                              register={register}
                              id="phoneNumber"
                              errors={errors}
                              placeholder="Phone"
                              maxLength="30"
                              type="tel"
                              icon="true"
                            />
                          </div>

                          <div className="col-md-4">
                            <Button
                              color="rgba(0, 43, 85, 1)"
                              disabled={
                                getValues("phoneNumber")?.length < 5 ||
                                isOtpSent
                              }
                              className="buttons"
                              onClick={() => sendOTP(getValues("phoneNumber"))}
                            >
                              Send OTP {isOtpSent && `(${sendAgainTimer})`}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="input">
                      <FormField
                        title="OTP"
                        id="otp"
                        ratio={[1, 12]}
                        className="text-white"
                      >
                        <TextBox
                          register={register}
                          id="otp"
                          errors={errors}
                          placeholder="otp"
                          maxLength="30"
                          disabled={disableOtp}
                          type="number"
                          icon="true"
                        />
                      </FormField>
                    </div>
                  </>
                )}

                <div className="col-md-12">
                  {showErrorMsg && (
                    <p className="text-danger">Please check your credentials</p>
                  )}
                </div>

                <div className="col-md-12 text-center mt-2">
                  <div className="row justify-content-end">
                    <div className="col-4">
                      <Link to={Navigation.PRIVACY_POLICY}>
                        <p className=" text-decoration-none text-secondary text-end fs-6">
                          Privacy Policy
                        </p>
                      </Link>
                    </div>
                  </div>
                  <Button
                    color="rgba(0, 43, 85, 1)"
                    className="btn btn-primary login-button mb-1"
                    type="submit"
                    disabled={!loginWithUsername && disableOtp}
                    title={loginWithUsername ? "LOGIN" : "VERIFY"}
                  />
                  {!hideLoginWithPhoneBtn ? (
                    <div className="row">
                      <div className="col-md-5">
                        <Button
                          className="btn-link text-decoration-none text-start"
                          onClick={toggleLoginMethod}
                        >
                          {`Login with ${
                            loginWithUsername ? "Phone" : "Username"
                          }`}
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
